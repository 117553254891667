import Portal from '@mui/material/Portal';
import { getStorefrontUrl } from '@niarab2c/frontend-commons/src/util/storefrontUtils';
import CriteriaComponent from '@niarab2c/niarab2c-core/src/components/CriteriaComponent';
import { generateQueryString } from '@niarab2c/niarab2c-core/src/util/hotels';
import DefaultPortalContext from '@niarab2c/ota-components/src/portal/DefaultPortalContext';
import type { Storefront } from '@niarab2c/otabuilder-crudmodel/src';
import queryString from 'query-string';
import React, { useCallback, useEffect, useState } from 'react';
import { Provider } from 'react-redux';
import ThemeProvider from '../ThemeProvider';
import type { CriteriaForm } from '../app';
import { Dispatch, StorefrontConfig, createStore } from '../app/widget';
import StyleSheet from './StyleSheet';
import WidgetPortalContainer from './WidgetPortalContainer';
import * as S from './styles';
export interface GetCredentials {
  (): {
    accessToken?;
    provider?;
  };
}
type Props = {
  storefrontId: string;
  headerSize?: string;
  getCredentials: GetCredentials;
  orientation: 'VERTICAL' | 'HORIZONTAL';
};
const Widget: React.FC<Props> = ({
  storefrontId,
  headerSize,
  getCredentials,
  orientation
}) => {
  const [init, setInit] = useState(undefined);
  const [store] = useState(() => createStore());
  const dispatch = (store.dispatch as Dispatch);
  const [storefront, setStorefront] = useState<Storefront>();
  useEffect(() => {
    setInit(null);
    StorefrontConfig.fetchStorefront(storefrontId).then(storefront => {
      setStorefront(storefront);
      if (storefront) {
        dispatch(StorefrontConfig.initStorefront(storefront)).then(() => setInit(true));
      } else {
        // não foi possível carregar o storefront
        setInit(false);
      }
    });
  }, [dispatch, storefrontId]);
  const onSearch = useCallback((criteria: CriteriaForm) => {
    const redirectTo = `/hotels/${criteria.destinations?.hotelIds?.length == 1 ? criteria.destinations?.hotelIds[0] : ''}#${generateQueryString(criteria)}`;
    const credentials = getCredentials?.();
    if (credentials?.accessToken) {
      // TODO está hardcoded com allpoints
      return window.open(`${getStorefrontUrl(storefront)}/auth/allpoints#${queryString.stringify({
        access_token: credentials?.accessToken,
        redirect_to: redirectTo
      })}`);
    }
    return window.open(`${getStorefrontUrl(storefront)}${redirectTo}`);
  }, [storefront, getCredentials]);
  const [defaultPortalContextValue, setDefaultPortalContextValue] = useState();
  if (init === false) return <>Erro de configuração</>;
  if (!init) return <>Carregando...</>;
  return <Provider store={store}>
      <StyleSheet>
        <ThemeProvider>
          <S.Container className="otabuilder-widget-container">
            <S.CenteredContainer>
              <S.Reset>
                <DefaultPortalContext.Provider value={defaultPortalContextValue}>
                  <CriteriaComponent isPersonSearch={false} headerSize={headerSize} onHotelSearch={onSearch} searchEngine_orientation={orientation} />
                </DefaultPortalContext.Provider>
              </S.Reset>
            </S.CenteredContainer>
          </S.Container>
        </ThemeProvider>
        <Portal>
          <WidgetPortalContainer setDefaultPortalContext={setDefaultPortalContextValue} />
        </Portal>
      </StyleSheet>
    </Provider>;
};
export default Widget;