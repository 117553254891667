import { Divider, TooltipProps } from '@mui/material';
import type { LegacyPaymentOption, PaymentOption } from '@niarab2c/frontend-commons/src/types/hotels';
import { NiaraIcon } from '@niaratech/niara-icons';
import { printPrice } from '@niaratech/niara-js-commons';
import _uniq from 'lodash/uniq';
import React, { useState } from 'react';
import { IcAnticipation, IcCreditCard, IcDirectHotel, IcIata, IcInvoiced, IcPix } from '../../Icons/typePayment';
import { IcCardAmex, IcCardDinners, IcCardDiscover, IcCardElo, IcCardHipercard, IcCardJcb, IcCardMaster, IcCardVisa, IcPaymentLater } from '../../components/CardBrand/Brands';
import ToolTip from '../../microComponents/ToolTip';
import * as S from './styles';
export type PaymentProps = {
  paymentOptions?: LegacyPaymentOption[] | PaymentOption[];
  textSize?: number;
  textColor?: string;
  fontWeight?: number | string;
  displayMode?: 'VERTICAL' | 'TOOLTIP';
  maxPaymentToDisplay?: number;
  ellipsis?: boolean;
  showStartIcon?: boolean;
  hideInfoIcon?: boolean;
  disableMorePayments?: boolean;
  placementTooltip?: TooltipProps['placement'];
  justifyContent?: string;
};
export const PAYMENT_TYPES = ({
  DIR: "Pagamento direto",
  DIR_IATA: "Garantia IATA",
  OFF_CC: "Cartão de crédito",
  ON_CC: "Cartão de crédito",
  ON_PIX: "PIX",
  OFF_ANT: "Antecipado",
  OFF_FAT: "Faturado",
  LATER: "Pagar depois"
} as const);
type Brands = {
  cardBrand: 'MASTERCARD' | 'VISA' | 'AMEX' | 'ELO' | 'HIPER' | 'HIPERCARD' | 'AURA' | 'DINERS' | 'JCB' | 'DISCOVER';
  cardBadge?: string;
};
const CARD_BRANDS: Brands[] = [{
  cardBrand: 'MASTERCARD',
  cardBadge: IcCardMaster
}, {
  cardBrand: 'VISA',
  cardBadge: IcCardVisa
}, {
  cardBrand: 'AMEX',
  cardBadge: IcCardAmex
}, {
  cardBrand: 'ELO',
  cardBadge: IcCardElo
}, {
  cardBrand: 'HIPER',
  cardBadge: IcCardHipercard
}, {
  cardBrand: 'HIPERCARD',
  cardBadge: IcCardHipercard
}, {
  cardBrand: 'AURA',
  cardBadge: null
}, {
  cardBrand: 'DINERS',
  cardBadge: IcCardDinners
}, {
  cardBrand: 'JCB',
  cardBadge: IcCardJcb
}, {
  cardBrand: 'DISCOVER',
  cardBadge: IcCardDiscover
}];
export const icons = ({
  DIR: IcDirectHotel,
  DIR_IATA: IcIata,
  OFF_CC: IcCreditCard,
  ON_CC: IcCreditCard,
  ON_PIX: IcPix,
  OFF_ANT: IcAnticipation,
  OFF_FAT: IcInvoiced,
  LATER: IcPaymentLater
} as const);
const PaymentConditions: React.FC<PaymentProps> = ({
  paymentOptions,
  textSize,
  textColor,
  fontWeight,
  displayMode,
  maxPaymentToDisplay = 2,
  ellipsis,
  showStartIcon,
  hideInfoIcon,
  placementTooltip,
  justifyContent,
  disableMorePayments
}) => {
  const [open, setOpen] = useState(false);
  const enabledPaymentOptions = React.useMemo(() => paymentOptions?.filter(po => po?.enabled || po?.enabled == null /* #183469 considerar enabled null como habilitado */)?.sort((a, b) => (a?.alias || '').localeCompare(b?.alias || '')) ?? [], [paymentOptions]);
  const availablePaymentsLabel: string[] = React.useMemo(() => _uniq(enabledPaymentOptions?.filter(r => r.type != 'LATER')?.map(payOpt => payOpt.alias || PAYMENT_TYPES[payOpt.type]).filter(Boolean) ?? []), [enabledPaymentOptions]);
  const availablePaymentsString = availablePaymentsLabel?.length > maxPaymentToDisplay ? `${availablePaymentsLabel.slice(0, maxPaymentToDisplay).join(', ')}` : availablePaymentsLabel?.join(" ou ");

  // Removend devido a questão de não ficar tão legivel quando os nomes são maiores que o limite da caixa de texto
  //const otherPaymentTypes = paymentOptions?.filter((payment) => payment.type !== 'ON_PIX' && payment.type !== 'ON_CC')
  const paymentOptionsFormatted = new Set();
  const uniqTypes = enabledPaymentOptions?.filter(paymentOption => {
    const type = paymentOption?.type === 'OFF_CC' ? 'ON_CC' : paymentOption?.type;
    if (!paymentOptionsFormatted.has(type)) {
      paymentOptionsFormatted.add(type);
      return true;
    }
    return false;
  });
  const typePaymentInfo = () => {
    if (hideInfoIcon) return null;
    const isMobile = window.innerWidth < 768;
    return <ToolTip open={isMobile ? open : undefined} placement={placementTooltip} title={<S.ConditionsContainer>
            {enabledPaymentOptions?.map((paymentType, idx) => {
        return <React.Fragment key={paymentType?.id}>
                  <S.TextGroup key={idx}>
                    {paymentType?.alias && <S.Text> • {paymentType.alias}</S.Text>}
                    {paymentType.description && <S.Text>{paymentType.description}</S.Text>}
                    {['ON_CC', 'OFF_CC', 'DIR'].includes(paymentType?.type) && <CreditCardConditions paymentOption={paymentType} />}
                  </S.TextGroup>
                  {idx < enabledPaymentOptions?.length - 1 && <Divider sx={{
            borderColor: '#444f5d'
          }} />}
                </React.Fragment>;
      })}
          </S.ConditionsContainer>}>
        {enabledPaymentOptions?.length > 0 && <S.IconInfo onClick={() => setOpen(s => !s)} centered={displayMode !== 'VERTICAL'}>
            <NiaraIcon width={14} height={14} icon="sysInfo" color="#9C9C9C" />
          </S.IconInfo>}
      </ToolTip>;
  };
  if (displayMode === 'VERTICAL') {
    return <S.TypePaymentVertical>
        <S.TypeOptions>
          {enabledPaymentOptions?.map(r => {
          return <S.UnicTypes key={r?.id}>
                <img src={icons[r?.type]} alt="" />
                <ToolTip title={<S.ToolTipContainer>{r?.alias}</S.ToolTipContainer>} placement="top">
                  <S.TextTypePayment ellipsis={ellipsis}>{r?.alias}</S.TextTypePayment>
                </ToolTip>
              </S.UnicTypes>;
        })}
        </S.TypeOptions>
        {typePaymentInfo()}
      </S.TypePaymentVertical>;
  }
  if (availablePaymentsLabel?.length) return <S.PaymentInfo justifyContent={justifyContent}>
        {showStartIcon && <img src={icons[uniqTypes[0]?.type]} alt="Payment condition" />}

        <S.PaymentLine textSize={textSize} textColor={textColor} fontWeight={fontWeight} dangerouslySetInnerHTML={{
      __html: availablePaymentsString
    }} ellipsis={ellipsis} />
        {!disableMorePayments ? <>{availablePaymentsLabel?.length > 2 && <b>(+{availablePaymentsLabel?.length - 2})</b>}</> : null}

        {typePaymentInfo()}
      </S.PaymentInfo>;
  return null;
};
const CreditCardConditions = ({
  paymentOption
}: {
  paymentOption: PaymentOption | LegacyPaymentOption;
}) => {
  const maxCCPaymentConditions = paymentOption.oncc_conditions?.reduce((max, cur) => cur?.maxInst > (max?.maxInst || 0) ? cur : max, null);

  //Pega todas as bandeiras de cartão de crédito disponíveis e remove duplicatas
  const cardBrands = paymentOption?.oncc_conditions?.map(cc => cc.cardBrands)?.flat()?.filter(Boolean)?.filter((v, i, a) => a.indexOf(v) === i);
  if (!maxCCPaymentConditions) return null;
  const maxCCPaymentText = maxCCPaymentConditions?.maxInst > 0 && `Pague em até ${maxCCPaymentConditions?.maxInst}x.`;
  const maxCCPaymentMinInstText = 'minInstValue' in maxCCPaymentConditions && maxCCPaymentConditions?.minInstValue > 0 && 'paymentCurrency' in paymentOption && `Parcela mínima de ${printPrice(maxCCPaymentConditions?.minInstValue, paymentOption?.paymentCurrency)}.`;
  return maxCCPaymentConditions && <>
        <S.Text>{maxCCPaymentText}</S.Text>
        <S.Text>{maxCCPaymentMinInstText}</S.Text>
        <S.CardIcons>
          {cardBrands?.map((card, i) => {
        const badge = CARD_BRANDS.find(brand => brand.cardBrand === card)?.cardBadge;
        return badge && <img key={i} src={badge} />;
      })}
        </S.CardIcons>
      </>;
};
export default PaymentConditions;