import { selfFlightReservationDAO } from '@niarab2c/frontend-commons/src/daos';
import callApi from '@niarab2c/frontend-commons/src/util/callApi';
import { SelfFlightReservation } from '@niarab2c/niara-spear-crudmodel';
import { createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit';
import { format as formatDateFns } from 'date-fns';
import { nanoid } from 'nanoid/non-secure';
import getRecaptchaToken from '../../../recaptcha';
import type { CreditCard, CreditCardPaymentOption, CriteriaForm, Distribution, Flight, FlightClass, Issuance, OutputPaymentOption, PaymentOption, PriceComposition, Result } from '../../../types/flight';
import type { CompleteRootState } from '../../base';
import flightFilter, { FlightFilterState, buildOptions as flightFilterBuildOptions } from './flightFilter';
export { setPartialFilter, filterResults, filterFlightClasses } from './flightFilter';
export type { Result } from '../../../types/flight';
export type SegmentType = 'I' | 'V';
export type SegmentFlight = Omit<Result, 'voos'> & {
  voos: Array<Flight>;
};
export type Segment = {
  description: string;
  origin: string;
  originDescription: string;
  destination: string;
  destinationDescription: string;
  date: string;
  /**
   * "I" se ONEWAY, "I" e "V" se ROUNDTRIP, "1", "2", etc se "MULTICITY"
   */
  id: SegmentType | string;
  flightClass?: FlightClass;
  flight?: SegmentFlight;
  results?: Result[];
  currentPage?: number;
  flightFilter?: FlightFilterState;
};
export type SelectedFlight = {
  flightClass: FlightClass;
  flightResult: Result;
  segmentId: SegmentType | string;
};
export type FlightState = {
  criteria?: CriteriaForm;
  searchId?: string;
  searchInProgress?: boolean;
  segments?: Segment[];
  /**
   * Não usar este objeto
   * @deprecated
   */
  selected?: SelectedFlight[];
  currentSegment: SegmentType | number;
  results?: Result[];
  filteredResults?: Result[];
  airlines?: Array<{
    name;
    code;
  }>;
  flightRatesLoadInProgress?: boolean;
  flightRates?: Distribution[];
  selectedFlightRate?: Distribution;
  bookInProgress?: boolean;
  error?;
};
const createState = (): FlightState => ({
  currentSegment: 'I'
});
export const search = createAsyncThunk<{
  results: Result[];
}, {
  criteria: CriteriaForm;
  searchId: string;
}, {
  state: CompleteRootState;
}>('flight/search', async (parameters, {
  getState
}) => {
  const {
    criteria,
    searchId
  } = parameters;
  const rootState = getState();
  const landingPageLocator = rootState.storefrontConfig?.storefront?.locator;
  const clientId = rootState.core?.clientId;
  const b2c = rootState.authentication?.b2c;
  const selfBooking = b2c || rootState.authentication?.isClientUser;
  const TODAY = formatDateFns(new Date(), 'yyyy-MM-dd');

  // valida data de saída (não executar pesquisa no passado)
  if (criteria.slices.find(r => r.startDate < TODAY)) {
    throw new Error("Não é permitida busca para data passada");
  }
  const data = await callApi('niara-spear-booking', `/landing-pages/${landingPageLocator}/flights/availability`, 'post', {
    body: {
      mode: criteria.mode,
      preferredCabin: 'COACH',
      // HARD CODED??
      occupancy: criteria.occupancy,
      slices: criteria.slices,
      clientId,
      landingPage: landingPageLocator,
      selfBooking,
      b2c,
      travellerId: null,
      searchId
    }
  });
  return data;
});

/**
 * Carrega priceComposition da tarifa do carrinho para cada distribuição
 */
export const loadFlightRates = createAsyncThunk<{
  flightRates: Array<Distribution>;
}, void, {
  state: CompleteRootState;
}>('flight/loadFlightRates', async (voidValue, {
  getState
}) => {
  const rootState = getState();
  const segments = rootState.flight?.segments;
  if (segments?.length > 0) {
    const searchId = rootState.flight.searchId;
    const landingPageLocator = rootState.storefrontConfig?.storefront?.locator;
    const slices = segments.map(s => {
      const flights = s.flight.voos.reduce((acc, v) => [...acc, {
        info: v.info,
        classe: s.flightClass
      }], []);
      return {
        segment: s.id,
        info: s.flight.info,
        flights: flights
      };
    });
    const payload = {
      credentialId: segments[0].flight?.credential?.id,
      distributions: segments[0].flight?.distributions,
      slices,
      searchId
    };
    const apiname = 'niara-spear-booking';
    let apipath = '/flights/rate2'; // Não funciona (AWS_IAM)
    const headers: any = {};
    if (landingPageLocator) {
      const recaptchaToken = await getRecaptchaToken({
        action: 'getFlightRate'
      });
      headers.rcptch = recaptchaToken;
      apipath = '/landing-pages/' + landingPageLocator + '/flights/rate2';
    }
    const response = await callApi(apiname, apipath, 'post', {
      body: payload,
      headers
    });
    const distributions = response?.distributions?.filter(r => r.type == 'DIRECT'); // Só funciona com pagamento direto
    if (!response?.distributions) {
      throw new Error(`Erro ao buscar tarifas: ${response.error}`);
    }
    return {
      flightRates: distributions
    };
  } else return {
    flightRates: null
  };
});
export type Pax = {
  ageQualifying: 'ADT' | 'CHD' | 'INF';
  firstName: string;
  lastName: string;
  gender: 'M' | 'F';
  dob: string;
  email: string;
  phone: string;
  fidelity: {
    avianca;
    azul;
    gol;
    tam;
  };
  passport: string;
};
type BookParams = {
  pax: Pax[];
  sendVoucher: boolean;
};
type BookResponse = {
  id: string;
  success: true;
};

/**
 * Gera a reserva de voo
 */
export const bookFlights = createAsyncThunk<BookResponse, BookParams, {
  state: CompleteRootState;
}>('flight/bookFlights', async ({
  pax,
  sendVoucher
}, {
  getState
}) => {
  const rootState = getState();
  const segments = rootState.flight?.segments;
  if (segments?.length > 0) {
    const searchId = rootState.flight.searchId;
    const landingPageLocator = rootState.storefrontConfig?.storefront?.locator;
    const clientId = rootState?.core?.clientId;
    const flightRate = rootState?.flight?.selectedFlightRate;
    const slices = segments.map(s => {
      const flights = s.flight.voos.reduce((acc, v) => [...acc, {
        info: v.info,
        location: v.location,
        time: v.time,
        classe: s.flightClass
      }], []);
      return {
        segment: s.id,
        info: s.flight.info,
        flights: flights,
        players: s.flight.players,
        location: s.flight.location,
        distributions: s.flight.distributions,
        price: s.flightClass.priceComposition.total // Preço do voo/classe selecionado no momento da reserva, para comparação porterior com voos pesquisados na OS
      };
    });
    const payload = {
      credentialId: segments[0].flight.credential.id,
      // TODO Possivel ter voos de diferentes credenciais?
      distributionId: flightRate.id,
      startDate: segments[0].date,
      // TODO Data-hora com timezone
      endDate: segments.slice(-1)[0].date,
      // TODO Data-hora com timezone
      slices,
      pax: pax.map(p => ({
        ageQualifying: p.ageQualifying,
        firstName: p.firstName,
        lastName: p.lastName,
        gender: p.gender,
        dateOfBirth: p.dob,
        // Precisa mudar para  dob no backend!!!!
        email: p.email,
        cellphone: p.phone,
        // Precisa mudar para phone no backend (e remover o antigo phone)
        phone: p.phone,
        // Precisa mudar para phone no backend (e remover o antigo phone)
        fidelity: p.fidelity,
        documentType: p.passport ? 'PASSPORT' : undefined,
        documentNumber: p.passport
      })),
      priceComposition: flightRate.priceComposition,
      totalPrice: flightRate.priceComposition.total,
      clientId,
      // clientName ???
      searchId,
      b2c: true,
      sendVoucher
    };
    const apiname = 'niara-spear-booking';
    let apipath = '/flights/booking2'; // NÃO VAI FUNCIONAR POR CAUSA DA Authorização IAM
    const headers: any = {};
    if (landingPageLocator) {
      const recaptchaToken = await getRecaptchaToken({
        action: 'getFlightRate'
      });
      headers.rcptch = recaptchaToken;
      apipath = '/landing-pages/' + landingPageLocator + '/flights/booking2';
    }
    const response = await callApi(apiname, apipath, 'post', {
      body: payload,
      headers
    });
    return response;
  } else return {
    flightRates: null
  };
});
type LoadIssuanceResponse = Issuance;
type LoadIssuanceParams = {
  flightReservationId;
};

/**
 * Carrega as formas de pagamento e detalhes de pagamento para a emissão de uma reserva
 */
export const loadIssuance = createAsyncThunk<LoadIssuanceResponse, LoadIssuanceParams, {
  state: CompleteRootState;
}>('flight/loadIssuance', async ({
  flightReservationId
}, {
  getState
}) => {
  const rootState = getState();
  const landingPageLocator = rootState.storefrontConfig?.storefront?.locator;
  return await callApi('niara-spear-booking', `/landing-pages/${landingPageLocator}/flights/booking/${flightReservationId}/issuance2`, 'get');
});
type LoadCreditCardPaymentOptionsParams = {
  creditCard: CreditCard;
  paymentOption: PaymentOption;
  flightReservationId: string;
};
type LoadCreditCardPaymentOptionsResponse = {
  creditCardPaymentOptions: Array<CreditCardPaymentOption>;
};
/**
 * carrega as opções de parcelamento para uma reserva
 */
export const loadCreditCardPaymentOptions = createAsyncThunk<LoadCreditCardPaymentOptionsResponse, LoadCreditCardPaymentOptionsParams, {
  state: CompleteRootState;
}>('flight/loadCreditCardPaymentOptions', async ({
  creditCard,
  paymentOption,
  flightReservationId
}, {
  getState
}) => {
  const rootState = getState();
  const landingPageLocator = rootState.storefrontConfig?.storefront?.locator;
  return await callApi('niara-spear-booking', `/landing-pages/${landingPageLocator}/flights/booking/${flightReservationId}/issuance/payment2`, 'post', {
    body: {
      creditCard,
      paymentOption
    }
  }).then(r => ({
    creditCardPaymentOptions: r.Financiamentos
  }));
});
type EmitFlightTicketsParams = {
  creditCard: CreditCard;
  paymentOption: PaymentOption;
  flightReservationId: string;
  creditCardPaymentOption: CreditCardPaymentOption;
  outputPaymentOptions: OutputPaymentOption[];
  priceComposition: PriceComposition;
  email: string;
};
type EmitFlightTicketsResponse = {
  flightReservation: SelfFlightReservation;
};
export const emitFlightTickets = createAsyncThunk<EmitFlightTicketsResponse, EmitFlightTicketsParams, {
  state: CompleteRootState;
}>('flight/emitFlightTickets', async ({
  creditCard,
  paymentOption,
  flightReservationId,
  creditCardPaymentOption,
  outputPaymentOptions,
  priceComposition,
  email
}, {
  getState
}) => {
  const rootState = getState();
  const landingPageLocator = rootState.storefrontConfig?.storefront?.locator;
  const payment = {
    paymentOption,
    creditCard,
    creditCardPaymentOption
  };
  const payload = {
    payment,
    outputOptions: outputPaymentOptions,
    priceComposition
  };
  const apiname = 'niara-spear-booking';
  const apipath = `/landing-pages/${landingPageLocator}/flights/booking/${flightReservationId}/issuance2`;
  await callApi(apiname, apipath, 'post', {
    body: payload
  }).then(response => {
    if (!response.success) {
      throw new Error(`Erro ao emitir bilhetes: ${response.error}`);
    }
    return response;
  });

  // se chegou aqui, os bilhetes estão sendo emitidos
  const flightReservation = await new Promise((res, rej) => {
    const timeoutId = setInterval(() => {
      selfFlightReservationDAO.load({
        id: flightReservationId,
        email
      }).then(flightReservation => {
        if (flightReservation.issuanceStatus == 'ISSUED') {
          res(flightReservation);
          clearInterval(timeoutId);
          return;
        } else if (flightReservation.issuanceStatus != 'PROCESSING') {
          rej(new Error(`Erro emitindo bilhetes: ${flightReservation?.issuanceError ?? flightReservation?.errors?.join?.(', ') ?? ''}`));
          clearInterval(timeoutId);
          return;
        }
      });
    }, 2000);
  });
  return {
    flightReservation
  };
});
const chunkArray = (results: Result[], size: number): Result[][] => results.length > size ? [results.slice(0, size), ...chunkArray(results.slice(size), size)] : [results];
const flightSlice = createSlice({
  name: 'flight',
  initialState: createState(),
  reducers: {
    setFlightRateChoice: (state, action: PayloadAction<SelectedFlight>) => {
      //TODO: não deixar selecionar itens de credenciais diferentes
      state.selected = [...(state.selected?.map(selectedFlight => selectedFlight.flightResult.info.segmento === action.payload.flightResult.info.segmento ? action.payload : selectedFlight) ?? []), ...(state.selected?.map(flight => flight.flightResult.info.segmento)?.includes(action.payload.flightResult.info.segmento) ? [] : [action.payload])];
      const segment = state.segments.find(segment => segment.id == action.payload.segmentId);
      segment.flight = action.payload.flightResult;
      segment.flightClass = action.payload.flightClass;
      state.flightRates = null;
      state.selectedFlightRate = null;
    },
    setFlightSegment: (state, action) => {
      state.currentSegment = action.payload;
    },
    setCurrentPageSegment: (state, action) => {
      state.segments = state.segments.map(segment => state.currentSegment === segment.id ? {
        ...segment,
        currentPage: action.payload
      } : segment);
    },
    clear: (state, action) => {
      return createState();
    }
  },
  extraReducers: builder => {
    builder.addCase(search.pending, (prevState, action) => {
      const state = createState();
      const criteria = action.meta.arg.criteria;
      state.criteria = criteria;
      state.searchInProgress = true;
      state.searchId = action.meta.arg.searchId ?? nanoid();
      state.flightRates = null;
      state.selectedFlightRate = null;
      const mode = criteria.mode;
      const segments: Segment[] = [];
      if (mode === 'ROUNDTRIP') {
        const s = criteria.slices[0];
        segments.push({
          description: s.origin + '->' + s.destination,
          origin: s.origin,
          originDescription: s.originDescription,
          destination: s.destination,
          destinationDescription: s.destinationDescription,
          date: s.startDate,
          id: 'I'
        });
        segments.push({
          description: s.destination + '->' + s.origin,
          origin: s.destination,
          originDescription: s.destinationDescription,
          destination: s.origin,
          destinationDescription: s.originDescription,
          date: s.endDate,
          id: 'V'
        });
      } else if (mode === 'MULTICITY') {
        segments.push(...criteria.slices.map(function (s, idx) {
          return {
            description: s.origin + '->' + s.destination,
            origin: s.origin,
            originDescription: s.originDescription,
            destination: s.destination,
            destinationDescription: s.destinationDescription,
            date: s.startDate,
            id: '' + (idx + 1)
          };
        }));
      } else {
        const s = criteria.slices[0];
        segments.push({
          description: s.origin + '->' + s.destination,
          origin: s.origin,
          originDescription: s.originDescription,
          destination: s.destination,
          destinationDescription: s.destinationDescription,
          date: s.startDate,
          id: 'I'
        });
      }
      state.segments = segments;
      return state;
    }).addCase(search.rejected, (state, action) => {
      state.searchInProgress = false;
      state.error = action.error;
    }).addCase(search.fulfilled, (state, action) => {
      if (action.meta.arg.searchId === state.searchId) {
        const results = action.payload.results;
        state.results = results;
        state.searchInProgress = false;
        // "distribui" os resultados entre os segmentos da pesquisa (ida, volta ou múltiplas cidades)
        state.segments = state.segments.map(segment => {
          const segmentResults = results.filter(r => r.info.segmento == segment.id)?.sort((prev, next) => prev.info.horaSaida > next.info.horaSaida ? 1 : -1);
          return {
            ...segment,
            results: segmentResults,
            currentPage: 0,
            flightFilter: flightFilter(segment?.flightFilter, flightFilterBuildOptions(segmentResults))
          };
        });
      }
    }).addCase(loadFlightRates.pending, (state, action) => {
      state.flightRates = null;
      state.selectedFlightRate = null;
      state.flightRatesLoadInProgress = true;
    }).addCase(loadFlightRates.fulfilled, (state, action) => {
      state.flightRates = action.payload.flightRates;
      state.selectedFlightRate = action.payload.flightRates?.[0];
      state.flightRatesLoadInProgress = false;
    }).addCase(loadFlightRates.rejected, (state, error) => {
      state.error = error;
      state.flightRates = null;
      state.selectedFlightRate = null;
      state.flightRatesLoadInProgress = false;
    }).addCase(bookFlights.pending, state => {
      state.bookInProgress = true;
    }).addCase(bookFlights.fulfilled, state => {
      // return createState()  não limpar, pq se der erro no processing, vai voltar pra cá
      state.bookInProgress = false;
    }).addCase(bookFlights.rejected, state => {
      state.bookInProgress = false;
    }).addMatcher(action => action.type?.startsWith('flightFilter'), (state, action) => {
      const newSegments = state.segments.map(segment => {
        if (segment.id == state.currentSegment) {
          // Executa o reducer do flightFilter do segmento ativo.
          return {
            ...segment,
            flightFilter: flightFilter(segment.flightFilter || undefined, action)
          };
        }
        // Segmentos não ativos não são modificados (cada segmento tem sua própria instância de flightFilter)
        return segment;
      });
      return {
        ...state,
        segments: newSegments
      };
    });
  }
});
export const {
  setFlightRateChoice,
  setFlightSegment,
  setCurrentPageSegment,
  clear
} = flightSlice.actions;
export default flightSlice.reducer;