import React, { useCallback, useEffect, useLayoutEffect, useState } from 'react';
import useTheme from '../../theme/useTheme';
import * as S from './styles';
export type Variant = 'modal' | 'full' | 'side' | 'block' | 'none';
type HideFunction = {
  ();
};
export interface Props {
  title?: React.ReactNode;
  /** Apenas para full (por enquanto) */
  customTitleRender?: (p: {
    hide: HideFunction;
  }) => React.ReactNode;
  showCloseButton?: boolean;
  show: boolean;
  setShow?: {
    (show: boolean);
  };
  onHidden?: {
    (hidden: boolean);
  };
  render?: (p: {
    hide: HideFunction;
    hidden: boolean;
  }) => React.ReactNode;
  mobileVariant?: Variant;
  tabletVariant?: Variant;
  desktopVariant?: Variant;
  paddingZero?: boolean;
  headerSize?: string;
  zIndex?: number;
  sidePosition?: 'left' | 'right';
  /** Apenas para full, mostra o modal de baixo para cima */
  transitionReverse?: boolean;
  className?: string;
  closeButtonProps?: React.HTMLAttributes<HTMLButtonElement>;
  maxWidth?: string;
  dataTestId?: string;
}
const CloseIcon = () => <svg className="close-icon" xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 24 24">
    <g transform="translate(-939 -130)">
      <rect width="24" height="24" transform="translate(939 130)" fill="none" opacity={0} />
      <line y2="23" transform="translate(959.264 134) rotate(45)" fill="none" strokeWidth="1.5px" strokeLinecap="round" />
      <line y2="23" transform="translate(959.264 150.264) rotate(135)" fill="none" strokeWidth="1.5px" strokeLinecap="round" />
    </g>
  </svg>;
const Modal: React.FC<React.PropsWithChildren<Props>> = props => {
  const {
    mobileVariant,
    tabletVariant,
    desktopVariant,
    showCloseButton,
    title,
    render,
    onHidden,
    show,
    setShow,
    customTitleRender,
    zIndex: _zIndex,
    transitionReverse,
    paddingZero,
    headerSize,
    sidePosition = 'right',
    className,
    closeButtonProps,
    maxWidth,
    dataTestId
  } = props;
  const [hidden, setHidden] = useState(() => !show);
  const [appearing, setAppearing] = useState(() => show);
  const hasTitle = title != null || customTitleRender != null;
  const theme = useTheme();
  const zIndex = _zIndex != null ? _zIndex : theme.zIndex.modal;
  useLayoutEffect(() => {
    if (show && hidden) {
      setHidden(false);
    } else if (!show && !hidden) {
      const timeoutId = setTimeout(() => {
        setHidden(true);
      }, 300);
      return () => {
        clearTimeout(timeoutId);
      };
    }
  }, [hidden, show]);
  useLayoutEffect(() => {
    if (show) {
      setAppearing(true);
      const timeoutId = setTimeout(() => {
        setAppearing(false);
      }, 400);
      return () => {
        clearTimeout(timeoutId);
      };
    }
  }, [show]);
  const hide: HideFunction = useCallback(() => {
    if (show) {
      setTimeout(() => {
        setHidden(true);
      }, 300);
      setShow?.(false);
    }
  }, [setShow, show]);
  useEffect(() => {
    onHidden?.(hidden);
  }, [hidden, onHidden]);
  if (hidden && !show) return null;
  return <>
      <S.ModalContainer className={!show ? `hidden` : appearing ? `appearing` : `${className}`} mobileVariant={mobileVariant} tabletVariant={tabletVariant} desktopVariant={desktopVariant} hasTitle={hasTitle} headerSize={headerSize} zIndex={zIndex} sidePosition={sidePosition} transitionReverse={transitionReverse} maxWidth={maxWidth} paddingZero={paddingZero}>
        <S.ModalDialog className={!show ? 'hidden' : appearing ? 'appearing' : ''} mobileVariant={mobileVariant} tabletVariant={tabletVariant} desktopVariant={desktopVariant} hasTitle={hasTitle} zIndex={zIndex} transitionReverse={transitionReverse} maxWidth={maxWidth} paddingZero={paddingZero}>
          <S.ModalContent className={!show ? 'hidden' : appearing ? 'appearing' : ''} mobileVariant={mobileVariant} tabletVariant={tabletVariant} desktopVariant={desktopVariant} hasTitle={hasTitle} zIndex={zIndex} transitionReverse={transitionReverse} padding={paddingZero ? '0' : null} margin={paddingZero ? '0' : null} maxWidth={maxWidth} paddingZero={paddingZero}>
            {customTitleRender != null ? <S.CustomTitleWrapper className={!show ? 'hidden' : appearing ? 'appearing' : ''} mobileVariant={mobileVariant} tabletVariant={tabletVariant} desktopVariant={desktopVariant} hasTitle={hasTitle} zIndex={zIndex} transitionReverse={transitionReverse} sidePosition={sidePosition} paddingZero={paddingZero} maxWidth={maxWidth}>
                {customTitleRender({
              hide
            })}
              </S.CustomTitleWrapper> : title != null && <S.ModalTitle className={!show ? 'hidden' : appearing ? 'appearing' : ''} mobileVariant={mobileVariant} tabletVariant={tabletVariant} desktopVariant={desktopVariant} hasTitle={hasTitle} zIndex={zIndex} transitionReverse={transitionReverse} paddingZero={paddingZero} sidePosition={sidePosition} maxWidth={maxWidth}>
                  {title}
                </S.ModalTitle>}
            {showCloseButton !== false && setShow != null && <S.ModalCloseButton onClick={() => hide()} className={!show ? 'hidden' : appearing ? 'appearing' : ''} mobileVariant={mobileVariant} tabletVariant={tabletVariant} desktopVariant={desktopVariant} hasTitle={hasTitle} zIndex={zIndex} transitionReverse={transitionReverse} sidePosition={sidePosition} maxWidth={maxWidth} paddingZero={paddingZero} data-testid={dataTestId} {...closeButtonProps}>
                <CloseIcon />
              </S.ModalCloseButton>}
            {props.children}
            {render ? render({
            hide,
            hidden
          }) : null}
          </S.ModalContent>
        </S.ModalDialog>
      </S.ModalContainer>
      <S.ModalBackdrop className={!show ? 'hidden' : appearing ? 'appearing' : ''} mobileVariant={mobileVariant} tabletVariant={tabletVariant} desktopVariant={desktopVariant} hasTitle={hasTitle} zIndex={zIndex} transitionReverse={transitionReverse} />
    </>;
};
export default Modal;