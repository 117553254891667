import React from 'react';
import fillCrudMetadataGaps from '../../fillCrudMetadataGaps';
import authorization from '../../util/authorization';
import DEFAULT_TAG_GROUPS from '../defaultTagGroups';
import { currencies } from '../currencyExchangeRule';
import type { Tenant } from '..';
import { printPrice } from '@niaratech/niara-js-commons';
export const PAYMENT_TYPES = [{
  code: 'DIR',
  label: 'Pagamento direto',
  abrev: 'dir',
  fa: 'fas fa-hand-holding-usd'
}, {
  code: 'DIR_IATA',
  label: 'Pagamento direto IATA',
  abrev: 'iata',
  fa: 'fas fa-globe'
}, {
  code: 'OFF_CC',
  label: 'Cartão de crédito Offline',
  abrev: 'CC',
  fa: 'fas fa-credit-card'
}, {
  code: 'ON_CC',
  label: 'Cartão de crédito Online',
  abrev: 'CC',
  fa: 'fas fa-credit-card'
}, {
  code: 'OFF_ANT',
  label: 'Antecipado',
  abrev: 'ant',
  fa: 'fas fa-piggy-bank'
}, {
  code: 'OFF_FAT',
  label: 'Faturado',
  abrev: 'fat',
  fa: 'fas fa-file-invoice-dollar'
}];
export default fillCrudMetadataGaps({
  label: 'Cliente',
  labelPlural: 'Clientes',
  name: 'Client',
  faIcon: 'building',
  insertable: true,
  updatable: true,
  deletable: false,
  custom_withAudit: true,
  authorization: authorization('clientConsultant', 'admin'),
  columns: ['id', 'corporate_name', 'brand_name', 'vat', 'city', 'state_acronym', 'tags', 'enabled'],
  fields: [{
    label: 'Id',
    name: 'id',
    type: 'text',
    updatable: false,
    insertable: false,
    id: true,
    render: false,
    'custom:shallowClient': true // habilita para consulta sem autenticação (ex: OTABuilder)
  }, {
    custom_group: 'details',
    name: 'corporate_name',
    label: 'Razão Social',
    required: true,
    searchableText: true,
    'custom:shallowClient': true
  }, {
    custom_group: 'details',
    name: 'brand_name',
    label: 'Nome Fantasia',
    required: true,
    searchableText: true,
    'custom:shallowClient': true // habilita para consulta sem autenticação (ex: OTABuilder)
  }, {
    custom_group: 'details',
    name: 'vat',
    label: 'CNPJ',
    // required: true,
    mask: '99.999.999/9999-99',
    'custom:shallowClient': true
  }, {
    custom_group: 'details',
    name: 'br_withholdsTaxes',
    label: 'Retem impostos na fonte',
    type: 'boolean',
    custom_checkTenant: t => t && (!t.country_code || t.country_code === 'BR'),
    hint: 'De acordo com a lei 10.833/2003, artigo 34, empresas públicas, sociedades de economia mista e entidades que a União detenha a maioria do capital social devem reter na fonte os impostos IRPJ, CSLL, PIS e COFINS no pagamento de bens e serviços tomados. Esta configuração é utilizada em funções relacionadas a cobranças por cartão e VCN.'
    // 'De acordo com a lei 9.430/1996, artigo 64, pagamentos por bens e serviços tomados por órgãos e entidades de administração pública federal devem ser realizados pelo valor após dedução dos impostos IRPJ, CSLL, PIS e COFINS, que são retidos na fonte. ',
    // 'De acordo com a Instrução Normativa RFB no. 1.234, de 11 de janeiro de 2012, artigo 10, pagamentos com cartão de débito ou crédito para serviços tomados por órgãos e entidades de administração pública federal devem ser realizados pelo valor após dedução dos impostos IRPJ, CSLL, PIS e COFINS, que são retidos na fonte. Esta configuração é lida pelo sistema para a emissão de VCNs e cobrança de pagamentos por cartão.',
  }, {
    custom_group: 'details',
    name: 'website',
    label: 'Website',
    'custom:shallowClient': true
  }, {
    custom_group: 'details',
    name: 'address',
    label: 'Endereço',
    'custom:shallowClient': true
  }, {
    custom_group: 'details',
    name: 'address_number',
    label: 'Número',
    'custom:shallowClient': true
  }, {
    custom_group: 'details',
    name: 'address_apartment',
    label: 'Complemento',
    'custom:shallowClient': true
  }, {
    custom_group: 'details',
    name: 'city',
    label: 'Cidade',
    renderColumn: i => i.city && i.city + ' - ' + i.state_acronym,
    'custom:shallowClient': true
  }, {
    custom_group: 'details',
    name: 'state_acronym',
    label: 'Estado',
    renderColumn: false,
    'custom:shallowClient': true
  }, {
    custom_group: 'details',
    name: 'country_code',
    required: true,
    label: 'País',
    inputType: 'countryCode',
    'custom:shallowClient': true
  }, {
    custom_group: 'details',
    name: 'zip_code',
    label: 'CEP',
    mask: '_____-___'
  }, {
    custom_group: 'details',
    name: 'tags',
    label: 'Marcadores',
    type: 'textarray',
    clientEnabled: true,
    travellerEnabled: false,
    reservationEnabled: false
  }, {
    custom_group: 'main_contact',
    custom_hiddenFromCreateView: false,
    name: 'main_contact_name',
    label: 'Nome do contato principal',
    'custom:excludeForCurrentClient': true
  }, {
    custom_group: 'main_contact',
    custom_hiddenFromCreateView: true,
    name: 'main_contact_position',
    label: 'Cargo',
    'custom:excludeForCurrentClient': true
  }, {
    custom_group: 'main_contact',
    custom_hiddenFromCreateView: false,
    name: 'main_contact_cellphone',
    label: 'Celular',
    inputType: 'phoneNumber',
    'custom:excludeForCurrentClient': true,
    'custom:shallowClient': true
  }, {
    custom_group: 'main_contact',
    custom_hiddenFromCreateView: true,
    name: 'main_contact_phone',
    label: 'Telefone',
    inputType: 'phoneNumber',
    'custom:excludeForCurrentClient': true,
    'custom:shallowClient': true
  }, {
    custom_group: 'main_contact',
    custom_hiddenFromCreateView: true,
    name: 'main_contact_whatsapp',
    label: 'Whatsapp',
    inputType: 'phoneNumber',
    'custom:excludeForCurrentClient': true,
    'custom:shallowClient': true
  }, {
    custom_group: 'main_contact',
    custom_hiddenFromCreateView: false,
    name: 'main_contact_email',
    label: 'E-mail',
    'custom:excludeForCurrentClient': true,
    'custom:shallowClient': true,
    inputType: 'email'
  }, {
    custom_group: 'main_contact',
    custom_hiddenFromCreateView: false,
    name: 'main_contact_notification_emails',
    label: 'E-mail para avisos',
    type: 'textarray',
    hint: 'E-mails de criação de reservas, cancelamento de reservas e upload de notas ficais para esses emails. Separar com ENTER.',
    inputType: 'emailArray',
    'custom:excludeForCurrentClient': false
  }, {
    custom_group: 'main_contact',
    custom_hiddenFromCreateView: true,
    name: 'sendMail_fromNameDefaultOption',
    label: 'Nome do remetente de e-mails enviados',
    type: 'text',
    options: [{
      value: 'CLIENT_NAME',
      label: 'Nome do cliente'
    }, {
      value: 'TENANT_NAME',
      label: 'Nome da instalação'
    }, {
      value: 'CUSTOM',
      label: 'Nome customizado'
    }],
    inputType: 'select',
    hint: 'Padrão do nome do remetente nos e-mails enviados, quando selfbooking. Se existir remetente configurado no template de e-mail, esta configuração será ignorada.',
    custom_checkTenant: tenant => tenant?.sendMail_fromNameDefaultOption !== 'ALWAYS_TENANT_NAME' && tenant?.sendMail_fromNameDefaultOption !== 'ALWAYS_CUSTOM' && true
  }, {
    custom_group: 'main_contact',
    custom_hiddenFromCreateView: true,
    custom_groupName: "Dados de notificação",
    name: 'sendMail_customFromName',
    label: 'Nome do remetente de e-mails enviados customizado',
    type: 'text',
    renderForm: i => i?.sendMail_fromNameDefaultOption == 'CUSTOM' || i?.sendMail_fromNameDefaultOption == 'ALWAYS_CUSTOM' ? i.sendMail_customFromName : false,
    custom_checkTenant: tenant => tenant?.sendMail_fromNameDefaultOption !== 'ALWAYS_TENANT_NAME' && tenant?.sendMail_fromNameDefaultOption !== 'ALWAYS_CUSTOM' && true
  }, {
    custom_group: 'main_contact',
    custom_groupName: "Dados de notificação",
    name: 'sendMail_replyToOption',
    label: 'E-mail de resposta',
    type: 'text',
    options: [{
      value: 'CURRENT_USER',
      label: 'E-mail do usuário logado'
    }, {
      value: 'CUSTOM',
      label: 'E-mail cadastrado (exceto selfbooking)'
    }],
    inputType: 'select',
    hint: 'Endereço de e-mail que receberá respostas de e-mails de cotação e link de pagamento, quando selfbooking',
    custom_checkTenant: tenant => tenant?.sendMail_replyToOption !== 'ALWAYS_CURRENT_USER' && tenant?.sendMail_replyToOption !== 'ALWAYS_CUSTOM' && true
  }, {
    custom_group: 'main_contact',
    custom_groupName: "Dados de notificação",
    name: 'sendMail_customReplyTo',
    label: 'E-mail de resposta cadastrado',
    type: 'text',
    inputType: 'email',
    renderForm: function (i) {
      return i?.sendMail_replyToOption == 'CUSTOM' ? this.render(i) : false;
    },
    custom_checkTenant: tenant => tenant?.sendMail_replyToOption !== 'ALWAYS_CURRENT_USER' && tenant?.sendMail_replyToOption !== 'ALWAYS_CUSTOM' && true
  }, {
    custom_group: 'details',
    custom_hiddenFromCreateView: true,
    name: 'logo',
    label: 'Logotipo',
    inputType: 'inputPublicFile',
    insertable: false,
    updatable: true,
    render: i => i.logo && <img src={i.logo} className="border max-width-200"></img>,
    custom_gerarPrefix: i => {
      return 'clients/' + i.id + '/mainlogo/';
    },
    hint: 'Logotipo da empresa. Usado no voucher. Utilize imagem com fundo transparente (.png). (Resolução ideal: 120 x 60 pixels) O logotipo será redimensionado proporcionalmente com máximo de 250px de largura e 60px de altura.'
  }, {
    custom_group: 'details',
    custom_hiddenFromCreateView: true,
    name: 'logoSmall',
    label: 'Logotipo do menu reduzido',
    inputType: 'inputPublicFile',
    insertable: false,
    updatable: true,
    render: i => i.logo && <img src={i.logo} className="border max-width-200"></img>,
    custom_gerarPrefix: i => {
      return 'clients/' + i.id + '/mainlogoSmall/';
    },
    hint: 'Logotipo que aparecere quando o menu lateral estiver fechado. Utilize imagem com fundo transparente (.png). Tamanho recomendado: 24px x 40px.'
  }, {
    custom_group: 'customization',
    custom_hiddenFromCreateView: true,
    name: 'favicon',
    label: 'Favicon',
    inputType: 'inputPublicFile',
    insertable: false,
    updatable: true,
    custom_gerarPrefix: i => i.id + '/favicon/',
    hint: 'Tamanhos recomendados em pixels: 32x32, 96x96 ou 256x256. Apenas formato ICO ou PNG. Exibirá na cotação e link de pagamento.',
    'custom:shallowClient': true
  }, {
    custom_group: 'customization',
    custom_hiddenFromCreateView: true,
    name: 'voucher_replacelogo',
    label: 'Usar logotipo no cabeçalho voucher',
    type: 'boolean',
    insertable: false,
    updatable: true,
    default: false,
    render: i => i.voucher_replacelogo ? "Sim" : "Não",
    hint: 'Utilizar o logotipo do cliente no voucher (aba detalhes do cliente), substituindo o logotipo padrão',
    'custom:excludeForCurrentClient': true
  }, {
    custom_group: 'customization',
    custom_hiddenFromCreateView: true,
    name: 'voucher_headerColor',
    label: 'Cor cabeçalho do voucher',
    inputType: 'color',
    hint: 'Valor hexadecimal da cor de fundo do cabeçalho do voucher.',
    placeholder: 'ex: #Eb3030',
    renderForm: i => i.voucher_headerColor ? <div className="p-2" style={{
      backgroundColor: i.voucher_headerColor
    }}>
            {i.voucher_headerColor}
          </div> : null,
    mask: '#______',
    'custom:excludeForCurrentClient': true
  }, {
    custom_group: 'customization',
    custom_hiddenFromCreateView: true,
    name: 'mailResultText',
    label: 'Texto de cotação por e-mail',
    type: 'textarray',
    inputType: 'textarea',
    hint: 'Texto padrão para o e-mail de cotação',
    deprecated: true,
    renderForm: false
  }, {
    custom_group: 'customization',
    custom_hiddenFromCreateView: true,
    name: 'systemLocale',
    label: 'Idioma/ Região do sistema',
    options: [{
      label: 'Português (Brasil)',
      value: 'pt-BR'
    }, {
      label: 'Inglês',
      value: 'en'
    }, {
      label: 'Espanhol',
      value: 'es'
    }, {
      label: 'Espanhol (MX)',
      value: 'es-MX'
    }, {
      label: 'Espanhol (CO)',
      value: 'es-CO'
    }],
    inputType: 'select',
    'custom:shallowClient': true // habilita para consulta sem autenticação (ex: OTABuilder)
  }, {
    custom_group: 'customization',
    custom_hiddenFromCreateView: true,
    name: 'engineRule_enforceVersion_deleteme',
    label: 'Forçar versão da pesquisa',
    hint: 'Para usar na migração da pesquisa do novo motor',
    withNullSelector: true,
    options: [{
      label: 'c/ Distribuição',
      value: '0'
    }, {
      label: 'Novo motor',
      value: '1'
    }],
    inputType: 'select',
    'custom:shallowClient': true,
    // habilita para consulta sem autenticação (ex: OTABuilder)
    custom_checkTenant: (tenant: Tenant) => {
      return tenant?.modules_engineRuleOptIn_deleteme;
    }
  }, {
    custom_group: 'selfBooking',
    custom_hiddenFromCreateView: true,
    name: 'selfBooking_enabled',
    label: '(SEM USO)',
    default: false,
    type: 'boolean',
    render: false,
    renderForm: false,
    deprecated: true
  }, {
    custom_group: 'obt',
    custom_hiddenFromCreateView: true,
    name: 'obt_enabled',
    label: 'Habilitar Gestão Corporativa de Viagens (SEM USO)',
    default: false,
    type: 'boolean',
    render: false,
    renderForm: false,
    deprecated: true
  }, {
    custom_group: 'obt',
    custom_hiddenFromCreateView: true,
    name: 'obt_required_tab_groups',
    label: 'Grupos de marcadores obrigatórios (SEM USO)',
    type: 'textarray',
    options: DEFAULT_TAG_GROUPS,
    render: false,
    renderForm: false,
    deprecated: true
  }, {
    custom_group: 'booking',
    custom_hiddenFromCreateView: true,
    name: 'booking_observation',
    label: 'Observação da reserva',
    inputType: 'textarea'
  }, {
    custom_group: 'booking',
    custom_hiddenFromCreateView: true,
    name: 'restrict_nonrefundable',
    label: 'Bloquear tarifas não reembolsáveis',
    default: false,
    //Pedido https://omnibees.aha.io/features/NIT-1068
    type: 'boolean',
    'custom:shallowClient': true // habilita para consulta sem autenticação (ex: OTABuilder)
  }, {
    custom_group: 'booking',
    custom_hiddenFromCreateView: true,
    name: 'restrict_inpenalty',
    label: 'Bloquear tarifas em penalidade',
    default: false,
    //Pedido https://omnibees.aha.io/features/NIT-1068
    type: 'boolean',
    'custom:shallowClient': true // habilita para consulta sem autenticação (ex: OTABuilder)
  }, {
    custom_group: 'booking',
    custom_hiddenFromCreateView: true,
    name: 'restrict_one_price',
    label: 'Apresentar apenas o melhor preço por quarto na busca',
    default: false,
    type: 'boolean'
  }, {
    custom_group: 'booking',
    custom_hiddenFromCreateView: true,
    default: false,
    name: 'credential_exclusive',
    label: 'Buscar apenas nas credenciais do cliente',
    type: 'boolean'
  }, {
    custom_group: 'booking',
    custom_hiddenFromCreateView: true,
    name: 'getHotelAvailFilterPaymentTypes',
    label: 'Filtro padrão: tipo de pagamento',
    type: 'textarray',
    options: PAYMENT_TYPES.map(p => ({
      value: p.code,
      label: p.label
    }))
  }, {
    custom_group: 'booking',
    custom_hiddenFromCreateView: true,
    name: 'getHotelAvailFilterCommissionMin',
    label: 'Filtro padrão: comissão mínima',
    type: 'float',
    inputType: 'percentage'
  }, {
    custom_group: 'booking',
    custom_hiddenFromCreateView: true,
    name: 'getHotelAvailFilterRateTypes',
    label: 'Filtro padrão: tipo de tarifa',
    type: 'textarray',
    options: [{
      value: 'NET',
      label: 'Tarifa NET'
    }, {
      value: 'MARKUP',
      label: 'Markup'
    }, {
      value: 'COMM',
      label: 'Comissionada'
    }]
  }, {
    custom_group: 'booking',
    custom_hiddenFromCreateView: true,
    name: 'getHotelAvailFilterBreakfast',
    label: 'Filtro padrão: Café da manhã',
    type: 'boolean'
  }, {
    custom_group: 'booking',
    custom_hiddenFromCreateView: true,
    default: true,
    name: 'promoCodeEnabled',
    label: 'Habilitar Campo de Código Promocional',
    type: 'boolean',
    'custom:shallowClient': true
  }, {
    custom_group: 'booking',
    custom_hiddenFromCreateView: true,
    default: true,
    name: 'modules_checkout_experimental',
    label: 'Habilitar Checkout Experimental (SÓ DEVERIA MOSTRAR EM DEV)',
    type: 'boolean',
    'custom:shallowClient': false,
    custom_checkTenant: t => t?.DevOrHomologOrProd == 'DEV'
  }, {
    custom_group: 'flight',
    custom_hiddenFromCreateView: true,
    custom_groupName: "Filtros Padrões",
    name: 'flightStopsFilter',
    label: 'Filtro: Número de paradas',
    type: 'textarray',
    options: [{
      label: 'Nenhuma',
      value: 'NONE'
    }, {
      label: 'Uma parada',
      value: 'ONE'
    }, {
      label: 'Duas ou mais paradas',
      value: 'MULTIPLE'
    }]
  }, {
    custom_group: 'flight',
    custom_hiddenFromCreateView: true,
    custom_groupName: "Filtros Padrões",
    name: 'flightDepartureFilter',
    label: 'Filtro: Horário Partida',
    type: 'textarray',
    options: [{
      label: 'Manhã (5h-12h)',
      value: 'MORNING_DEPARTURE'
    }, {
      label: 'Tarde (12h-18h)',
      value: 'EVENING_DEPARTURE'
    }, {
      label: 'Noite (18h-0h)',
      value: 'NIGHT_DEPARTURE'
    }, {
      label: 'Madrudaga (0h-5h)',
      value: 'DAWN_DEPARTURE'
    }]
  }, {
    custom_group: 'flight',
    custom_hiddenFromCreateView: true,
    custom_groupName: "Filtros Padrões",
    name: 'flightArrivalFilter',
    label: 'Filtro: Horário Chegada',
    type: 'textarray',
    options: [{
      label: 'Manhã (5h-12h)',
      value: 'MORNING_ARRIVAL'
    }, {
      label: 'Tarde (12h-18h)',
      value: 'EVENING_ARRIVAL'
    }, {
      label: 'Noite (18h-0h)',
      value: 'NIGHT_ARRIVAL'
    }, {
      label: 'Madrudaga (0h-5h)',
      value: 'DAWN_ARRIVAL'
    }]
  }, {
    custom_group: 'vcn',
    custom_hiddenFromCreateView: true,
    name: 'vcn_can_be_disabled',
    label: 'Consultor pode desligar VCN',
    type: 'boolean',
    default: false,
    'custom:excludeForCurrentClient': true
  }, {
    custom_group: 'distribution',
    custom_hiddenFromCreateView: true,
    name: 'distribution_login',
    label: 'Login',
    insertable: false,
    updatable: false,
    'custom:excludeForCurrentClient': true
  }, {
    custom_group: 'distribution',
    custom_hiddenFromCreateView: true,
    name: 'distribution_password',
    label: 'Senha',
    insertable: false,
    updatable: true,
    'custom:excludeForCurrentClient': true
  }, {
    custom_group: 'distribution',
    custom_hiddenFromCreateView: true,
    name: 'distribution_endpoint',
    label: 'Endpoint',
    insertable: false,
    updatable: false,
    'custom:excludeForCurrentClient': true
  }, {
    custom_group: 'integration',
    custom_hiddenFromCreateView: true,
    name: 'integration_ignoreIntegration',
    label: 'Ignorar envio para backoffice',
    type: 'boolean',
    insertable: true,
    default: false,
    updatable: true,
    'custom:excludeForCurrentClient': true
  }, {
    custom_group: 'integration',
    custom_hiddenFromCreateView: true,
    name: 'integration_externalCode',
    label: 'Código Externo',
    insertable: true,
    updatable: true,
    'custom:excludeForCurrentClient': true
  }, {
    custom_group: 'integration',
    custom_hiddenFromCreateView: true,
    name: 'integration_blockable',
    label: 'Bloquear se não tem limite',
    type: 'boolean',
    insertable: true,
    default: false,
    updatable: true,
    'custom:excludeForCurrentClient': true
  }, {
    custom_group: 'integration',
    custom_hiddenFromCreateView: true,
    name: 'integration_notificationEndpoints',
    label: 'Endpoints para notificação',
    type: 'objectarray',
    graphQLType: '[NestedClientNotificationEndpoint]',
    graphQLInputType: '[NestedClientNotificationEndpointInput]',
    insertable: false,
    updatable: true,
    fields: [{
      name: 'endpoint',
      label: 'Endpoint',
      required: true,
      type: 'text'
    }, {
      name: 'headers',
      label: 'Headers',
      type: 'objectarray',
      graphQLType: '[NestedClientNotificationEndpointHeader]',
      graphQLInputType: '[NestedClientNotificationEndpointHeaderInput]',
      fields: [{
        name: 'key',
        label: 'Chave',
        required: true,
        type: 'text'
      }, {
        name: 'value',
        label: 'Valor',
        required: true,
        type: 'text'
      }]
    }],
    hint: 'Serão notificados eventos de criação e alteração de viagens.',
    'custom:excludeForCurrentClient': true,
    renderForm: i => i.integration_notificationEndpoints && <div>
            {i.integration_notificationEndpoints.map((e, i) => <p key={i}>{e.endpoint}</p>)}
          </div>
  }, {
    custom_group: 'gdsQueues',
    custom_hiddenFromCreateView: true,
    name: 'gdsQueuesPriceTolerance',
    label: 'Tolerancia de valor',
    type: 'float',
    inputType: 'percentage',
    insertable: true,
    updatable: true,
    'custom:excludeForCurrentClient': true,
    hint: 'Tolerancia para booking automatico de hotel'
  }, {
    custom_group: 'gdsQueues',
    custom_hiddenFromCreateView: true,
    name: 'gdsQueuesMinGuests',
    label: 'Mínima Capacidade de quarto',
    type: 'int',
    insertable: true,
    updatable: true,
    'custom:excludeForCurrentClient': true
  }, {
    custom_group: 'gdsQueues',
    custom_hiddenFromCreateView: true,
    name: 'gdsQueuesMaxGuests',
    label: 'Máxima capacidade de quarto',
    type: 'int',
    insertable: true,
    updatable: true,
    'custom:excludeForCurrentClient': true
  }, {
    custom_group: 'gdsQueues',
    custom_hiddenFromCreateView: true,
    name: 'gdsQueuesMeal',
    label: 'Regime de Alimentação Mínimo',
    insertable: true,
    updatable: true,
    options: [{
      label: 'Nada',
      value: 'NOTHING'
    }, {
      label: 'Café da manhã',
      value: 'BREAKFAST'
    }],
    inputType: 'select',
    'custom:excludeForCurrentClient': true
  }, {
    custom_group: 'gdsQueues',
    custom_hiddenFromCreateView: true,
    name: 'gdsQueuesNonRefundableEnabled',
    label: 'Permitir reservas em penalidade ou não reembolsáveis',
    type: 'boolean',
    insertable: true,
    updatable: true,
    'custom:excludeForCurrentClient': true
  }, {
    custom_group: 'gdsQueues',
    custom_hiddenFromCreateView: true,
    name: 'gdsQueuesRateSelection',
    label: 'Configuração de seleção de tarifa',
    insertable: true,
    updatable: true,
    options: [{
      label: 'Mais barata',
      value: 'MIN_PRICE'
    }, {
      label: 'Melhor remuneração',
      value: 'MAX_REVENUE'
    }, {
      label: 'Maior quarto',
      value: 'BIGGEST_ROOM'
    }],
    inputType: 'select',
    'custom:excludeForCurrentClient': true
  }, {
    custom_group: 'conciliation',
    custom_groupName: "Conciliação",
    custom_hiddenFromCreateView: true,
    name: 'conciliation_revisionApprovalRequired',
    label: 'Exige Aprovação em Alteração',
    type: 'boolean',
    insertable: true,
    default: false,
    updatable: true,
    hint: 'Exige aprovação manual da reserva para habilitar cobrança se o hotel alterar valor ou período da reserva'
  }, {
    custom_group: 'conciliation',
    custom_groupName: "Conciliação",
    custom_hiddenFromCreateView: true,
    name: 'conciliation_invoiceRequired',
    label: 'Exige Nota Fiscal',
    type: 'boolean',
    insertable: true,
    default: false,
    updatable: true,
    hint: 'Exige envio de nota fiscal para habilitar cobrança'
  }, {
    custom_group: 'conciliation',
    custom_groupName: "Conciliação",
    custom_hiddenFromCreateView: true,
    name: 'conciliation_invoiceApproval',
    label: 'Aprovação Da Nota Fiscal',
    type: 'text',
    options: [{
      label: 'Manual',
      value: 'MANUAL',
      hint: 'Aprovação de nota será manual'
    }, {
      label: 'Semi Automática',
      value: 'AUTOREJECT',
      hint: 'Rejeição de nota será automática. Aprovação manual.'
    }, {
      label: 'Automática',
      value: 'AUTO',
      hint: 'Aprovação de nota automática, conforme configuração abaixo'
    }],
    insertable: true,
    default: 'MANUAL',
    updatable: true
  }, {
    custom_group: 'antiFraud',
    custom_groupName: "Antifraude",
    custom_hiddenFromCreateView: true,
    custom_checkTenant: t => t && t.modules_fraudDetection,
    name: 'antifraud_enabled',
    label: 'Ativo',
    type: 'boolean',
    insertable: true,
    default: false,
    updatable: true,
    hint: 'Liga e desliga o envio de anti-fraude'
  }, {
    custom_group: 'antiFraud',
    custom_groupName: "Antifraude",
    custom_hiddenFromCreateView: true,
    custom_checkTenant: t => t && t.modules_fraudDetection,
    name: 'antifraud_automaticBlockThreshold',
    label: 'Limiar para o bloqueio',
    type: 'float',
    inputType: 'percentage',
    insertable: true,
    default: 0.9,
    updatable: true,
    hint: 'Score do antifraude que vai fazer o bloqueio ou cancelamento da reserva automaticamente'
  }, {
    custom_group: 'antiFraud',
    custom_groupName: "Antifraude",
    custom_hiddenFromCreateView: true,
    custom_checkTenant: t => t && t.modules_fraudDetection,
    name: 'antifraud_automaticNotificationThreshold',
    label: 'Limiar para notificação',
    type: 'float',
    inputType: 'percentage',
    insertable: true,
    default: 0.5,
    updatable: true,
    hint: 'Score do antifraude que vai enviar email de notificação'
  }, {
    custom_group: 'antiFraud',
    custom_groupName: "Antifraude",
    custom_hiddenFromCreateView: true,
    custom_checkTenant: t => t && t.modules_fraudDetection,
    name: 'antifraud_notificationEmail',
    label: 'Email para notificação',
    type: 'text',
    inputType: 'email',
    insertable: true,
    updatable: true,
    hint: 'Email para que serão enviadas as notificações'
  }, {
    custom_group: 'conciliation',
    custom_groupName: "Conciliação",
    custom_hiddenFromCreateView: true,
    name: 'conciliation_invoiceAutomaticApproval',
    label: 'Restrição da Aprovação Automática',
    type: 'text',
    options: [{
      label: 'Nenhuma',
      value: 'NONE'
    }, {
      label: 'Restrita',
      value: 'RESTRICT',
      hint: 'Valida se é uma nota fiscal.'
    }, {
      label: 'Muito Restrita',
      value: 'VERY_RESTRICT',
      hint: 'Valida campos de cnpj e valor da nota.'
    }],
    insertable: true,
    default: 'NONE',
    updatable: true
  }, {
    custom_group: 'terms',
    custom_groupName: "Termos e Condições",
    name: 'terms_files',
    label: 'Termos, políticas, regulamentos (B2C)',
    type: 'objectarray',
    graphQLType: '[NestedTermsFile]',
    graphQLInputType: '[NestedTermsFileInput]',
    insertable: false,
    updatable: true,
    hint: 'Visíveis pelo viajante em cotação, voucher, link de pagamento, Motor Niara, OTA Builder. Sobrescreve os termos gerais.',
    'custom:shallowClient': true,
    // habilita para consulta sem autenticação (ex: OTABuilder)
    fields: [{
      name: 'name',
      label: 'Nome',
      hint: 'Termos, condições gerais, regulamento, política de privacidade, etc',
      required: true,
      type: 'text'
    }, {
      name: 'file',
      label: 'Arquivo (PDF)',
      required: false,
      type: 'text',
      inputType: 'inputPublicFile',
      fileType: 'pdf',
      custom_gerarPrefix: i => {
        return 'clients/' + i.id + '/terms/';
      }
    }, {
      name: 'url',
      label: 'Link',
      hint: '(ignorado se existir arquivo)',
      required: false,
      type: 'text',
      fileType: 'pdf',
      custom_gerarPrefix: i => {
        return 'clients/' + i.id + '/terms/';
      }
    }]
  }, {
    custom_group: 'terms',
    custom_groupName: "Termos e Condições",
    name: 'terms_files_b2b',
    label: 'Termos, políticas, regulamentos B2B',
    type: 'objectarray',
    graphQLType: '[NestedTermsFile]',
    graphQLInputType: '[NestedTermsFileInput]',
    insertable: false,
    updatable: true,
    hint: 'Visíveis para usuários do cliente B2B, mas nunca ao viajante. Sobrescreve os termos gerais.',
    'custom:shallowClient': false,
    // habilita para consulta sem autenticação (ex: OTABuilder)
    fields: [{
      name: 'name',
      label: 'Nome',
      hint: 'Termos, condições gerais, regulamento, política de privacidade, etc',
      required: true,
      type: 'text'
    }, {
      name: 'file',
      label: 'Arquivo (PDF)',
      required: false,
      type: 'text',
      inputType: 'inputPublicFile',
      fileType: 'pdf',
      custom_gerarPrefix: i => {
        return 'clients/' + i.id + '/terms/';
      }
    }, {
      name: 'url',
      label: 'Link',
      hint: '(ignorado se existir arquivo)',
      required: false,
      type: 'text',
      fileType: 'pdf',
      custom_gerarPrefix: i => {
        return 'clients/' + i.id + '/terms/';
      }
    }]
  }, {
    custom_group: 'terms',
    custom_groupName: "Termos e Condições",
    custom_hiddenFromCreateView: true,
    name: 'terms_text',
    label: 'Descrição dos Termos e Condições',
    type: 'text',
    inputType: 'wysiwyg',
    insertable: true,
    updatable: true,
    render: false,
    deprecated: true,
    hint: '(Não utilizar) O texto será exibido na tela de reserva.',
    'custom:shallowClient': true // habilita para consulta sem autenticação (ex: OTABuilder)
  }, {
    custom_group: 'terms',
    custom_groupName: "Termos e Condições",
    custom_hiddenFromCreateView: true,
    name: 'terms_file',
    label: 'Arquivo para Termos e Condições',
    inputType: 'inputPublicFile',
    fileType: 'pdf',
    custom_gerarPrefix: i => {
      return 'clients/' + i.id + '/terms/';
    },
    insertable: false,
    updatable: true,
    render: false,
    deprecated: true,
    hint: '(Não utilizar) Será exibido um link para acesso ao arquivo na tela de reserva.',
    'custom:shallowClient': true // habilita para consulta sem autenticação (ex: OTABuilder)
  }, {
    custom_group: 'terms',
    custom_groupName: "Termos e Condições",
    custom_hiddenFromCreateView: true,
    name: 'policy_file',
    render: false,
    deprecated: true,
    label: 'Arquivo para Políticas (obsoleto, não usar)',
    inputType: 'inputPublicFile',
    fileType: 'pdf',
    custom_gerarPrefix: i => {
      return 'clients/' + i.id + '/terms/';
    },
    insertable: false,
    updatable: true
  }, {
    custom_group: 'details',
    name: 'enabled',
    label: 'Ativo',
    default: true,
    type: 'boolean',
    'custom:shallowClient': true
  }, {
    custom_group: 'creditLimit',
    custom_groupName: "Limite de crédito",
    custom_hiddenFromCreateView: true,
    custom_checkTenant: t => t && t.modules_creditLimit,
    name: 'creditLimit_overrideLimits',
    label: 'Definir limites específicos',
    type: 'boolean',
    insertable: true,
    default: false,
    updatable: true,
    hint: 'Permite o cadastro de limites específicos deste cliente. Se desabilitado, usará os limites padrão na configuração de limite de crédito.',
    'custom:excludeForCurrentClient': true,
    'custom:shallowClient': false
  }, {
    custom_group: 'creditLimit',
    custom_groupName: "Limite de crédito",
    custom_hiddenFromCreateView: true,
    default: null,
    custom_checkTenant: t => t && t.modules_creditLimit,
    render: e => {
      if (e.creditLimit_overrideLimits !== true) return false;
      return <table>
            {e?.creditLimits?.map((item, idx) => {
          const paymentType = item?.paymentTypes?.[0] === 'FAT' ? 'Faturado' : item?.paymentTypes?.[0] === 'CREDITCARD' ? 'Cartão de Crédito' : item?.paymentTypes?.[0];
          return <tr key={`creditLimit-${idx}`}>
                  <td style={{
              padding: '2px 5px'
            }}>{paymentType}</td>
                  <td style={{
              padding: '2px 5px'
            }}>{printPrice(item?.creditLimit, item?.currency)}</td>
                </tr>;
        })}
          </table>;
    },
    inputType: 'InputCreditLimit',
    name: 'creditLimits',
    label: 'Limites',
    type: 'objectarray',
    fields: [{
      name: 'paymentTypes',
      label: 'Tipo de Pagamento',
      type: 'textarray',
      inputType: 'select',
      readOnly: true,
      options: [{
        value: 'CREDITCARD',
        label: 'Cartão de Crédito'
      }, {
        value: 'FAT',
        label: 'Faturado'
      }]
    }, {
      name: 'productTypes',
      label: 'Tipo de Produto',
      type: 'textarray',
      inputType: 'select',
      readOnly: true,
      options: [{
        value: 'hotelReservation',
        label: 'Reserva de Hotel'
      }, {
        value: 'flightReservation',
        label: 'Reserva de Voo'
      }]
    }, {
      name: 'currency',
      label: 'Moeda',
      inputType: 'select',
      options: currencies
    }, {
      name: 'creditLimit',
      label: 'Limite de Crédito',
      type: 'float'
    }],
    'custom:excludeForCurrentClient': true,
    'custom:shallowClient': false
  }, {
    name: 'isParentClient',
    label: 'É cliente pai em grupo de clientes',
    default: false,
    type: 'boolean',
    renderForm: false,
    insertable: false,
    updatable: true,
    'custom:shallowClient': true
  }, {
    name: 'parentClientId',
    label: 'Cliente pai no grupo de clientes',
    type: 'text',
    renderForm: false,
    insertable: false,
    updatable: false,
    'custom:shallowClient': true
  }, {
    name: 'childrenClientIds',
    label: 'Clientes do grupo',
    type: 'textarray',
    insertable: false,
    updatable: false,
    renderForm: false
  }],
  importColumnNames: ['corporate_name', 'brand_name', 'vat', 'website', 'address', 'address_number', 'address_apartment', 'city', 'state_acronym', 'country_code', 'zip_code', 'main_contact_name', 'main_contact_position', 'main_contact_cellphone', 'main_contact_phone', 'main_contact_email', 'booking_observation', 'restrict_nonrefundable', 'restrict_one_price', 'credential_exclusive', 'integration_externalCode'],
  custom_groupNames: {
    details: "Dados da empresa",
    main_contact: "Contato principal",
    booking: "Hospedagem",
    flight: "Aéreo",
    obt: "Habilitar Gestão",
    vcn: "VCN",
    customization: "Customização",
    distribution: "Distribuição",
    integration: "Integração",
    conciliation: "Conciliação",
    antiFraud: "Antifraude",
    terms: "Termos e Condições",
    gdsQueues: " Filas GDS",
    creditLimit: "Limite de crédito",
    sharePeople: "Compartilhar pessoas"
  },
  filter: [{
    name: 'brand_name_eq',
    field: 'brand_name',
    op: 'beginsWith',
    label: 'Nome'
  }, {
    name: 'enabled_eq',
    field: 'enabled',
    op: 'eq',
    label: 'Ativo'
  }, {
    name: 'query',
    graphQLType: 'String',
    type: 'text',
    label: 'Pesquisa'
  }, {
    field: 'brand_name',
    op: 'contains',
    label: 'Nome'
  }, {
    field: 'isParentClient',
    op: 'eq',
    label: 'É cliente pai?'
  }, {
    field: 'isParentClient',
    op: 'ne',
    label: 'Não é cliente pai?'
  }, {
    field: 'parentClientId',
    op: 'isNull',
    label: 'Cliente pai'
  }],
  searchParams: [{
    name: 'query',
    type: 'text'
  }, {
    name: 'autocomplete',
    type: 'text'
  }, {
    name: 'opensearch',
    type: 'boolean'
  }, {
    name: 'pageSize',
    type: 'int'
  }]
});