import fillCrudMetadataGaps from '../../fillCrudMetadataGaps';
import authorization from '../../util/authorization';
import { currencies } from '../currencyExchangeRule';
import _cloneDeep from 'lodash/cloneDeep';
import type { CreditControlRule } from '../../types';
const DEFAULT_CREDIT_LIMITS: CreditControlRule['creditLimits'] = [{
  enabled: false,
  paymentTypes: ['FAT'],
  productTypes: ['hotelReservation'],
  currency: 'BRL',
  creditLimit: 0
}, {
  enabled: false,
  paymentTypes: ['CREDITCARD'],
  productTypes: ['hotelReservation'],
  currency: 'BRL',
  creditLimit: 0
}];
export default fillCrudMetadataGaps({
  label: 'Controle de crédito',
  labelPlural: 'Controle de crédito',
  name: 'CreditControlRule',
  namePlural: 'CreditControlRules',
  custom_withAudit: true,
  authorization: authorization('consultant', 'admin'),
  deletable: false,
  dynamoDB: {
    tableName: 'SpearCreditControlRule'
  },
  columns: ['id', 'name', 'enabled'],
  fields: [{
    label: 'Id',
    name: 'id',
    type: 'text',
    updatable: false,
    insertable: false,
    id: true,
    renderColumn: false
  }, {
    name: 'name',
    label: 'Nome da Regra',
    required: true
  }, {
    name: 'enabled',
    label: 'Ativo',
    type: 'boolean'
  }, {
    name: 'insufficientErrorMessage',
    label: 'Mensagem de limite insuficiente',
    required: true
  }, {
    name: 'isExternalControl',
    label: 'Controle Externo de Controle de Limite',
    type: 'boolean',
    default: false,
    onEntityChange: (oldEntity, newEntity) => {
      if (oldEntity.isExternalControl != newEntity.isExternalControl) {
        if (!newEntity.isExternalControl) {
          newEntity['externalControlCredentialId'] = null;
          newEntity['configuration'] = _cloneDeep(DEFAULT_CREDIT_LIMITS);
        } else {
          newEntity['configuration'] = null;
        }
        return {
          ...newEntity
        };
      }
      return newEntity;
    }
  }, {
    name: 'externalControlCredentialId',
    label: 'Credencial',
    inputType: 'credential',
    credentialTypeList: ['COIN'],
    renderForm: i => i.isExternalControl,
    custom_auditUrl: e => ({
      url: `/credentials`,
      label: e?.loyaltyCredentialName
    })
  }, {
    name: 'creditLimits',
    label: 'Configuração',
    type: 'objectarray',
    inputType: 'InputCreditLimit',
    custom_renderFieldsRecursively: true,
    custom_auditConfig: {
      show: 'ALWAYS',
      groupName: 'Limite de crédito'
    },
    renderForm: i => !i.isExternalControl,
    default: DEFAULT_CREDIT_LIMITS,
    fields: [{
      name: 'enabled',
      label: 'Habilitado',
      type: 'boolean',
      custom_auditConfig: {
        show: 'ALWAYS'
      }
    }, {
      name: 'paymentTypes',
      label: 'Tipo de Pagamento',
      type: 'textarray',
      inputType: 'select',
      readOnly: true,
      options: [{
        value: 'CREDITCARD',
        label: 'Cartão de Crédito'
      }, {
        value: 'FAT',
        label: 'Faturado'
      }],
      custom_auditConfig: {
        show: 'ALWAYS'
      }
    }, {
      name: 'productTypes',
      label: 'Tipo de Produto',
      type: 'textarray',
      inputType: 'select',
      readOnly: true,
      options: [{
        value: 'hotelReservation',
        label: 'Reserva de Hotel'
      }, {
        value: 'flightReservation',
        label: 'Reserva de Voo'
      }],
      custom_auditConfig: {
        show: 'ALWAYS'
      }
    }, {
      name: 'currency',
      label: 'Moeda',
      inputType: 'select',
      options: currencies,
      custom_auditConfig: {
        show: 'ALWAYS'
      }
    }, {
      name: 'creditLimit',
      label: 'Limite de Crédito padrão',
      type: 'float',
      custom_auditConfig: {
        show: 'ALWAYS'
      }
    }]
  }, {
    deprecated: true /* remover após 2024-fev */,
    name: 'verificationType',
    label: '-',
    type: 'text',
    render: false
  }],
  filter: []
});