import fillCrudMetadataGaps from '../../fillCrudMetadataGaps';
import authorization from '../../util/authorization';
export default fillCrudMetadataGaps({
  label: 'Ação',
  labelPlural: 'Ações',
  name: 'ActionAudit',
  insertable: false,
  updatable: false,
  deletable: false,
  authorization: authorization('clientConsultant'),
  columnNames: ['id'],
  fields: [{
    label: 'Id',
    name: 'id',
    type: 'text',
    updatable: false,
    insertable: false,
    id: true,
    render: false,
    placeholder: 'order#BABABA@2023-12-19T19:38:33.201Z'
  }, {
    label: 'Referência',
    name: 'referenceTypeId',
    type: 'text',
    placeholder: 'order#BABABA'
  }, {
    label: 'Outras referências',
    name: 'otherReferencesTypeId',
    type: 'textarray',
    placeholder: '[hotelReservation#BABABA, hotelReservation#BABABE]'
  }, {
    label: 'Ação',
    name: 'action',
    type: 'text',
    options: [{
      label: 'Pedido Realizado',
      value: 'CHECKOUT'
    }, {
      label: 'Pagamento do Link de Pagamento',
      value: 'PAYMENTLINK_PAYMENT'
    }, {
      label: 'Troca status da reserva',
      value: 'CHANGE_RESERVATION_STATUS'
    }, {
      label: 'Reenvia conta/senha',
      value: 'RESEND_ACCOUNT'
    }, {
      label: 'Redução de comissão',
      value: 'REDUCE_COMMISSION'
    }, {
      label: 'Redução de markup',
      value: 'REDUCE_MARKUP'
    }, {
      label: 'Reset de conta/senha',
      value: 'RESET_PASSWORD'
    }, {
      label: 'Muda data de cancelamento automático',
      value: 'CHANGE_AUTOMATIC_CANCELLATION_DATE'
    }, {
      label: 'Ordem cancelada',
      value: 'CANCEL_ORDER'
    }, {
      label: 'Reserva cancelada',
      value: 'CANCEL_ORDER_HOTEL_RESERVATION'
    }, {
      label: 'Pagamento estornado',
      value: 'REFUND_PAYMENT'
    }, {
      label: 'Agendamento de Relatório',
      value: 'SCHEDULE_RESERVATION_REPORT'
    }, {
      label: 'Serviço adicionado',
      value: 'ADD_RESERVATION_TO_ORDER'
    }, {
      label: 'Link de pagamento criado',
      value: 'CREATE_PAYMENTLINK'
    }, {
      label: 'Envio de email',
      value: 'SEND_EMAIL'
    }, {
      label: 'Mudança de nome do passageiro',
      value: 'CHANGE_GUEST_NAME'
    }, {
      label: 'Cancela Fidelidade',
      value: 'CANCEL_LOYALTY'
    }, {
      label: 'Mudança de preço',
      value: 'CHANGE_PRICE'
    }, {
      label: 'Mudança de datas e ocupação',
      value: 'CHANGE_RESERVATION'
    }, {
      label: 'Cancelamento de reserva',
      value: 'CANCEL_RESERVATION'
    }, {
      label: 'Pagamento manual',
      value: 'CREATE_MANUAL_PAYMENT'
    }, {
      label: 'Baixa de pagamento',
      value: 'CONFIRM_OUTSTANDING_PAYMENT'
    }, {
      label: 'Atualização pelo fornecedor',
      value: 'IMPORT_RESERVATION'
    }, {
      label: 'Cancelamento automático',
      value: 'AUTOMATIC_CANCELLATION'
    }, {
      label: '(Admin) Adicionar dispositivo MFA',
      value: 'ADMIN_ADD_MFA_DEVICE'
    }, {
      label: 'Adicionar dispositivo MFA',
      value: 'ADD_MFA_DEVICE'
    }, {
      label: '(Admin) Associar dispositivo MFA',
      value: 'ADMIN_ASSOCIATE_MFA_DEVICE'
    }, {
      label: 'Associar dispositivo MFA',
      value: 'ASSOCIATE_MFA_DEVICE'
    }, {
      label: 'Visualização de dados confidenciais',
      value: 'DESOBFUSCATE'
    }, {
      label: 'Download do relatório',
      value: 'DOWNLOAD_REPORT'
    }, {
      label: 'Atualização de dados',
      value: 'UPDATE'
    }]
  }, {
    label: 'Data/Hora',
    name: 'datetime',
    type: 'datetime'
  }, {
    label: 'Data/Hora fim (opcional)',
    name: 'datetimeEnd',
    type: 'datetime'
  }, {
    label: 'Usuário',
    name: 'username',
    type: 'text'
  }, {
    label: 'Nome do usuário',
    name: 'fullname',
    type: 'text'
  }, {
    label: 'Status',
    name: 'result',
    type: 'text',
    options: [{
      label: 'Sucesso',
      value: 'SUCCESS'
    }, {
      label: 'Falha',
      value: 'FAILED'
    }, {
      label: 'Iniciado',
      value: 'STARTED'
    }]
  }, {
    label: 'Mais informações',
    name: 'moreInformation',
    type: 'object'
  }],
  filter: [{
    name: 'id_beginsWith',
    field: 'id',
    op: 'beginsWith',
    label: 'ID'
  }, {
    name: 'id_eq',
    field: 'id',
    op: 'eq',
    label: 'ID'
  }, {
    field: 'datetime',
    op: 'gt'
  }]
});