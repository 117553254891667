import { HotelResult } from '@niarab2c/frontend-commons/src/types/hotels';
import callApi from '@niarab2c/frontend-commons/src/util/callApi';
import { HotelPostSearchRule, HotelSearchRule } from '@niarab2c/niara-spear-crudmodel';
import { createAsyncThunk, createSlice, PayloadAction, unwrapResult } from '@reduxjs/toolkit';
import _isEqual from 'lodash/isEqual';
import { nanoid } from 'nanoid';
import queryString from 'query-string';
import type { AnyAction } from 'redux';
import _debounce from 'lodash/debounce';
import type { BaseRootState, CompleteRootState, Listener } from '../../base';
import type { HotelDetailsState } from './hotelDetails';
import hotelDetails from './hotelDetails';
import type { HotelPaginationState } from './hotelPagination';
import * as HotelPagination from './hotelPagination';
import type { CriteriaForm, State as HotelSearchState } from './hotelSearch';
import hotelSearch, { searchHotels as _searchHotels } from './hotelSearch';
export * as HotelDetails from './hotelDetails';
export * as HotelPagination from './hotelPagination';
export * as HotelSearch from './hotelSearch';
export type HotelState = {
  hotelSearch: HotelSearchState;
  hotelPagination: HotelPaginationState;
  hotelDetails: HotelDetailsState;
  hotelSearchRule?: Partial<HotelSearchRule>;
  hotelPostSearchRule?: Partial<HotelPostSearchRule>;
  searchId?: string;
};
export const loadHotelSearchRule = createAsyncThunk<{
  hotelSearchRule?: HotelSearchRule;
  hotelPostSearchRule?: HotelPostSearchRule;
}, {
  clientId?: string;
  propertyId?: string;
} | void, {
  state: CompleteRootState;
}>('hotel/loadHotelSearchRule', async (options, {
  getState
}) => {
  const rootState = getState();
  const clientId = (options && options?.clientId) ?? rootState.core?.clientId;
  const propertyId = options ? options?.propertyId : undefined;
  const landingPageId = rootState.storefrontConfig?.storefront?.landingPageId;
  const results = await Promise.all([callApi('niara-spear-commons', `/genericRuleResolver/hotelSearchRule/details`, 'get', {
    params: {
      clientId,
      landingPageId,
      propertyId
    } // TODO complementar o contexto com mais dados (hotel, data, etc)
  }), callApi('niara-spear-commons', `/genericRuleResolver/hotelPostSearchRule/details`, 'get', {
    params: {
      clientId,
      landingPageId,
      propertyId
    } // TODO complementar o contexto com mais dados (hotel, data, etc)
  })]);
  return {
    hotelSearchRule: results[0]?.hotelSearchRule,
    hotelPostSearchRule: results[1]?.hotelPostSearchRule
  };
});
const initialState: HotelState = {
  hotelSearch: hotelSearch(undefined, {
    type: 'IGNORE'
  }),
  hotelPagination: HotelPagination.default(undefined, {
    type: 'IGNORE'
  }),
  hotelDetails: hotelDetails(undefined, {
    type: 'IGNORE'
  })
};
const hotelSlice = createSlice({
  name: 'hotel',
  initialState,
  reducers: {
    _setHotelSearch: (state, action: PayloadAction<{
      hotelSearch: HotelSearchState;
    }>) => {
      const hotelSearch = action.payload.hotelSearch;
      state.hotelSearch = hotelSearch;
      state.searchId = hotelSearch.searchId;
      let hotelPagination = HotelPagination.reducer(undefined, HotelPagination.setResults({
        results: hotelSearch.results,
        hotelSearch
      }));
      if (hotelSearch?.criteria?.promoCode?.length > 0) {
        // se pesquisou com promoCode, seta automaticamente o filtro do promoCode, para mostrar apenas
        // os resultados com o promoCode solicitado
        hotelPagination = HotelPagination.reducer(hotelPagination, HotelPagination.setFilter({
          filter: {
            promoCode: hotelSearch?.criteria?.promoCode
          }
        }));
      }
      state.hotelPagination = hotelPagination;
    },
    clearSearch: state => {
      state.hotelSearch = {};
      state.hotelPagination = HotelPagination.initialState;
    }
  },
  extraReducers: builder => {
    builder.addCase(loadHotelSearchRule.fulfilled, (state, action) => {
      if (action.payload) {
        return {
          ...state,
          hotelSearchRule: action.payload?.hotelSearchRule,
          hotelPostSearchRule: action.payload?.hotelPostSearchRule
        };
      } else {
        return state;
      }
    });
    builder.addMatcher((action: AnyAction) => action.type.startsWith('hotelSearch'), (state, action) => {
      if (state.searchId === action.payload?.searchId || state.searchId === action.meta?.arg?.searchId) {
        // const previousResults = state.hotelSearch?.results
        state.hotelSearch = hotelSearch(state.hotelSearch, action);

        // substituído pelo listener
        // if (previousResults !== state.hotelSearch?.results) {
        //   state.hotelPagination = HotelPagination.reducer(
        //     state.hotelPagination,
        //     HotelPagination.setResults({ results: state.hotelSearch.results })
        //   )
        // }
      }
      // hotel details encapsula um hotelSearch
      state.hotelDetails = hotelDetails(state.hotelDetails, action);
    });
    builder.addMatcher((action: AnyAction) => action.type.startsWith('hotelPagination'), (state, action) => {
      state.hotelPagination = HotelPagination.reducer(state.hotelPagination, action);
    });
    builder.addMatcher((action: AnyAction) => action.type.startsWith('hotelDetails'), (state, action) => {
      state.hotelDetails = hotelDetails(state.hotelDetails, action);
    });
  }
});
const {
  _setHotelSearch
} = hotelSlice.actions;
export const {
  clearSearch
} = hotelSlice.actions;
export default hotelSlice.reducer;
export const searchHotels = createAsyncThunk<{
  results: HotelResult[];
  searchId: string;
  errorMessage?: string;
}, {
  criteriaForm: CriteriaForm;
  engineRuleVersion: string;
}, {
  state: BaseRootState;
  rejectValue: {
    searchId: string;
    errorMessage: string;
  };
}>('hotel/searchHotels', async ({
  criteriaForm,
  engineRuleVersion
}, {
  getState,
  dispatch,
  rejectWithValue
}) => {
  // compara o critério - se não mudou, não faz nova busca
  const currentCriteria = getState().hotel?.hotelSearch?.criteria;
  if (currentCriteria && _isEqual(currentCriteria, criteriaForm)) {
    // keep previous search
  } else if (criteriaForm) {
    dispatch(clearSearch());
    const searchId = nanoid();
    dispatch(_setHotelSearch({
      hotelSearch: {
        searchId
      }
    }));
    //carrega as regras de pesquisa
    await dispatch(loadHotelSearchRule({
      clientId: criteriaForm.clientId,
      propertyId: criteriaForm.destinations?.propertyId
    }));
    try {
      return await dispatch(_searchHotels({
        criteriaForm,
        searchId,
        roomRatesLimit: 1,
        engineRuleVersion
      })).then(unwrapResult);
    } catch (error) {
      return rejectWithValue({
        searchId,
        errorMessage: error.errorMessage
      });
    }
  }
});
export const createListener = (): Listener<BaseRootState> => {
  const debouncedDispatchSetResults = _debounce((results, dispatch, currentState) => {
    dispatch(HotelPagination.setResults({
      results,
      hotelSearch: currentState?.hotel?.hotelSearch
    }));
  }, 400, {
    maxWait: 1200,
    leading: true
  });
  return (previousState, currentState, dispatch) => {
    if (previousState?.hotel?.hotelSearch?.results != currentState?.hotel?.hotelSearch?.results) {
      // atualiza hotelPagination de acordo com atualização com results, mas usa o debounce para
      // diminuir as atualizações
      debouncedDispatchSetResults(currentState?.hotel?.hotelSearch?.results, dispatch, currentState);
    }
  };
};