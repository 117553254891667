import callApi from '@niarab2c/frontend-commons/src/util/callApi';
import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import queryString from 'query-string';
import type { CompleteRootState } from '../base';
export interface LoyaltyState {
  userBalance?: number;
  unitText?: string;
  userBalanceProgramType?: string;
  userBalanceContractStatus?: 'ACTIVE' | 'INACTIVE';
  tier?: string;
  showTierOnNavbar?: boolean;
}
export const refreshBalance = createAsyncThunk<{
  userBalance: number | null;
  unitText: string | null;
  userBalanceContractStatus: 'ACTIVE' | 'INACTIVE';
}, void, {
  state: CompleteRootState;
}>('loyalty/refreshBalance', async (ignore, {
  getState
}) => {
  const rootState = getState();
  const b2c = rootState?.authentication?.b2c;

  // Só chama o balance se for b2c
  if (!b2c) return null;
  const clientId = rootState.core.clientId;
  const tenantId = rootState.core.tenantId;
  const landingPageId = rootState.storefrontConfig?.storefront?.landingPageId;
  const result = await callApi('niara-spear-loyalty-programs', `/loyalty-programs/balance_v2?${queryString.stringify({
    clientId,
    tenantId,
    landingPageId
  })}`);
  return {
    userBalance: result?.[0]?.balance ?? result?.balance?.balance ?? null,
    unitText: result?.balance?.unitText ?? "pontos",
    userBalanceProgramType: result?.[0]?.balance ?? result?.balance?.programType ?? null,
    userBalanceContractStatus: result?.balance?.contractStatus,
    tier: result?.balance?.tier,
    showTierOnNavbar: result?.balance?.showTierOnNavbar
  }; //v1: result?.[0]?.balance  v2: result?.balance
});
const loyaltySlice = createSlice({
  name: 'loyalty',
  initialState: ({} as LoyaltyState),
  reducers: {},
  extraReducers: builder => {
    builder.addCase(refreshBalance.fulfilled, (state, action) => {
      if (action.payload) {
        return {
          ...state,
          ...action.payload
        };
      } else {
        return state;
      }
    });
  }
});
export default loyaltySlice.reducer;