import callApi from '@niarab2c/frontend-commons/src/util/callApi';
import { useMemo } from 'react';
const iataDestinationSearch = () => async (query: any): Promise<any> => {
  if (!query) return;
  let response: {
    suggest?: any;
  } = {};
  response = await callApi('niara-spear-flight-dest', '/_search', 'post', {
    body: {
      suggest: {
        text: query,
        aiport_suggestion: {
          completion: {
            field: 'suggest'
          }
        },
        city_suggestion: {
          completion: {
            field: 'cities',
            size: 3
          }
        }
      }
    }
  });
  const {
    aiport_suggestion,
    city_suggestion
  } = response.suggest;
  const result = [...aiport_suggestion[0].options.map((airport: any) => ({
    ...airport,
    type: 'city'
  })), ...city_suggestion[0].options.map((city: any) => ({
    ...city,
    type: 'airport'
  }))].map(op => ({
    label: op.text,
    id: op._id,
    address: [op._source.city, op._source.state, op._source.country].filter(item => !!item).join(' - '),
    city: op._source.city,
    state: op._source.state,
    country: op._source.country,
    countryCode: op._source.countryCode,
    name: op.type === 'airport' ? `${op._source.iata} - ${op._source.name}` : op._source.name,
    type: op.type,
    coordinates: {
      lon: op._source.longitude,
      lat: op._source.latitude
    }
  }));
  return result;
};
const useIataSearch = () => {
  return useMemo(() => iataDestinationSearch(), []);
};
export default useIataSearch;