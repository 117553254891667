import fillCrudMetadataGaps, { PartialField } from '../../fillCrudMetadataGaps';
import authorization from '../../util/authorization';
import order from '../order';
import { statusOptions as paymentStatusOptions } from '../payment';
import selfHotelReservation from '../selfHotelReservation';
export default fillCrudMetadataGaps({
  label: 'Viagem',
  labelPlural: 'Viagens',
  name: 'SelfOrder',
  deletable: false,
  updatable: false,
  insertable: false,
  searchable: false,
  authorization: authorization('unauthenticated', false),
  dynamoDB: {
    tableName: 'SpearOrder'
  },
  'custom:sortFields': ['date desc'],
  columns: order.columns,
  fields: [...(order.fields as PartialField[]).filter(f => !f['custom:excludeForSelfOrder'] && f.name !== 'items' && f.name !== 'hotelReservations'), {
    /** usar isto para substituir items */
    name: 'hotelReservations',
    label: 'Reservas de hotel',
    type: 'objectarray',
    fields: selfHotelReservation.fields.filter(f => !f['custom:excludeForSelfOrder'])
  }, {
    name: 'loyalties',
    graphQLType: '[NestedSelfOrderLoyalty]',
    type: 'objectarray',
    fields: [{
      label: 'Id',
      name: 'id'
    }, {
      label: 'Id do Item',
      name: 'itemId'
    }, {
      label: 'Tipo do Item',
      name: 'itemType'
    }, {
      label: 'Pontos',
      name: 'points',
      type: 'float'
    }, {
      label: 'Data Referencia',
      name: 'date',
      type: 'datetime'
    }, {
      label: 'Agendamento',
      name: 'scheduleDate',
      type: 'datetime'
    }, {
      label: 'Código Acumulo',
      name: 'ncl_integration_locator'
    }, {
      label: 'status',
      name: 'status',
      options: [{
        value: 'PENDING',
        label: 'Pendente'
      }, {
        value: 'FAILED',
        label: 'Erro'
      }, {
        value: 'REJECTED',
        label: 'Rejeitado'
      }, {
        value: 'CANCELLED',
        label: 'Cancelado'
      }, {
        value: 'REFUNDED',
        label: 'Estornado'
      }, {
        value: 'AUTHORIZED',
        label: 'Autorizado'
      }, {
        value: 'CONFIRMED',
        label: 'Confirmado'
      }, {
        value: 'SCHEDULED',
        label: 'Agendado'
      }],
      renderStyle(i, fieldName = 'status') {
        if (i[fieldName]) {
          switch (i[fieldName]) {
            case 'REJECTED':
            case 'FAILED':
            case 'CANCELLED':
              return 'danger';
            case 'PENDING':
              return 'warning';
            case 'SCEHDULED':
              return 'primary';
            case 'CONFIRMED':
              return 'success';
          }
        }
        return 'danger';
      }
    }, {
      label: 'Tipo',
      name: 'type',
      options: [{
        value: 'POINTS',
        label: 'Pontos'
      }, {
        value: 'CASHBACK',
        label: 'Cashback'
      }]
    }, {
      label: 'Unidade',
      name: 'unit',
      type: 'text'
    }, {
      label: 'Moeda',
      name: 'currency',
      type: 'text'
    }, {
      label: 'Valor',
      name: 'value',
      type: 'float'
    }, {
      label: 'Descrição',
      name: 'description',
      type: 'text'
    }, {
      label: 'Unidade',
      name: 'loyaltyRedeem_unitText',
      type: 'text'
    }]
  }, {
    name: 'payments',
    graphQLType: '[NestedOrderPayment]',
    updatable: false,
    insertable: false,
    type: 'objectarray',
    fields: [{
      name: 'id'
    }, {
      name: 'loyaltyId'
    }, {
      name: 'createDate',
      label: 'Data da transação',
      type: 'datetime'
    }, {
      name: 'captureDate',
      label: 'Data da captura',
      type: 'datetime'
    }, {
      name: 'cancellationDate',
      label: 'Data Estorno',
      type: 'datetime'
    }, {
      name: 'status',
      options: paymentStatusOptions
    }, {
      name: 'message'
    }, {
      name: 'paidItems',
      type: 'objectarray',
      graphQLType: '[EmbeddedPaymentPaidItem]',
      fields: [{
        name: 'id'
      }, {
        name: 'type'
      }, {
        name: 'pendingAmountCancelled',
        type: 'float'
      }, {
        name: 'amountCancelled',
        type: 'float'
      }, {
        name: 'amount',
        type: 'float'
      }]
    }, {
      name: 'creditCard',
      type: 'object',
      graphQLType: 'EmbeddedPaymentCreditCard',
      fields: [{
        name: 'brand',
        label: 'Bandeira do cartão'
      }, {
        name: 'number',
        label: 'Número do cartão'
      }, {
        name: 'holder',
        label: 'Nome no cartão'
      }
      // {
      //   name: 'expiryDate',
      //   label: 'Data de expiração (MMAA)',
      //   deprecated: true,
      //   render: false,
      // },
      ]
    }, {
      name: 'amount',
      type: 'float'
    }, {
      name: 'pendingAmountCancelled',
      type: 'float'
    }, {
      name: 'amountCancelled',
      type: 'float'
    }, {
      name: 'currency'
    }, {
      name: 'type'
    }, {
      name: 'installments',
      type: 'int'
    }, {
      name: 'pixQrCodeContent',
      label: 'Conteúdo do QR CODE'
    }, {
      name: 'pixQrCode',
      label: 'QR CODE do PIX'
    }, {
      name: 'pixDueDate',
      label: 'Validade do pix'
    }, {
      name: 'authentication3DSecChallenge',
      label: 'Desafio 3DS',
      type: 'object' // Objeto opcaco retornado pela adyen, por esse motivo seus campos não foram especificados
    }, {
      name: 'credentialId',
      label: 'Credencial'
    }, {
      name: 'credential',
      type: 'object',
      updatable: false,
      graphQLType: 'SelfOrder_Payment_Credential',
      fields: [{
        name: 'id',
        label: 'Id'
      }, {
        name: 'type'
      }, {
        name: 'publicKey',
        label: 'Chave pública'
      }, {
        name: 'clientKey',
        label: 'Client Key'
      }, {
        name: 'environment',
        label: 'Ambiente'
      }]
    }, {
      name: 'source'
    }, {
      name: 'pendingReason',
      options: [{
        value: 'CAPTURE_CONFIRMATION',
        label: 'Aguardando confirmação do pagamento'
      }, {
        value: 'PIX_PAYMENT',
        label: 'Aguardando o pagamento do PIX'
      }, {
        value: 'FRAUD_ANALYSIS',
        label: 'Pagamento em análise'
      }, {
        value: '3DS_AUTHENTICATION',
        label: 'Autenticação 3DS solicitada'
      }]
    }, {
      name: 'paymentCondition_description',
      label: 'Instruções da forma de pagamento'
    }, {
      name: 'manual',
      type: 'boolean'
    }, {
      name: 'purchaseSessionId'
    }, {
      name: 'previousPaymentId'
    }]
  }],
  filter: order.filter,
  searchParams: [{
    name: 'showValues',
    type: 'boolean'
  }],
  getParams: [{
    name: 'email',
    type: 'text'
  }, {
    name: 'showValues',
    type: 'boolean'
  }]
});