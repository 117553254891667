import fillCrudMetadataGaps from '../../fillCrudMetadataGaps';
import authorization from '../../util/authorization';
import hotelReservation from '../hotelReservation';
export default fillCrudMetadataGaps({
  label: 'Viagem',
  labelPlural: 'Viagens',
  name: 'Order',
  faIcon: 'file-invoice',
  insertable: false,
  updatable: true,
  deletable: false,
  custom_withAudit: true,
  custom_withObfuscate: true,
  authorization: authorization('clientConsultant'),
  'custom:sortFields': ['date desc'],
  dynamoDB: {
    indexedProp: 'date',
    indexName: 'tenant-date-index',
    scanIndexBackwards: true,
    indexes: [{
      indexedProp: 'quotationId',
      indexName: 'tenant-quotationId-index',
      partitionKey: 'tenantId'
    }]
  },
  columnNames: ['id', 'description', 'clientName', 'createdByName', 'status', 'startDate', 'endDate', 'date', 'isForGroups', 'confirmedStatusItems', 'cancelledStatusItems', 'otherStatusItems'],
  fields: [{
    label: 'Id',
    name: 'id',
    type: 'text',
    updatable: false,
    insertable: false,
    id: true,
    renderColumn: false
  }, {
    name: 'clientId',
    label: 'Id Cliente',
    updatable: false
  }, {
    name: 'clientName',
    label: 'Nome do cliente',
    updatable: false
  }, {
    name: 'travellerName',
    label: 'Nome do viajante',
    updatable: false,
    deprecated: true // OBT
  }, {
    name: 'travellerFirstName',
    label: 'Primeiro nome',
    updatable: false,
    render: false,
    deprecated: true // OBT
  }, {
    name: 'travellerLastName',
    label: 'Sobrenome',
    updatable: false,
    render: false,
    deprecated: true // OBT
  }, {
    name: 'travellerId',
    label: 'Id do viajante',
    updatable: false,
    deprecated: true // OBT
  }, {
    name: 'date',
    label: 'Data da reserva',
    type: 'datetime',
    updatable: false
  }, {
    label: 'Data de início',
    name: 'startDate',
    type: 'date',
    updatable: false
  }, {
    label: 'Data de fim',
    name: 'endDate',
    type: 'date',
    updatable: false
  }, {
    name: 'createdBy',
    label: 'Criado por',
    type: 'text',
    updatable: false,
    'custom:excludeForSelfOrder': true
  }, {
    name: 'createdByName',
    label: 'Criado por',
    type: 'text',
    updatable: false,
    'custom:excludeForSelfOrder': true
  }, {
    name: 'cancelled',
    label: 'Cancelado',
    type: 'boolean',
    updatable: false
  }, {
    name: 'cancellationDate',
    label: 'Cancelado em',
    type: 'datetime',
    updatable: false
  }, {
    name: 'status',
    label: 'Status',
    options: [{
      accentColor: '#0C83D9',
      label: "Criada",
      value: 'PRE_BOOKING' /** v1 */
    }, {
      accentColor: '#0C83D9',
      label: "Criada",
      value: 'CREATED' /** v2 - manter? */
    }, {
      accentColor: '#FDBA01',
      label: "Em andamento",
      value: 'PENDING' /** v1 - converter para algum INPROGRESS_X */
    }, {
      accentColor: '#FF0000',
      label: "Rejeitado",
      value: 'REJECTED' /** v2 */
    }, {
      accentColor: '#FF0000',
      label: "Falha",
      value: 'FAILED' /** usado? matar? */
    }, {
      accentColor: '#FF0000',
      label: "Cancelado",
      value: 'CANCELLED' /** v2 */
    }, {
      accentColor: '#53AC2A',
      label: "Confirmado",
      value: 'CONFIRMED'
    }, {
      accentColor: '#FDBA01',
      label: "Em andamento",
      value: 'BOOKING_1' /** v1 - converter para INPROGRESS_BOOKING */
    }, {
      accentColor: '#FDBA01',
      label: "Em andamento",
      value: 'BOOKING_2' /** v1 - converter para INPROGRESS_BOOKING */
    }, {
      accentColor: '#FDBA01',
      label: "Em andamento",
      value: 'PAYMENT' /** v1 - converter para INPROGRESS_PAYMENT */
    }, {
      accentColor: '#FDBA01',
      label: "Em andamento",
      value: 'LOYALTY' /** v1 - converter para INPROGRESS_LOYALTY? */
    }, {
      accentColor: '#FDBA01',
      label: "Em andamento",
      value: 'EMAILS'
    } /** v1 - excluir? */, {
      accentColor: '#FDBA01',
      label: "Em andamento",
      value: 'INPROGRESS' /** ??? */
    }, {
      accentColor: '#FDBA01',
      label: "Em andamento",
      value: 'INPROGRESS_PAYMENT' /** v2 */
    }, {
      accentColor: '#FDBA01',
      label: "Em andamento",
      value: 'INPROGRESS_BOOKING' /** v2 */
    }, {
      accentColor: '#FDBA01',
      label: "Em andamento",
      value: 'INPROGRESS_NEXT' /** v2 */
    }, {
      accentColor: '#FDBA01',
      label: "Pagamento pendente",
      value: 'PENDING_PAYMENT' /** v2 */
    }, {
      accentColor: '#FF0000',
      label: "Não confirmado",
      value: 'UNCONFIRMED' /** v2 */
    }],
    renderStyle(i, fieldName = 'status') {
      if (i[fieldName]) {
        switch (i[fieldName]) {
          case 'CANCELLED_TIMEOUT':
          case 'CANCELLED':
          case 'REJECTED':
            return 'danger';
          case 'PENDING':
            return 'warning';
          case 'CONFIRMED':
            return 'success';
        }
      }
      if (i.cancelled) return 'danger';
      return 'warning';
    },
    updatable: false
  }, {
    name: 'tags',
    label: 'Marcadores',
    type: 'textarray',
    updatable: false,
    'custom:excludeForSelfOrder': true
  }, {
    /** usar isto para substituir items */
    name: 'hotelReservations',
    label: 'Reservas de hotel',
    type: 'objectarray',
    updatable: false,
    insertable: false,
    fields: hotelReservation.fields,
    custom_excludeFromAuditValue: true
  }, {
    name: 'isForGroups',
    label: 'Reserva para grupos',
    type: 'boolean',
    updatable: false,
    renderColumn: false
  }, {
    name: 'description',
    label: 'Descrição',
    type: 'text',
    renderColumn: function (i) {
      if (!i.description) {
        if (i.isForGroups) {
          return `Reserva para grupos ${i.id}`;
        } else {
          return `Viagem ${i.id}`;
        }
      }
      return i.description;
    }
  }, {
    name: 'destinationName',
    label: 'Destino'
  }, {
    name: 'destinationPosition',
    label: 'Destino (posição)',
    type: 'floatarray',
    'custom:excludeForSelfOrder': true
  }, {
    name: 'errorMessage',
    label: 'Mensagem de erro',
    type: 'text'
  }, {
    custom_groupName: "Comprador",
    name: 'buyer_name',
    label: 'Nome do comprador',
    updatable: false
  }, {
    custom_groupName: "Comprador",
    name: 'buyer_email',
    label: 'E-mail do comprador',
    updatable: false,
    custom_obfuscateValue: true
  }, {
    custom_groupName: "Comprador",
    name: 'buyer_cpf',
    label: 'CPF do comprador',
    updatable: false,
    custom_obfuscateValue: true
  }, {
    custom_groupName: "Comprador",
    name: 'buyer_passport',
    label: 'Passaporte do comprador',
    updatable: false,
    custom_obfuscateValue: true
  }, {
    custom_groupName: "Comprador",
    name: 'buyer_dob',
    label: 'Data de nascimento do comprador',
    updatable: false
  }, {
    custom_groupName: "Comprador",
    name: 'buyer_phone',
    label: 'Número de telefone do comprador',
    updatable: false,
    custom_obfuscateValue: true
  }, {
    custom_groupName: "Comprador",
    name: 'buyer_username',
    label: 'Login do comprador',
    updatable: false,
    custom_obfuscateValue: true
  }, {
    custom_groupName: "Comprador",
    name: 'buyer_nationality',
    label: 'Nacionalidade',
    updatable: false,
    custom_obfuscateValue: true
  }, {
    custom_groupName: "Endereço do comprador",
    name: 'buyer_country',
    label: 'País',
    updatable: false,
    custom_obfuscateValue: true
  }, {
    custom_groupName: "Endereço do comprador",
    name: 'buyer_addressCity',
    label: 'Cidade',
    updatable: false,
    custom_obfuscateValue: true
  }, {
    custom_groupName: "Endereço do comprador",
    name: 'buyer_addressState',
    label: 'Estado',
    updatable: false,
    custom_obfuscateValue: true
  }, {
    custom_groupName: "Endereço do comprador",
    name: 'buyer_addressDistrict',
    label: 'Bairro',
    updatable: false,
    custom_obfuscateValue: true
  }, {
    custom_groupName: "Endereço do comprador",
    name: 'buyer_addressNumber',
    label: 'Número',
    updatable: false,
    custom_obfuscateValue: true
  }, {
    custom_groupName: "Endereço do comprador",
    name: 'buyer_addressZipCode',
    label: 'CEP',
    updatable: false,
    custom_obfuscateValue: true
  }, {
    custom_groupName: "Endereço do comprador",
    name: 'buyer_addressStreet',
    label: 'Rua',
    updatable: false,
    custom_obfuscateValue: true
  }, {
    custom_groupName: "Endereço do comprador",
    name: 'buyer_addressComplement',
    label: 'Complemento',
    updatable: false,
    custom_obfuscateValue: true
  }, {
    name: 'confirmedStatusItems',
    label: 'Itens confirmados',
    type: 'int',
    updatable: false
  }, {
    name: 'cancelledStatusItems',
    label: 'Itens cancelados',
    type: 'int',
    updatable: false
  }, {
    name: 'otherStatusItems',
    label: 'Itens em outros status',
    type: 'int',
    updatable: false
  }, {
    name: 'quotationId',
    type: 'text'
  }, {
    name: 'quotationToken',
    type: 'text'
  }, {
    name: 'lostReservationId',
    type: 'text'
  }, {
    name: 'landingPageId',
    type: 'text'
  }, {
    name: 'landingPageTitle',
    type: 'text'
  }, {
    name: 'storefrontId',
    type: 'text'
  }, {
    name: 'storefrontTitle',
    type: 'text'
  }, {
    name: 'deleteMeCheckoutStrategy',
    type: 'text',
    options: [{
      label: 'Somente reserva',
      value: 'BOOKING_ONLY'
    }, {
      label: 'Reserva após o pagamento',
      value: 'PAYMENT_THEN_BOOKING'
    }, {
      label: 'Pagamento após reserva',
      value: 'BOOKING_THEN_PAYMENT'
    }]
  }, {
    name: 'serviceAccountId',
    type: 'text'
  }],
  filter: [{
    name: 'clientId_eq',
    field: 'clientId',
    op: 'eq',
    label: 'Client Id'
  }, {
    name: 'travellerId_eq',
    field: 'travellerId',
    op: 'eq',
    label: 'Traveller Id'
  }, {
    name: 'date_ge',
    field: 'date',
    op: 'ge',
    label: 'Data de Reserva Início'
  }, {
    name: 'date_le',
    field: 'date',
    op: 'le',
    label: 'Data de Reserva Fim'
  }, {
    name: 'startDate_ge',
    field: 'startDate',
    op: 'ge',
    label: 'Início da viagem maior que'
  }, {
    name: 'startDate_le',
    field: 'startDate',
    op: 'le',
    label: 'Início da viagem menor que'
  }, {
    name: 'endDate_ge',
    field: 'endDate',
    op: 'ge',
    label: 'Fim da viagem maior que'
  }, {
    name: 'cancelled_ne',
    field: 'cancelled',
    op: 'ne',
    label: 'Cancelado diferente de'
  }, {
    name: 'isForGroups_eq',
    field: 'isForGroups',
    op: 'eq'
  }, {
    name: 'isForGroups_ne',
    field: 'isForGroups',
    op: 'ne'
  }, {
    field: 'quotationId',
    op: 'eq'
  }, {
    field: 'id',
    op: 'contains'
  }]
});