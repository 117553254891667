import React from 'react';
import { SupplierPaymentRule } from '..';
import fillCrudMetadataGaps from '../../fillCrudMetadataGaps';
import authorization from '../../util/authorization';
import { Field, PartialField } from '@niaratech/devcase-crud';
const omniPaymentOptions = [{
  label: 'Cartão Offline',
  value: 'OFF_CC',
  hint: 'Cliente pagará fornecedor com VCN/Cartão'
}, {
  label: 'Direto no local',
  value: 'DIR',
  hint: 'Cliente pagará fornecedor no local do serviço (hotel/aeroporto/etc) e fornecerá cartão de crédito como garantia'
}, {
  label: 'Direto no local (IATA)',
  value: 'DIR_IATA',
  hint: 'Cliente pagará fornecedor no local do serviço (hotel/aeroporto/etc) e será usada a garantia IATA da agência'
}, {
  label: 'Faturado Offline',
  value: 'OFF_FAT',
  hint: 'Cliente pagará fornecedor via fatura'
}, {
  label: 'Antecipado Offline',
  value: 'OFF_ANT',
  hint: 'Cliente pagará fornecedor antecipadamente'
}];
const hotelbedsPaymentOptions = [{
  label: 'Direto no local',
  value: 'DIR',
  hint: 'Cliente pagará fornecedor no local do serviço (hotel/aeroporto/etc) e fornecerá cartão de crédito como garantia'
}, {
  label: 'Cartão Offline',
  value: 'OFF_CC',
  hint: 'Cliente pagará fornecedor com VCN/Cartão'
}, {
  label: 'Cartão Online',
  value: 'ON_CC',
  hint: 'Cliente pagará fornecedor com VCN/Cartão e será cobrado na hora'
}, {
  label: 'Faturado Offline',
  value: 'OFF_FAT',
  hint: 'Cliente pagará fornecedor via fatura'
}];
const webbedsPaymentOptions = [{
  label: 'Cartão Online',
  value: 'ON_CC',
  hint: 'Cliente pagará fornecedor com VCN/Cartão e será cobrado na hora'
}, {
  label: 'Faturado Offline',
  value: 'OFF_FAT',
  hint: 'Cliente pagará fornecedor via fatura'
}];
const restelPaymentOptions = [{
  label: 'Direto no local',
  value: 'DIR',
  hint: 'Cliente pagará fornecedor no local do serviço (hotel/aeroporto/etc) e fornecerá cartão de crédito como garantia'
}, {
  label: 'Faturado Offline',
  value: 'OFF_FAT',
  hint: 'Cliente pagará fornecedor via fatura'
}, {
  label: 'Antecipado Offline',
  value: 'OFF_ANT',
  hint: 'Cliente pagará fornecedor antecipadamente'
}];
const w2mPaymentOptions = [{
  label: 'Faturado Offline',
  value: 'OFF_FAT',
  hint: 'Cliente pagará fornecedor via fatura'
}];
const trendPaymentOptions = [{
  label: 'Cartão Online',
  value: 'ON_CC',
  hint: 'Cliente pagará fornecedor com VCN/Cartão e será cobrado na hora'
}, {
  label: 'Direto no local',
  value: 'DIR',
  hint: 'Cliente pagará fornecedor no local do serviço (hotel/aeroporto/etc) e fornecerá cartão de crédito como garantia'
}, {
  label: 'Faturado Offline',
  value: 'OFF_FAT',
  hint: 'Cliente pagará fornecedor via fatura'
}];
const expediaPaymentOptions = [{
  label: 'Cartão Online',
  value: 'ON_CC',
  hint: 'Cliente pagará fornecedor com VCN/Cartão e será cobrado na hora'
}, {
  label: 'Direto no local',
  value: 'DIR',
  hint: 'Cliente pagará fornecedor no local do serviço (hotel/aeroporto/etc) e fornecerá cartão de crédito como garantia'
}];
export const niaraPaymentOptions = ([{
  label: 'Cartão de Crédito Online',
  value: 'INT_ON_CC',
  hint: 'Cliente pagará a instalação com VCN/Cartão e será cobrado na hora'
}, {
  label: 'Pix',
  value: 'INT_ON_PIX',
  hint: 'Cliente pagará a instalação com o PIX e será cobrado na hora'
}, {
  label: 'Faturado',
  value: 'INT_OFF_FAT',
  hint: 'Cliente pagará fornecedor via fatura'
}, {
  label: 'Offline',
  value: 'INT_OFF_OFF',
  hint: 'Cliente pagará fornecedor offline'
}, {
  label: 'Pagar Depois',
  value: 'INT_LATER',
  hint: 'Nenhuma forma de pagamento foi selecionada no momento da reserva'
}, {
  label: 'Cartão Offline (externo)',
  value: 'EXT_CC',
  hint: 'Cliente pagará com cartão que não será cobrado pela instalação'
}, {
  label: 'Direto no local (externo)',
  value: 'EXT_DIR',
  hint: 'Cliente pagará fornecedor no local do serviço (hotel/aeroporto/etc) e fornecerá cartão de crédito como garantia'
}, {
  label: 'Direto no local (IATA) (externo)',
  value: 'EXT_DIR_IATA',
  hint: 'Cliente pagará fornecedor no local do serviço (hotel/aeroporto/etc) e será usada a garantia IATA da agência'
}, {
  label: 'Pix (externo)',
  value: 'EXT_PIX',
  hint: 'Cliente pagará o fonecedor do hotel com PIX'
}, {
  label: 'Faturado (externo)',
  value: 'EXT_FAT',
  hint: 'Cliente pagará via fatura que não será controlada pela instalação'
}, {
  label: 'Offline (externo)',
  value: 'EXT_OFF',
  hint: 'Cliente pagará offline'
}, {
  label: 'Pagar Depois (externo)',
  value: 'EXT_LATER',
  hint: 'Nenhuma forma de pagamento foi selecionada no momento da reserva'
}] as const);
const defaultOptions: Partial<SupplierPaymentRule> = {
  omnibeesPaymentOptions: Object.assign({
    EXT_DIR: ['DIR'],
    EXT_DIR_IATA: ['DIR_IATA'],
    EXT_CC: ['OFF_CC'],
    EXT_FAT: ['OFF_FAT'],
    EXT_OFF: ['OFF_ANT'],
    EXT_PIX: []
  }, ...niaraPaymentOptions.filter(option => option.value.startsWith('INT_')).map(option => ({
    [option.value]: ['OFF_FAT', 'OFF_ANT', 'OFF_CC']
  }))),
  expediaPaymentOptions: Object.assign({
    EXT_DIR: ['DIR'],
    EXT_DIR_IATA: [],
    EXT_CC: ['ON_CC'],
    EXT_FAT: [],
    EXT_OFF: [],
    EXT_PIX: []
  }, ...niaraPaymentOptions.filter(option => option.value.startsWith('INT_')).map(option => ({
    [option.value]: ['ON_CC']
  }))),
  hotelbedsPaymentOptions: Object.assign({
    EXT_DIR: ['DIR'],
    EXT_DIR_IATA: [],
    EXT_CC: ['ON_CC', 'OFF_CC'],
    EXT_FAT: ['OFF_FAT'],
    EXT_OFF: [],
    EXT_PIX: []
  }, ...niaraPaymentOptions.filter(option => option.value.startsWith('INT_')).map(option => ({
    [option.value]: ['OFF_FAT']
  }))),
  webbedsPaymentOptions: Object.assign({
    EXT_DIR: [],
    EXT_DIR_IATA: [],
    EXT_CC: ['ON_CC'],
    EXT_FAT: ['OFF_FAT'],
    EXT_OFF: [],
    EXT_PIX: []
  }, ...niaraPaymentOptions.filter(option => option.value.startsWith('INT_')).map(option => ({
    [option.value]: ['OFF_FAT']
  }))),
  restelPaymentOptions: Object.assign({
    EXT_DIR: ['DIR'],
    EXT_DIR_IATA: [],
    EXT_CC: [],
    EXT_FAT: ['OFF_FAT'],
    EXT_OFF: ['OFF_ANT'],
    EXT_PIX: []
  }, ...niaraPaymentOptions.filter(option => option.value.startsWith('INT_')).map(option => ({
    [option.value]: ['OFF_FAT', 'OFF_ANT']
  }))),
  w2mPaymentOptions: Object.assign({
    EXT_DIR: [],
    EXT_DIR_IATA: [],
    EXT_CC: [],
    EXT_FAT: ['OFF_FAT'],
    EXT_OFF: [],
    EXT_PIX: []
  }, ...niaraPaymentOptions.filter(option => option.value.startsWith('INT_')).map(option => ({
    [option.value]: ['OFF_FAT']
  }))),
  trendPaymentOptions: Object.assign({
    EXT_DIR: ['DIR'],
    EXT_DIR_IATA: [],
    EXT_CC: ['ON_CC'],
    EXT_FAT: [],
    EXT_OFF: [],
    EXT_PIX: []
  }, ...niaraPaymentOptions.filter(option => option.value.startsWith('INT_')).map(option => ({
    [option.value]: ['ON_CC']
  }))),
  niaraPaymentOptions: Object.assign({
    EXT_DIR: ['EXT_DIR'],
    EXT_DIR_IATA: ['EXT_DIR_IATA'],
    EXT_CC: ['INT_ON_CC', 'EXT_CC'],
    EXT_FAT: ['INT_OFF_FAT', 'EXT_FAT'],
    EXT_OFF: ['INT_OFF_OFF', 'EXT_OFF'],
    EXT_PIX: []
  }, ...niaraPaymentOptions.filter(option => option.value.startsWith('INT_')).map(option => ({
    [option.value]: niaraPaymentOptions.filter(option => option.value.indexOf('PIX') === -1 && option.value.indexOf('DIR') === -1).map(option => option.value)
  })))
};
const getPaymentOptions = entityPaymentOptions => {
  if (!entityPaymentOptions) {
    return undefined;
  }
  const paymentOptions = [];
  Object.keys(entityPaymentOptions)?.forEach(po => {
    if (entityPaymentOptions?.[po]) {
      paymentOptions.push({
        option: po,
        options: entityPaymentOptions?.[po]
      });
    }
  });
  return <div style={{
    width: '100%'
  }}>
      {paymentOptions?.map((paymentOption, index) => <div key={index} style={{
      display: 'flex',
      flexWrap: 'wrap',
      width: '100%'
    }}>
          {`${paymentOption?.option}: ${paymentOption?.options?.join(', ')}`}
        </div>)}
    </div>;
};
const credentialTypes = ([{
  value: 'NIAR',
  label: 'Niara',
  prefix: 'niara'
}, {
  value: 'OMNI',
  label: 'Omnibees',
  prefix: 'omnibees'
}, {
  value: 'TRND',
  label: 'Trend',
  prefix: 'trend'
}, {
  value: 'EXPD',
  label: 'Expedia',
  prefix: 'expedia'
}, {
  value: 'HOTB',
  label: 'Hotelbeds',
  prefix: 'hotelbeds'
}, {
  value: 'WBDS',
  label: 'Webbeds',
  prefix: 'webbeds'
}, {
  value: 'W2MT',
  label: 'W2M',
  prefix: 'w2m'
}, {
  value: 'RSTL',
  label: 'Restel',
  prefix: 'restel'
}] as const);
const _setCheckTenantForSupplierSpecificFields = (field): PartialField => {
  const credentialType = credentialTypes.find(c => field.name?.startsWith(c.prefix));
  if (credentialType) {
    return {
      ...field,
      custom_checkTenant: tenant => tenant?.credentialTypes?.includes(credentialType.value)
    };
  } else {
    return field;
  }
};
export default fillCrudMetadataGaps({
  label: 'Pagamento ao Fornecedor',
  labelPlural: 'Pagamento ao Fornecedor',
  name: 'SupplierPaymentRule',
  namePlural: 'SupplierPaymentRules',
  custom_withAudit: true,
  authorization: authorization('consultant', 'admin'),
  deletable: false,
  dynamoDB: {
    tableName: 'SpearSupplierPaymentRule'
  },
  columns: ['id', 'name', 'enabled'],
  fields: [{
    label: 'Id',
    name: 'id',
    type: 'text',
    updatable: false,
    insertable: false,
    id: true,
    renderColumn: false
  }, {
    name: 'name',
    label: 'Nome da Regra',
    required: true
  }, {
    name: 'enabled',
    label: 'Ativo',
    required: true,
    default: true,
    type: 'boolean'
  }, {
    custom_groupName: "Omnibees",
    name: 'omnibeesPaymentOptions',
    label: 'Pagamentos liberados',
    type: 'object',
    default: defaultOptions.omnibeesPaymentOptions,
    fields: niaraPaymentOptions.map(option => ({
      name: option.value,
      label: option.label,
      type: 'textarray',
      withOptionSorter: true,
      inputType: 'niara-react-form/inputMultiSelect',
      options: omniPaymentOptions,
      hint: `Caso reserva paga com a forma de recebimento ${option.label}, serão priorizadas as formas de envio de pagamento abaixo para a Omnibees`
    })),
    render: e => getPaymentOptions(e.omnibeesPaymentOptions)
  }, {
    custom_groupName: "Hotelbeds",
    name: 'hotelbedsPaymentOptions',
    label: 'Pagamentos liberados',
    type: 'object',
    default: defaultOptions.hotelbedsPaymentOptions,
    fields: niaraPaymentOptions.map(option => ({
      name: option.value,
      label: option.label,
      type: 'textarray',
      withOptionSorter: true,
      inputType: 'niara-react-form/inputMultiSelect',
      options: hotelbedsPaymentOptions,
      hint: `Caso reserva paga com a forma de recebimento ${option.label}, serão priorizadas as formas de envio de pagamento abaixo para a Hotelbeds`
    })),
    render: e => getPaymentOptions(e.hotelbedsPaymentOptions)
  }, {
    custom_groupName: "Webbeds",
    name: 'webbedsPaymentOptions',
    label: 'Pagamentos liberados',
    type: 'object',
    default: defaultOptions.webbedsPaymentOptions,
    fields: niaraPaymentOptions.map(option => ({
      name: option.value,
      label: option.label,
      type: 'textarray',
      withOptionSorter: true,
      inputType: 'niara-react-form/inputMultiSelect',
      options: webbedsPaymentOptions,
      hint: `Caso reserva paga com a forma de recebimento ${option.label}, serão priorizadas as formas de envio de pagamento abaixo para a Webbeds`
    })),
    render: e => getPaymentOptions(e.webbedsPaymentOptions)
  }, {
    custom_groupName: "Restel",
    name: 'restelPaymentOptions',
    label: 'Pagamentos liberados',
    type: 'object',
    default: defaultOptions.restelPaymentOptions,
    fields: niaraPaymentOptions.map(option => ({
      name: option.value,
      label: option.label,
      type: 'textarray',
      withOptionSorter: true,
      inputType: 'niara-react-form/inputMultiSelect',
      options: restelPaymentOptions,
      hint: `Caso reserva paga com a forma de recebimento ${option.label}, serão priorizadas as formas de envio de pagamento abaixo para a Restel`
    })),
    render: e => getPaymentOptions(e.restelPaymentOptions)
  }, {
    custom_groupName: "W2M",
    name: 'w2mPaymentOptions',
    label: 'Pagamentos liberados',
    type: 'object',
    default: defaultOptions.w2mPaymentOptions,
    fields: niaraPaymentOptions.map(option => ({
      name: option.value,
      label: option.label,
      type: 'textarray',
      withOptionSorter: true,
      inputType: 'niara-react-form/inputMultiSelect',
      options: w2mPaymentOptions,
      hint: `Caso reserva paga com a forma de recebimento ${option.label}, serão priorizadas as formas de envio de pagamento abaixo para a W2M`
    })),
    render: e => getPaymentOptions(e.restelPaymentOptions)
  }, {
    custom_groupName: "Trend",
    name: 'trendPaymentOptions',
    label: 'Pagamentos liberados',
    type: 'object',
    default: defaultOptions.trendPaymentOptions,
    fields: niaraPaymentOptions.map(option => ({
      name: option.value,
      label: option.label,
      type: 'textarray',
      withOptionSorter: true,
      inputType: 'niara-react-form/inputMultiSelect',
      options: trendPaymentOptions,
      hint: `Caso reserva paga com a forma de recebimento ${option.label}, serão priorizadas as formas de envio de pagamento abaixo para a Trend`
    })),
    render: e => getPaymentOptions(e?.trendPaymentOptions)
  }, {
    custom_groupName: "Trend",
    name: 'trendExtraOptions',
    label: 'Extra no Faturamento',
    type: 'textarray',
    inputType: 'niara-react-form/inputMultiSelect',
    options: [{
      label: 'Apenas Diárias',
      value: 'NO_EXTRA',
      hint: 'Faturar apenas diárias.'
    }, {
      label: 'Incluir Extras',
      value: 'EXTRA',
      hint: 'Faturar com extras.'
    }, {
      label: 'Incluir Extras (exceto álcool)',
      value: 'EXTRA_ALCOHOL',
      hint: 'Faturar com extras (exceto álcool).'
    }, {
      label: 'Incluir Extras (exceto álcool e cigarros)',
      value: 'EXTRA_ALCOHOL_CIGAR',
      hint: 'Faturar com extrar (exceto álcool e cigarros).'
    }, {
      label: 'Não incluir Taxa de Turismo',
      value: 'NO_TURISM_TAX',
      hint: 'Faturar sem a taxa de turismo.'
    }, {
      label: 'Incluir apenas Lavanderia',
      value: 'LAUNDRY',
      hint: 'Faturar e adicionar apenas lavanderia.'
    }]
  }, {
    custom_groupName: "Expedia",
    name: 'expediaPaymentOptions',
    label: 'Pagamentos liberados',
    type: 'object',
    default: defaultOptions.expediaPaymentOptions,
    fields: niaraPaymentOptions.map(option => ({
      name: option.value,
      label: option.label,
      type: 'textarray',
      withOptionSorter: true,
      inputType: 'niara-react-form/inputMultiSelect',
      options: expediaPaymentOptions,
      hint: `Caso reserva paga com a forma de recebimento ${option.label}, serão priorizadas as formas de envio de pagamento abaixo para a Expedia`
    })),
    render: e => getPaymentOptions(e.expediaPaymentOptions)
  }, {
    custom_groupName: "Niara",
    name: 'niaraPaymentOptions',
    label: 'Pagamentos liberados',
    type: 'object',
    default: defaultOptions.niaraPaymentOptions,
    fields: niaraPaymentOptions.map(option => ({
      name: option.value,
      label: option.label,
      type: 'textarray',
      withOptionSorter: true,
      inputType: 'niara-react-form/inputMultiSelect',
      options: niaraPaymentOptions,
      hint: `Caso reserva paga com a forma de recebimento ${option.label}, serão priorizadas as formas de envio de pagamento abaixo para o Niara`
    })),
    render: e => getPaymentOptions(e.niaraPaymentOptions)
  }].map(_setCheckTenantForSupplierSpecificFields),
  filter: []
});