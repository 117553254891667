import { Divider, Portal, Stack } from '@mui/material';
import { formatDate, formatNumber } from '@niaratech/niara-js-commons';
import { addDays, diffInDays } from '@niaratech/niara-js-commons/dist/dateUtils';
import { useImmutableFunction } from '@niaratech/niara-react-components/src';
import { isAfter, isBefore, parseISO, startOfDay, addDays as addDaysAsDate, differenceInMonths } from 'date-fns';
import React, { useEffect, useMemo, useState } from 'react';
import ClickAwayListener from 'react-click-away-listener';
import { BsChevronLeft, BsChevronRight } from 'react-icons/bs';
import { useTheme } from 'styled-components';
import _upperFirst from 'lodash/upperFirst';
import Modal from '../../components/Modal';
import useDefaultPortalContext from '../../portal/useDefaultPortalContext';
import { BREAKPOINT_MD } from '../../theme/ota';
import RenderDate, { RenderDateProps } from './RenderDate';
import * as S from './styles';
type Value = {
  startDate?: string;
  endDate?: string;
};
export type DatePickerProps = Omit<RenderDateProps, 'minDate' | 'maxDate' | 'ranges' | 'onChange'> & {
  initOpened?: boolean;
  headerSize?: string;
  onShown?: () => void;
  onHandleChangeDate?: () => void;
  searchEngine_orientation?: 'VERTICAL' | 'HORIZONTAL';
  oneway?: boolean;
  loading?: boolean;
  activeHover?: boolean;
  minDiffDays?: number;
  personSearchEnabled?: boolean;
  minDate?: Date | string | undefined;
  maxDate?: Date | string | undefined;
  value?: Value;
  setValue?: React.Dispatch<React.SetStateAction<Value>>;
  referenceComponent?: React.MutableRefObject<any>;
};
type DateModify = 'START_DATE_SUBTRACT_ONE' | 'START_DATE_ADD_ONE' | 'END_DATE_SUBTRACT_ONE' | 'END_DATE_ADD_ONE';
type DateChangeProps = {
  modify: DateModify;
};
const DatePicker: React.FC<DatePickerProps> = ({
  focusedRange,
  maxDate: _maxDate,
  minDate: _minDate,
  initOpened,
  headerSize,
  calencarPosition,
  detailsToCalendar = [],
  disabledDates,
  monthDisplayFormat = 'LLLL, yyyy',
  centerMode = false,
  onShown,
  onHandleChangeDate,
  onShownDateChange,
  searchEngine_orientation,
  initialFocusedRange,
  loading,
  activeHover,
  oneway,
  minDiffDays = 1,
  personSearchEnabled,
  value,
  setValue,
  referenceComponent
}) => {
  const datePickerRef = React.useRef(null);
  const startDateStr = value?.startDate;
  const endDateStr = value?.endDate;
  /**
   * adapta value para ranges
   */
  const ranges = useMemo<[{
    startDate: Date;
    endDate: Date;
    key: 'selection';
  }]>(() => {
    if (startDateStr && endDateStr) {
      return [{
        startDate: parseISO(startDateStr),
        endDate: parseISO(endDateStr),
        key: 'selection'
      }];
    } else if (startDateStr) {
      return [{
        startDate: parseISO(startDateStr),
        endDate: addDaysAsDate(parseISO(startDateStr), 1),
        key: 'selection'
      }];
    } else {
      return [{
        startDate: startOfDay(new Date()),
        endDate: addDaysAsDate(startOfDay(new Date()), 1),
        key: 'selection'
      }];
    }
  }, [startDateStr, endDateStr]);
  const startDate = ranges?.[0]?.startDate;
  const endDate = ranges?.[0]?.endDate;

  /**
   * adapta setValue para onChange
   */
  const onChange = useImmutableFunction<RenderDateProps['onChange']>(ranges => {
    if (setValue) {
      setValue(oldValue => {
        const newStartDate = ranges?.selection?.startDate;
        const newEndDate = ranges?.selection?.endDate;
        const newValue = [newStartDate, newEndDate].map(date => date ? formatDate(date, undefined, 'yyyy-MM-dd') : null);
        if (newValue?.[0] == oldValue?.startDate && newValue?.[1] == oldValue?.endDate) {
          return oldValue;
        } else {
          return {
            startDate: newValue?.[0],
            endDate: newValue?.[1]
          };
        }
      });
    }
  });
  const [size, setSize] = useState<number>();
  const [openDate, setOpenDate] = useState(initOpened ?? false);
  const theme = useTheme();
  useEffect(() => {
    if (openDate && onShown) {
      onShown();
    }
    // não disparar onShown se mudar a instância
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [openDate]);
  const nights = startDate && endDate ? diffInDays(endDate, startDate) : 0;
  const handleChange: RenderDateProps['onChange'] = item => {
    const hasSelectedRanges = oneway || diffInDays(item?.selection?.endDate, item?.selection?.startDate) > 0;
    onChange(item);
    if (hasSelectedRanges) {
      setOpenDate(false);
    }
  };
  useEffect(() => {
    if (typeof window !== 'undefined') {
      setSize(window.innerWidth);
    }
    const onResize = () => {
      setSize(window.innerWidth);
    };
    window.addEventListener('resize', onResize);
  }, []);
  const customDate = (date: any) => date.toLocaleDateString('pt-BR', {
    weekday: 'short',
    day: '2-digit',
    month: 'short'
  }).replace('de', ' ').replace('.', '');
  const [minDate, maxDate] = React.useMemo(() => {
    return [_minDate, _maxDate].map(dateReceived => {
      if (!dateReceived) return undefined;
      if (typeof dateReceived === 'string') {
        return parseISO(dateReceived);
      } else {
        return dateReceived;
      }
    });
  }, [_minDate, _maxDate]);
  const startDateCanBeReduced = startDate ? isAfter(startDate, minDate ?? new Date()) : false;
  const startDateCanBeIncreased = !startDate ? false : maxDate ? isBefore(startDate, parseISO(addDays(maxDate ?? new Date(), -1 * minDiffDays))) : true;
  const endDateCanBeReduced = !endDate ? false : isAfter(endDate, parseISO(addDays(minDate ?? new Date(), minDiffDays)));
  const endDateCanBeIncreased = !endDate ? false : maxDate ? isBefore(endDate, maxDate) : true;
  const handeAddDayChange = ({
    modify
  }: DateChangeProps) => {
    let newStartDate = new Date(startDate);
    let newEndDate = new Date(endDate);
    if (!openDate) {
      switch (modify) {
        case 'START_DATE_SUBTRACT_ONE':
          if (!startDateCanBeReduced) break;
          newStartDate = new Date(addDays(startDate.toISOString(), -1));
          break;
        case 'START_DATE_ADD_ONE':
          if (!startDateCanBeIncreased) break;
          newStartDate = new Date(addDays(startDate.toISOString(), 1));
          if (diffInDays(endDate, startDate) <= minDiffDays) {
            newEndDate = new Date(addDays(newStartDate.toISOString(), minDiffDays));
          }
          break;
        case 'END_DATE_SUBTRACT_ONE':
          if (!endDateCanBeReduced) break;
          if (diffInDays(endDate, startDate) > minDiffDays) {
            newEndDate = new Date(addDays(endDate.toISOString(), -1));
          } else {
            newEndDate = new Date(addDays(endDate.toISOString(), -1));
            newStartDate = new Date(addDays(newEndDate.toISOString(), -minDiffDays));
          }
          break;
        case 'END_DATE_ADD_ONE':
          if (!endDateCanBeIncreased) break;
          newEndDate = new Date(addDays(endDate.toISOString(), 1));
          break;
        default:
          break;
      }
    }
    onHandleChangeDate && onHandleChangeDate();
    onChange({
      selection: {
        startDate: newStartDate,
        endDate: newEndDate
      }
    });
  };
  const portalContainer = useDefaultPortalContext()?.container;
  const lowestPrice = useMemo(() => {
    if (detailsToCalendar?.length < 1) return;
    const lowPrice = detailsToCalendar.sort((oldValue, newValue) => (oldValue?.value ?? 0) - (newValue?.value ?? 0)).filter(i => i.value != null)[0];
    return `${lowPrice?.currency == 'BRL' ? 'R$' : ''} ${formatNumber(Math.trunc(lowPrice?.value ?? 0), lowPrice?.currency ?? 'BRL', 0)}`;
  }, [detailsToCalendar]);
  React.useEffect(() => {
    // Calcula a posição left de onde deve iniciar o componente do calendário para ele ficar centralizado com o componente referencia passado.
    // O left = 0 é o início do campo do calendário, ou seja, para calcular o meio o left ficará negativo
    // Se não passar o componente referência, ele não ficará centralizado com nada e ficará alinhado à esquerda do campo do calendário
    // Isso é utilizado apenas para o modo desktop
    if (!openDate) return;
    if (referenceComponent && referenceComponent.current && datePickerRef && datePickerRef.current) {
      if (!datePickerRef.current?.getBoundingClientRect?.()) return;
      const calendarRef = datePickerRef.current?.querySelector('.rdrCalendarWrapper');
      if (!calendarRef) return;
      const referenceRect = referenceComponent.current?.getBoundingClientRect();
      const calendarRect = calendarRef?.getBoundingClientRect();
      const sizeDiff = (calendarRect.width - referenceRect.width) / 2;
      const leftTranslate = calendarRect.left - referenceRect.left;
      let leftPosition = -(leftTranslate + sizeDiff);
      const exceededLeftScreen = referenceRect.left - sizeDiff;
      if (exceededLeftScreen < 0) leftPosition -= exceededLeftScreen;

      // const exceededRightScreen = screen.width - (leftPosition + referenceRect.width + calendarRect.width)
      // if (exceededRightScreen < 0) leftPosition += exceededRightScreen

      // console.log({ referenceRect, calendarRect, screenWidth: screen.width })
      datePickerRef.current.style.position = 'absolute';
      datePickerRef.current.style.left = `${leftPosition}px`;
    }
  }, [referenceComponent, openDate]);
  if (size < +BREAKPOINT_MD.replace('px', '')) {
    return openDate ? <Portal container={portalContainer}>
        {/* Coloco em um portal para funcionar melhor no widget - portal não vai funcionar legal */}
        <Modal show={openDate} setShow={() => setOpenDate(false)} tabletVariant="full" mobileVariant="full" desktopVariant="none" showCloseButton headerSize={headerSize} zIndex={2147483647}>
          <S.Container centerMode={centerMode} visibleDate className="datepicker-modal-container datepicker-container openDate">
            <S.Header>
              <S.HeaderTitle>Selecione suas datas</S.HeaderTitle>
            </S.Header>
            <RenderDate horizontal={false} ranges={ranges} rangeColors={[theme?.colors?.primary]} months={minDate && maxDate ? differenceInMonths(maxDate, minDate) + 1 : 24} focusedRange={focusedRange} onChange={handleChange} minDate={minDate} maxDate={maxDate} detailsToCalendar={detailsToCalendar} disabledDates={disabledDates} monthDisplayFormat={monthDisplayFormat} onShownDateChange={onShownDateChange} loading={loading} activeHover={activeHover} oneway={false} initialFocusedRange={initialFocusedRange} showMonthAndYearPickers={false} />

            <S.Footer>
              {ranges?.filter(Boolean)?.map(item => <div key={item.key}>
                  <h4>
                    {_upperFirst(formatDate(item.startDate, undefined, 'EEEEEE, d MMM'))} -{' '}
                    {_upperFirst(formatDate(item.endDate, undefined, 'EEEEEE, d MMM'))}
                  </h4>
                  {nights > 1 ? <p>({nights} Noites)</p> : <p>({nights} Noite)</p>}
                </div>)}

              <S.Button color="primary" variant="contained" disabled={diffInDays(endDate, startDate) <= 0} fullWidth type="button" onClick={() => setOpenDate(false)}>
                Aplicar
              </S.Button>
            </S.Footer>
          </S.Container>
        </Modal>
      </Portal> : <S.Container centerMode={centerMode} className={'datepicker-container'}>
        <S.Btn type="button" openDate={openDate} onClick={() => setOpenDate(true)}>
          <RenderDate ranges={ranges} rangeColors={[theme?.colors?.primary]} months={0} focusedRange={focusedRange} onChange={handleChange} minDate={minDate} maxDate={maxDate} detailsToCalendar={detailsToCalendar} disabledDates={disabledDates} monthDisplayFormat={monthDisplayFormat} onShownDateChange={onShownDateChange} loading={loading} activeHover={activeHover} />
        </S.Btn>
        <S.BtnArrows personSearchEnabled={personSearchEnabled} type="button" className="startStart" disabled={!startDateCanBeReduced} onClick={() => handeAddDayChange({
        modify: 'START_DATE_SUBTRACT_ONE'
      })}>
          <BsChevronLeft />
        </S.BtnArrows>
        <S.BtnArrows personSearchEnabled={personSearchEnabled} className="startEnd" type="button" disabled={!startDateCanBeIncreased} onClick={() => handeAddDayChange({
        modify: 'START_DATE_ADD_ONE'
      })}>
          <BsChevronRight />
        </S.BtnArrows>
        <S.BtnArrows personSearchEnabled={personSearchEnabled} className="endStart" type="button" disabled={!endDateCanBeReduced} onClick={() => handeAddDayChange({
        modify: 'END_DATE_SUBTRACT_ONE'
      })}>
          <BsChevronLeft />
        </S.BtnArrows>
        <S.BtnArrows personSearchEnabled={personSearchEnabled} className="endEnd" type="button" disabled={!endDateCanBeIncreased} onClick={() => handeAddDayChange({
        modify: 'END_DATE_ADD_ONE'
      })}>
          <BsChevronRight />
        </S.BtnArrows>
      </S.Container>;
  }
  return <S.Container centerMode={centerMode} className={'datepicker-container'} data-testid="datepicker-checkin-checkout">
      <S.Btn type="button" openDate={openDate} onClick={() => setOpenDate(!openDate)}>
        <RenderDate horizontal ranges={ranges} rangeColors={[theme?.colors?.primary]} months={0} focusedRange={focusedRange} onChange={handleChange} minDate={minDate} maxDate={maxDate} detailsToCalendar={detailsToCalendar} disabledDates={disabledDates} monthDisplayFormat={monthDisplayFormat} onShownDateChange={onShownDateChange} loading={loading} activeHover={activeHover} oneway={oneway} />
      </S.Btn>
      <S.BtnArrows type="button" personSearchEnabled={personSearchEnabled} className="startStart" disabled={!startDateCanBeReduced} onClick={() => handeAddDayChange({
      modify: 'START_DATE_SUBTRACT_ONE'
    })}>
        <BsChevronLeft />
      </S.BtnArrows>
      <S.BtnArrows personSearchEnabled={personSearchEnabled} className={oneway ? 'endEnd' : 'startEnd'} type="button" disabled={!startDateCanBeIncreased} onClick={() => handeAddDayChange({
      modify: 'START_DATE_ADD_ONE'
    })}>
        <BsChevronRight />
      </S.BtnArrows>
      {!oneway && <S.BtnArrows personSearchEnabled={personSearchEnabled} className="endStart" type="button" disabled={!endDateCanBeReduced} onClick={() => handeAddDayChange({
      modify: 'END_DATE_SUBTRACT_ONE'
    })}>
          <BsChevronLeft />
        </S.BtnArrows>}
      {!oneway && <S.BtnArrows personSearchEnabled={personSearchEnabled} className="endEnd" type="button" disabled={!endDateCanBeIncreased} onClick={() => handeAddDayChange({
      modify: 'END_DATE_ADD_ONE'
    })}>
          <BsChevronRight />
        </S.BtnArrows>}
      {openDate && <ClickAwayListener onClickAway={() => setOpenDate(false)}>
          <S.Container calencarPosition={calencarPosition} visibleDate zIndex={theme?.zIndex.searchCalendar} className="openDate" centerMode={centerMode} ref={datePickerRef}>
            <RenderDate horizontal searchEngine_orientation={'VERTICAL'} ranges={ranges} rangeColors={[theme?.colors?.primary]} months={openDate ? 2 : 0} focusedRange={focusedRange} onChange={handleChange} minDate={minDate} maxDate={maxDate} detailsToCalendar={detailsToCalendar} disabledDates={disabledDates} monthDisplayFormat={monthDisplayFormat} onShownDateChange={onShownDateChange} loading={loading} activeHover={activeHover} />
            <S.LegendsDatePicker>
              <Stack direction={'row'} divider={<Divider orientation="vertical" flexItem />} gap={'10px'} className="datePickerLegend" marginTop="-25px" marginLeft="30px">
                {detailsToCalendar?.length > 0 && <p className="lowestPriceContainer">
                    Diárias a partir de: <span className="lowestPrice">{lowestPrice}</span>
                  </p>}
                <div className="legend">
                  <div className="legend-no-availability-icon">x</div>
                  <div>Sem disponibilidade</div>
                </div>
                <div className="legend">
                  <div className="legend-availability-icon">x</div>
                  <div>Mínimo de noites</div>
                </div>
              </Stack>
            </S.LegendsDatePicker>
          </S.Container>
        </ClickAwayListener>}
    </S.Container>;
};
export default DatePicker;