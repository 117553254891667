import fillCrudMetadataGaps from '../../fillCrudMetadataGaps';
import authorization from '../../util/authorization';
import { printPrice } from '@niaratech/niara-js-commons';
import React, { Fragment } from 'react';
import type { Payment } from '../../types';
export const statusOptions = [{
  value: 'CREATED',
  label: 'Em andamento'
}, {
  value: 'PROCESSING',
  label: 'Em andamento'
}, {
  value: 'PENDING',
  label: 'Pendente'
}, {
  value: 'FAILED',
  label: 'Erro'
}, {
  value: 'REJECTED',
  label: 'Rejeitado'
}, {
  value: 'CANCELLED',
  label: 'Estornado'
}, {
  value: 'REFUNDED',
  label: 'Estornado'
} /** usado? */, {
  value: 'AUTHORIZED',
  label: 'Autorizado'
}, {
  value: 'CONFIRMED',
  label: 'Confirmado'
}, {
  value: 'SCHEDULED',
  label: 'Agendado'
}, {
  value: 'PRE_CONFIRMED',
  label: 'Pagamento pendente'
}];
export default fillCrudMetadataGaps<Payment>({
  label: 'Pagamento',
  labelPlural: 'Pagamentos',
  name: 'Payment',
  insertable: true,
  updatable: false,
  deletable: false,
  custom_withAudit: true,
  authorization: authorization('clientConsultant', 'master'),
  dynamoDB: {
    tableName: 'SpearPayment',
    indexes: [{
      indexedProp: 'orderId',
      indexName: 'tenantId-orderId-index'
    }, {
      indexedProp: 'productId',
      indexName: 'tenantId-productId-index'
    }, {
      indexedProp: 'serviceDate',
      indexName: 'tenantId-serviceDate-index'
    }, {
      indexedProp: 'creditControl_clientPaymentProduct',
      indexName: 'tenantId-creditControl_clientPaymentProduct-index'
    }]
  },
  columnNames: ['id', 'type', 'createDate', 'merchantType', 'provider', 'creditCard', 'amount', 'currency', 'amountCancelled', 'pendingAmountCancelled', 'installments', 'message', 'authorizationCode', 'proofOfSale', 'providerReturn', 'status'],
  fields: [{
    label: 'Id',
    name: 'id',
    type: 'text',
    updatable: false,
    insertable: false,
    render: false,
    id: true
  }, {
    name: 'createDate',
    label: 'Data da criação do registro',
    type: 'datetime',
    hint: 'Em transações online, é a data da transação',
    required: true,
    updatable: false,
    insertable: false,
    custom_auditConfig: {
      sortOrder: 3
    }
  }, {
    // Campo novo, para gravar a data da transação, que é diferente da data de criação para pagamentos inseridos manualmente (NIT-918)
    name: 'transactionDate',
    label: 'Data da transação',
    type: 'datetime',
    insertable: true
  }, {
    // Por enquanto, exclusivamente informativo. Para pagamentos inseridos manualmente (NIT-918)
    name: 'dueDate',
    label: 'Data de vencimento do pagamento (fatura)',
    type: 'datetime',
    insertable: true
  }, {
    name: 'amount',
    label: 'Valor',
    type: 'float',
    updatable: false,
    custom_auditConfig: {
      sortOrder: 5
    },
    renderColumn: i => <div>
          {i?.status == 'CONFIRMED' && i.amountCancelled > 0 ? <Fragment>
              <span className="mr-1">
                <del>{printPrice(i.amount, i.currency)}</del>
              </span>
              <span>{printPrice(i.amount - (i.amountCancelled || 0), i.currency)}</span>
            </Fragment> : <span>{printPrice(i.amount, i.currency)}</span>}
          {i.installments && i.installments > 1 && <span className="ml-1">{'(' + i.installments + 'x)'}</span>}
        </div>,
    render: i => <div>
          {i?.status == 'CONFIRMED' && i.amountCancelled > 0 ? <Fragment>
              <span className="mr-1">
                <del>{printPrice(i.amount, i.currency)}</del>
              </span>
              <span>{printPrice(i.amount - (i.amountCancelled || 0), i.currency)}</span>
            </Fragment> : <span>{printPrice(i.amount, i.currency)}</span>}
          {i.installments && i.installments > 1 && <span className="ml-1">{'(' + i.installments + 'x)'}</span>}
        </div>
  }, {
    name: 'credentialId',
    updatable: false,
    render: false
  }, {
    name: 'currency',
    label: 'Moeda',
    updatable: false,
    render: false
  }, {
    name: 'amountCancelled',
    label: 'Valor Estornado',
    type: 'float',
    updatable: false,
    render: false
  }, {
    name: 'pendingAmountCancelled',
    label: 'Valor Estornado a Confirmar',
    type: 'float',
    updatable: false,
    render: false
  }, {
    name: 'type',
    label: 'Meio de pagamento',
    updatable: false,
    custom_auditConfig: {
      sortOrder: 7
    },
    options: [{
      label: 'Cartão de Crédito',
      value: 'CREDITCARD'
    }, {
      label: 'Pontos',
      value: 'POINTS'
    }, {
      label: 'Pix',
      value: 'PIX'
    }, {
      label: 'Faturado',
      value: 'FAT'
    }, /** @deprecated */
    {
      label: 'Faturado',
      value: 'OFF_FAT'
    }, {
      label: 'Antecipado',
      value: 'ANT'
    }, {
      label: 'Pendente pagamento',
      value: 'LATER'
    }]
  }, {
    name: 'source',
    label: 'Gateway',
    updatable: false,
    custom_auditConfig: {
      sortOrder: 8
    }
  }, {
    name: 'provider',
    label: 'Adquirente',
    updatable: false,
    custom_auditConfig: {
      sortOrder: 27
    }
  }, {
    name: 'creditCard',
    label: 'Cartão',
    type: 'object',
    updatable: false,
    insertable: true,
    render: i => i.creditCard && i.creditCard.number,
    graphQLType: 'EmbeddedPaymentCreditCard',
    graphQLInputType: 'EmbeddedPaymentCreditCardInput',
    custom_renderFieldsRecursively: true,
    custom_auditConfig: {
      groupName: 'Cartão',
      sortOrder: 30
    },
    fields: [{
      name: 'brand',
      label: 'Bandeira do cartão'
    }, {
      name: 'number',
      label: 'Número do cartão',
      custom_excludeFromAuditValue: true
    }, {
      name: 'holder',
      label: 'Nome no cartão',
      custom_excludeFromAuditValue: true
    }
    // {
    //   name: 'expiryDate',
    //   label: 'Data de expiração (MMAA)',
    //   custom_excludeFromAuditValue: true,
    // },
    ]
  }, {
    name: 'installments',
    label: 'Parcelas',
    type: 'int',
    updatable: false,
    render: false
  }, {
    name: 'productId',
    label: 'Id do Produto',
    updatable: false,
    custom_auditConfig: {
      show: 'NEVER',
      sortOrder: 2
    }
  }, {
    name: 'productType',
    label: 'Tipo do Produto',
    updatable: false
  }, {
    name: 'productName',
    label: 'Nome do Produto',
    updatable: false
  }, {
    name: 'authorizationCode',
    label: 'Cód.Aut.',
    updatable: false,
    custom_auditConfig: {
      sortOrder: 26
    }
  }, {
    name: 'locator',
    label: 'Localizador',
    updatable: false,
    custom_auditConfig: {
      show: 'NEVER'
    }
  }, {
    name: 'message',
    label: 'Retorno',
    updatable: false,
    custom_auditConfig: {
      sortOrder: 28
    },
    renderColumn: i => i.providerReturn && i.providerReturn.message ? <div>
            <span>
              {i.providerReturn.message.length > 25 ? i.providerReturn.message.substring(0, 25) + '...' : i.providerReturn.message}
            </span>
            {i.providerReturn.messageCode && <span className="ml-2">({i.providerReturn.messageCode})</span>}
            {i.providerReturn.messageDetails && <span data-toggle="tooltip" className="ml-2" title={i.providerReturn.messageDetails}>
                <i className="fa fa-info-circle"></i>
              </span>}
          </div> : <div>{i.message}</div>
  }, {
    name: 'status',
    label: 'Status',
    updatable: false,
    options: statusOptions,
    custom_auditConfig: {
      sortOrder: 6
    },
    renderStyle(i, fieldName = 'status') {
      //previne i null
      if (!i) {
        return 'danger';
      }
      if (i[fieldName]) {
        if (i?.status == 'CONFIRMED' && ((i?.pendingAmountCancelled ?? 0) > 0 || (i?.amountCancelled ?? 0) > 0)) {
          return 'warning';
        }
        if (i?.status == 'PRE_CONFIRMED' && i?.creditControl_clientPaymentProduct == null) {
          return 'success';
        }
        switch ((i[fieldName] as Payment['status'])) {
          case 'FAILED':
          case 'REJECTED':
          case 'CANCELLED':
          case 'REFUNDED':
            return 'danger';
          case 'PENDING':
          case 'CREATED':
            return 'warning';
          case 'AUTHORIZED':
          case 'SCHEDULED':
          case 'PRE_CONFIRMED':
          case 'PROCESSING':
            return 'info';
          case 'CONFIRMED':
            return 'success';
        }
      }
      return 'danger';
    },
    render: i => {
      if (i?.status == 'CONFIRMED' && (i?.pendingAmountCancelled ?? 0) > 0) {
        return "Aguardando processamento de estorno (" + printPrice(i?.pendingAmountCancelled || 0, i.currency) + ')';
      }
      if (i?.status == 'CONFIRMED' && (i?.amountCancelled ?? 0) > 0) {
        return "Parcialmente estornado";
      }
      if (i?.status == 'PRE_CONFIRMED' && i?.creditControl_clientPaymentProduct == null) {
        return "Confirmado";
      }
      if (i?.status == 'CANCELLED' && i?.type == 'FAT') {
        return "Cancelado";
      }
      return statusOptions.filter(s => s?.value === i?.status)?.[0]?.label || i?.status;
    }
  }, {
    name: 'pendingReason',
    label: 'Pendência',
    updatable: false,
    options: [{
      value: 'CAPTURE_CONFIRMATION',
      label: 'Aguardando confirmação do pagamento'
    }, {
      value: 'PIX_PAYMENT',
      label: 'Aguardando o pagamento do PIX'
    }, {
      value: 'FRAUD_ANALYSIS',
      label: 'Pagamento em análise'
    }, {
      value: '3DS_AUTHENTICATION',
      label: 'Autenticação 3DS solicitada'
    }]
  }, {
    name: 'proofOfSale',
    label: 'NSU',
    updatable: false,
    custom_auditConfig: {
      sortOrder: 25
    }
  }, {
    name: 'description',
    label: 'Descritor',
    updatable: false,
    render: false
  }, {
    name: 'providerReturn',
    label: 'Retorno Adquirente',
    updatable: false,
    type: 'object',
    render: false
  }, {
    name: 'merchantId',
    label: 'ID do Cobrador',
    updatable: false,
    render: false
  }, {
    name: 'merchantType',
    label: 'Recebido por',
    updatable: false,
    custom_hiddenFromEmbededView: true,
    render: i => i.merchantType == 'HOTEL' ? 'Hotel' : 'Agência',
    custom_auditConfig: {
      show: 'NEVER',
      sortOrder: 4
    }
  }, {
    name: 'captureDate',
    label: 'Data Captura',
    type: 'datetime',
    updatable: false,
    custom_auditConfig: {
      sortOrder: 24
    }
  }, {
    name: 'cancellationDate',
    label: 'Data Estorno',
    type: 'datetime',
    updatable: false
  }, {
    name: 'comment',
    label: 'Observações',
    updatable: false
  }, {
    name: 'serviceLocator',
    label: 'Localizador',
    updatable: false
  }, {
    name: 'serviceDate',
    label: 'Data da criação do serviço',
    type: 'datetime',
    updatable: false
  }, {
    name: 'serviceStartDate',
    label: 'Check-in',
    type: 'date',
    updatable: false
  }, {
    name: 'serviceEndDate',
    label: 'Check-out',
    type: 'date',
    updatable: false
  }, {
    name: 'servicePax',
    label: 'Passageiro',
    updatable: false
  }, {
    name: 'serviceTotalBeforeTaxes',
    label: 'Valor da reserva',
    type: 'float',
    updatable: false
  }, {
    name: 'localCurrency',
    label: 'Moeda local',
    updatable: false
  }, {
    name: 'localCurrencyAmount',
    label: 'Valor em moeda local',
    type: 'float',
    updatable: false
  }, {
    name: 'localCurrencyTaxes',
    label: 'Impostos em moeda local',
    type: 'float',
    updatable: false
  }, {
    name: 'currencyRate',
    label: 'Taxa de câmbio',
    type: 'float',
    updatable: false
  }, {
    name: 'is3DS',
    label: 'Autenticado 3DS',
    type: 'boolean',
    updatable: false
  }, {
    name: 'authentication3DScavv',
    label: '3DS: cavv',
    updatable: false
  }, {
    name: 'authentication3DSxid',
    label: '3DS: xid',
    updatable: false
  }, {
    name: 'authentication3DSeci',
    label: '3DS: eci',
    updatable: false
  }, {
    name: 'authentication3DSeciString',
    label: '3DS: ECI Texto',
    updatable: false
  }, {
    name: 'binInfo',
    insertable: false,
    updatable: false,
    type: 'object',
    graphQLType: 'EmbeddedPaymentBinInfo',
    custom_renderFieldsRecursively: true,
    custom_auditConfig: {
      groupName: 'Bin Info'
    },
    fields: [{
      name: 'type',
      label: 'Tipo'
    }, {
      name: 'foreign',
      type: 'boolean',
      label: 'Estrangeiro'
    }, {
      name: 'corporate',
      type: 'boolean',
      label: 'Corporativo'
    }, {
      name: 'issuerName',
      label: 'Nome do emissor'
    }, {
      name: 'issuerCode',
      label: 'Código do emissor'
    }]
  }, {
    name: 'authentication3DSecChallenge',
    label: 'Desafio 3DS',
    updatable: false,
    type: 'object',
    // Objeto opcaco retornado pela adyen, por esse motivo seus campos não foram especificados
    custom_renderFieldsRecursively: true,
    custom_excludeFromAuditValue: true,
    custom_auditConfig: {
      groupName: 'Desafio 3DS'
    }
  }, {
    name: 'pixQrCodeContent',
    label: 'Conteúdo do QR CODE',
    updatable: false
  }, {
    name: 'pixQrCode',
    label: 'QR CODE do PIX',
    updatable: false
  }, {
    name: 'pixDueDate',
    label: 'Prazo para pagamento do pix',
    updatable: false,
    type: 'datetime'
  }, {
    name: 'pixKeyType',
    type: 'text'
  }, {
    name: 'paidItems',
    insertable: true,
    updatable: false,
    type: 'objectarray',
    custom_renderFieldsRecursively: true,
    graphQLType: '[EmbeddedPaidItem]',
    graphQLInputType: '[EmbeddedPaidItemInput]',
    custom_auditConfig: {
      groupName: 'Item Pago',
      sortOrder: 1000
    },
    fields: [{
      name: 'type',
      label: 'Tipo',
      custom_auditConfig: {
        sortOrder: 1000.0
      }
    }, {
      name: 'id',
      label: 'Id da reserva',
      custom_auditConfig: {
        show: 'ALWAYS',
        sortOrder: 1000.01
      }
    }, {
      name: 'amount',
      label: 'Valor',
      type: 'float',
      render: entity => printPrice(entity?.amount, entity?.currency),
      custom_auditConfig: {
        sortOrder: 1000.06
      }
    }, {
      name: 'amountCancelled',
      label: 'Valor cancelado',
      type: 'float',
      render: entity => printPrice(entity?.amountCancelled, entity?.currency),
      custom_auditConfig: {
        sortOrder: 1000.07
      }
    }, {
      name: 'exchangeAmountCancelled',
      label: 'Câmbio valor cancelado',
      type: 'float',
      render: entity => printPrice(entity?.exchangeAmountCancelled, entity?.exchangeCurrency),
      custom_auditConfig: {
        sortOrder: 1000.11
      }
    }, {
      name: 'pendingAmountCancelled',
      label: 'Valor cancelado pendente',
      type: 'float',
      render: entity => printPrice(entity?.pendingAmountCancelled, entity?.currency),
      custom_auditConfig: {
        sortOrder: 1000.08
      }
    }, {
      name: 'exchangePendingAmountCancelled',
      label: 'Câmbio valor cancelado pendente',
      type: 'float',
      render: entity => printPrice(entity?.exchangePendingAmountCancelled, entity?.exchangeCurrency),
      custom_auditConfig: {
        sortOrder: 1000.12
      }
    }, {
      name: 'productId',
      label: 'Id do Hotel',
      custom_auditConfig: {
        show: 'NEVER'
      }
    }, {
      name: 'productName',
      label: 'Nome do Hotel',
      custom_auditConfig: {
        show: 'ALWAYS',
        sortOrder: 1000.02
      }
    }, {
      name: 'startDate',
      label: 'Data de checkin',
      type: 'date',
      custom_auditConfig: {
        sortOrder: 1000.03
      }
    }, {
      name: 'endDate',
      label: 'Data de checkout',
      type: 'date',
      custom_auditConfig: {
        sortOrder: 1000.04
      }
    }, {
      name: 'primaryGuest',
      label: 'Hóspede',
      custom_auditConfig: {
        show: 'NEVER'
      }
    }, {
      name: 'currency',
      label: 'Moeda',
      updatable: false,
      render: false,
      custom_auditConfig: {
        sortOrder: 1000.05
      }
    }, {
      name: 'exchangeAmount',
      label: 'Valor de Câmbio',
      type: 'float',
      updatable: false,
      render: false,
      custom_auditConfig: {
        sortOrder: 1000.1
      }
    }, {
      name: 'exchangeCurrency',
      label: 'Moeda de Câmbio',
      updatable: false,
      render: false,
      custom_auditConfig: {
        sortOrder: 1000.09
      }
    }, {
      name: 'exchangeRate',
      label: 'Taxa de câmbio',
      type: 'float',
      required: false,
      updatable: false,
      render: false,
      hint: 'Taxa de câmbio aplicada à Moeda De para cálculo da Moeda Para',
      custom_auditConfig: {
        sortOrder: 1000.13
      }
    }],
    render: (entity, ignored) => {
      const printEntity = childEntity => {
        return Object.entries(childEntity).map(([key, value]) => {
          if (value && Array.isArray(value)) {
            return <div style={{
              display: 'flex',
              flexDirection: 'column',
              gap: '1px',
              paddingLeft: '2px',
              marginBottom: '5px'
            }}>
                  <div style={{
                display: 'flex',
                flexDirection: 'column',
                gap: '1px'
              }}>{printEntity(value)}</div>
                </div>;
          } else if (value && typeof value === 'object') {
            return <div style={{
              display: 'flex',
              flexDirection: 'column',
              gap: '1px',
              paddingLeft: '2px',
              marginBottom: '5px'
            }}>
                  <span>{key}:</span>
                  <div style={{
                display: 'flex',
                flexDirection: 'column',
                gap: '1px'
              }}>{printEntity(value)}</div>
                </div>;
          } else return <span>{`${key}: ${value}`}</span>;
        });
      };
      return printEntity(entity?.paidItems);
    }
  }, {
    name: 'orderId',
    label: 'Id da viagem',
    updatable: false,
    custom_auditConfig: {
      show: 'NEVER',
      sortOrder: 1
    }
  }, {
    custom_groupName: "Pagador",
    name: 'payer_name',
    label: 'Nome',
    type: 'text',
    updatable: false,
    custom_auditConfig: {
      sortOrder: 9
    }
  }, {
    custom_groupName: "Pagador",
    name: 'payer_email',
    label: 'Email',
    type: 'text',
    updatable: false,
    custom_auditConfig: {
      sortOrder: 10
    }
  }, {
    custom_groupName: "Pagador",
    name: 'payer_cpf',
    label: 'CPF',
    type: 'text',
    updatable: false,
    custom_auditConfig: {
      sortOrder: 11
    }
  }, {
    custom_groupName: "Pagador",
    name: 'payer_phone',
    label: 'Telefone',
    type: 'text',
    updatable: false,
    custom_auditConfig: {
      sortOrder: 12
    }
  }, {
    custom_groupName: "Pagador",
    name: 'payer_country',
    label: 'Pais',
    type: 'text',
    updatable: false,
    custom_auditConfig: {
      sortOrder: 21
    }
  }, {
    custom_groupName: "Pagador",
    name: 'payer_passport',
    label: 'Passaporte',
    type: 'text',
    updatable: false,
    custom_auditConfig: {
      sortOrder: 13
    }
  }, {
    custom_groupName: "Pagador",
    name: 'payer_birthday',
    label: 'Data de nascimento',
    type: 'date',
    updatable: false,
    custom_auditConfig: {
      sortOrder: 14
    }
  }, {
    custom_groupName: "Endereço do pagador",
    name: 'payer_addressZipCode',
    label: 'CEP',
    type: 'text',
    updatable: false,
    custom_auditConfig: {
      sortOrder: 23
    }
  }, {
    custom_groupName: "Endereço do pagador",
    name: 'payer_addressStreet',
    label: 'Rua',
    type: 'text',
    updatable: false,
    custom_auditConfig: {
      sortOrder: 15
    }
  }, {
    custom_groupName: "Endereço do pagador",
    name: 'payer_addressCountryCode',
    label: 'Codigo do país',
    type: 'text',
    updatable: false,
    custom_auditConfig: {
      sortOrder: 22
    }
  }, {
    custom_groupName: "Endereço do pagador",
    name: 'payer_addressDistrict',
    label: 'Bairro',
    type: 'text',
    updatable: false,
    custom_auditConfig: {
      sortOrder: 18
    }
  }, {
    custom_groupName: "Endereço do pagador",
    name: 'payer_addressCity',
    label: 'Cidade',
    type: 'text',
    updatable: false,
    custom_auditConfig: {
      sortOrder: 19
    }
  }, {
    custom_groupName: "Endereço do pagador",
    name: 'payer_addressState',
    label: 'Estado',
    type: 'text',
    updatable: false,
    custom_auditConfig: {
      sortOrder: 20
    }
  }, {
    custom_groupName: "Endereço do pagador",
    name: 'payer_addressNumber',
    label: 'Número',
    type: 'text',
    updatable: false,
    custom_auditConfig: {
      sortOrder: 16
    }
  }, {
    custom_groupName: "Endereço do pagador",
    name: 'payer_addressComplement',
    label: 'Complemento',
    type: 'text',
    updatable: false,
    custom_auditConfig: {
      sortOrder: 17
    }
  }, {
    name: 'manual',
    type: 'boolean',
    updatable: false,
    insertable: false
  }, {
    name: 'paymentCondition_description',
    label: 'Instruções da forma de pagamento',
    custom_auditConfig: {
      sortOrder: 29
    }
  }, {
    name: 'creditControl_clientPaymentProduct',
    label: 'Identificação usada para controle de crédito.',
    hint: 'Usado para o controle de crédito. Se diferente de null, pagamento precisa de baixa (Formato: {clientId}#{paymentType}#{productType})',
    updatable: false,
    render(entity, ignored) {
      if (entity.creditControl_clientPaymentProduct) return "Pendente de baixa";else return null;
    }
  }, {
    name: 'creditControl_enabled',
    label: 'Regra de controle de crédito habilitada para o pagamento',
    hint: 'Não quer dizer que conta ou não no limite - usar creditControl_clientAmount != null. Setado na criação do pagamento, pode ser lido nas atualizações do pagamento.',
    type: 'boolean',
    updatable: false
  }, {
    name: 'creditControl_confirmOutstandingPaymentDate',
    updatable: false,
    insertable: false,
    type: 'datetime'
  }, {
    name: 'purchaseSessionId',
    updatable: false,
    render: false
  }],
  searchParams: [{
    name: 'showValues',
    type: 'boolean'
  }],
  getParams: [{
    name: 'showValues',
    type: 'boolean'
  }],
  filter: [{
    field: 'productId',
    op: 'eq'
  }, {
    field: 'orderId',
    op: 'eq'
  }, {
    field: 'creditControl_clientPaymentProduct',
    op: 'beginsWith'
  }]
});