import { Box } from '@mui/material';
import { formatDate, formatNumber } from '@niaratech/niara-js-commons';
import { differenceInDays, format, isAfter, isSameDay, parseISO } from 'date-fns';
import React from 'react';
import { DateRange, type DateRangeProps } from 'react-date-range';
import { getLocaleDateFns } from '../../util/localeDateFns';
import { DetailCalendarDatePickerItems } from '../../HotelSearch/Criteria/HotelCriteria';
import ToolTip from '../ToolTip';
import * as S from './styles';
export type RenderDateProps = Pick<DateRangeProps, 'months' | 'ranges' | 'rangeColors' | 'focusedRange' | 'onChange' | 'onPreviewChange' | 'minDate' | 'maxDate' | 'disabledDates' | 'monthDisplayFormat' | 'onShownDateChange' | 'initialFocusedRange'> & S.OpenDateProps & {
  horizontal?: boolean;
  showMonthAndYearPickers?: boolean;
  showMonthArrow?: boolean;
  detailsToCalendar?: DetailCalendarDatePickerItems[];
  centerMode?: boolean;
  loading?: boolean;
  activeHover?: boolean;
  oneway?: boolean;
  referenceComponent?: React.MutableRefObject<any>;
};
const RenderDate: React.FC<RenderDateProps> = ({
  horizontal,
  months,
  ranges,
  rangeColors,
  focusedRange,
  onChange,
  onPreviewChange,
  minDate,
  maxDate,
  showMonthAndYearPickers,
  showMonthArrow,
  detailsToCalendar,
  disabledDates,
  monthDisplayFormat,
  onShownDateChange,
  initialFocusedRange,
  loading,
  activeHover,
  oneway,
  referenceComponent
}) => {
  const CustomDayContent = (date: Date) => {
    const day = format(date, 'd');
    const displayData = detailsToCalendar?.find(item => isSameDay(parseISO(item.date), date));
    const isUnavaliable = displayData && !displayData?.available;
    const diffDays = differenceInDays(date, ranges?.[0]?.startDate);
    const restrictions = displayData?.restrictions;
    const registerTypeInDate: 'Check out' | 'Check in' = date && ranges?.[0]?.endDate && isSameDay(date, ranges?.[0]?.endDate) ? 'Check out' : 'Check in';
    return <Box width="100%" height="100%" display="flex">
        {!displayData && <div className={`item-row`}>
            <span>{day}</span>
            {loading && (isAfter(date, minDate) || isSameDay(date, minDate)) && <div className="skeleton-component-render-date" />}
          </div>}
        {displayData && <ToolTip PopperProps={{
        disablePortal: true
      }} title={detailsToCalendar?.length > 0 && date && ranges?.[0]?.endDate && <S.TooltipContainer>
                  {!displayData?.available ? <p>Sem disponibilidade</p> : <Box width="100%">
                      {restrictions?.closedOnArrival === true && <p>Fechado para Check in</p>}
                      {restrictions?.closedOnDeparture === true && <p>Fechado para Check out</p>}
                      {restrictions?.minNightCount > 0 && <p>Mínimo de diárias {restrictions.minNightCount}</p>}
                      {restrictions?.maxNightCount > 0 && <p>Máximo de diárias {restrictions.maxNightCount}</p>}
                      {!activeHover && (registerTypeInDate === 'Check in' && !restrictions?.closedOnArrival || registerTypeInDate === 'Check out' && !restrictions?.closedOnDeparture) && <p>{registerTypeInDate}</p>}
                      {activeHover && <p>{diffDays > 0 && `${diffDays} ${diffDays > 1 ? "noites" : "noite"}`}</p>}
                    </Box>}
                </S.TooltipContainer>}>
            <div className={`item-row ${isUnavaliable ? 'unavaliable' : ''}`}>
              {restrictions?.minNightCount > 0 && <span className="min-nights">{restrictions.minNightCount}</span>}
              {isUnavaliable && <div className="unavaliable-icon">
                  <span>x</span>
                </div>}
              <span>{day}</span>
              <Box width="100%" display="flex" alignItems="baseline" justifyContent="center">
                {loading && displayData?.value == null && !isUnavaliable && (isAfter(date, minDate) || isSameDay(date, minDate)) ? <div className="skeleton-component-render-date" /> : displayData?.value != null && <span className="price">{formatNumber(displayData?.value, undefined, 2)}</span>}
              </Box>
            </div>
          </ToolTip>}
      </Box>;
  };
  const locale = getLocaleDateFns();
  return oneway ? <S.DateFormat>{ranges?.[0] && formatDate(ranges?.[0]?.startDate, undefined, 'd MMM yyyy')}</S.DateFormat> : <DateRange direction={horizontal ? 'horizontal' : 'vertical'} ranges={ranges} onChange={onChange} onPreviewChange={onPreviewChange} months={months} dayContentRenderer={CustomDayContent} shownDate={ranges?.[0]?.startDate ?? minDate} locale={locale} rangeColors={rangeColors} preventSnapRefocus={true} focusedRange={focusedRange} initialFocusedRange={initialFocusedRange} minDate={minDate} maxDate={maxDate} showMonthAndYearPickers={showMonthAndYearPickers} monthDisplayFormat={monthDisplayFormat}
  // linkedCalendars={true}
  showMonthArrow={showMonthArrow} weekStartsOn={0} disabledDates={disabledDates}
  // priceDay={priceDay}
  onShownDateChange={onShownDateChange} ref={referenceComponent} />;
};
export default RenderDate;