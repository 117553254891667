import fillCrudMetadataGaps from '../../fillCrudMetadataGaps';
import authorization from '../../util/authorization';
export default fillCrudMetadataGaps({
  label: 'Serviço',
  labelPlural: 'Serviços',
  name: 'ServiceSlim',
  deletable: false,
  updatable: false,
  insertable: false,
  searchable: true,
  authorization: authorization('clientConsultant', false),
  dynamoDB: {
    tableName: 'SpearServiceSlim',
    partitionKey: 'tenantIdClientId',
    indexName: 'tenantIdClientId-date-index',
    indexedProp: 'date',
    scanIndexBackwards: true,
    indexes: [{
      indexName: 'tenantIdClientId-cancelPolicy_dropDate-index',
      partitionKey: 'tenantIdClientId',
      indexedProp: 'cancelPolicy_dropDate',
      scanIndexBackwards: true
    }, {
      indexName: 'tenantIdClientId-cancelPolicy_penaltyDate-index',
      partitionKey: 'tenantIdClientId',
      indexedProp: 'cancelPolicy_penaltyDate',
      scanIndexBackwards: true
    }, {
      indexName: 'tenantIdClientId-startDate-index',
      partitionKey: 'tenantIdClientId',
      indexedProp: 'startDate',
      scanIndexBackwards: true
    }, {
      indexName: 'tenantIdClientId-cancelDate-index',
      partitionKey: 'tenantIdClientId',
      indexedProp: 'cancelDate',
      scanIndexBackwards: true
    }]
  },
  columnNames: ['id', 'orderId', 'locator', 'credentialAlias', 'startDate', 'date', 'clientName', 'credentialId', 'source', 'clientId', 'endDate', 'hotelName', 'status', 'importStatus', 'allGuests', 'hotelId', 'buyer_personId', 'idOnSource', 'user', 'pmsNumber', 'isManual', 'createdBy', 'responsible', 'responsibleName'],
  'custom:sortFields': ['date desc'],
  fields: [{
    label: 'Type#Id',
    name: 'typeId',
    type: 'text',
    id: true
  }, {
    label: 'Id',
    name: 'id',
    type: 'text'
  }, {
    label: 'Id',
    name: 'type',
    type: 'text'
  }, {
    name: 'hotelReservationId',
    label: 'ID da Reserva'
  }, {
    name: 'locator',
    label: 'Localizador'
  }, {
    name: 'orderId',
    label: 'Viagem'
  }, {
    name: 'order_description',
    label: 'Descrição da Viagem'
  }, {
    name: 'clientName',
    label: 'Nome do cliente'
  }, {
    name: 'clientId',
    label: 'Id do cliente'
  }, {
    name: 'credentialAlias',
    label: 'Credencial'
  }, {
    name: 'credentialId',
    label: 'Id da credencial'
  }, {
    name: 'date',
    label: 'Data da reserva',
    type: 'datetime'
  }, {
    name: 'endDate',
    label: 'Data de check-out',
    type: 'date'
  }, {
    name: 'startDate',
    label: 'Data de check-in',
    type: 'date'
  }, {
    name: 'hotelName',
    label: 'Hotel'
  }, {
    name: 'status',
    label: 'Situação'
  }, {
    name: 'cityName',
    label: 'Cidade'
  }, {
    name: 'importStatus',
    label: 'Situação Importação'
  }, {
    name: 'allGuests',
    label: 'Hóspedes',
    type: 'textarray'
  }, {
    name: 'source',
    label: 'Conteúdo'
  }, {
    name: 'hotelId',
    label: 'Id do hotel'
  }, {
    name: 'primaryGuest',
    label: 'Pax'
  }, {
    name: 'primaryGuest_personId',
    label: 'Pax - Person ID'
  }, {
    name: 'buyer_personId',
    label: 'Comprador - Person ID'
  }, {
    name: 'idOnSource',
    label: 'Localizador (conteúdo)'
  }, {
    name: 'cancelPolicy_dropDate',
    label: 'Data de cancelamento automático',
    type: 'datetime',
    hint: 'Já considera automaticCancellationEnabled'
  }, {
    name: 'cancelPolicy_penaltyDate',
    label: 'Data de penalidade',
    type: 'datetime'
  }, {
    name: 'cancelPolicy_penalty_value',
    label: 'Valor de Penalidade',
    type: 'float'
  }, {
    name: 'cancelPolicy_penalty_currency',
    label: 'Moeda'
  }, {
    name: 'cancelPolicy_total_value',
    label: 'Total',
    type: 'float'
  }, {
    name: 'cancelPolicy_total_currency',
    label: 'Moeda'
  }, {
    name: 'cancelPolicy_inPenalty',
    label: 'Em Penalidade?',
    type: 'boolean'
  }, {
    name: 'cancelPolicy_nonRefundable',
    label: 'Não reembolsável',
    type: 'boolean'
  }, {
    name: 'responsible',
    label: 'Responsável'
  }, {
    name: 'responsibleName',
    label: 'Nome do responsável'
  }, {
    name: 'hasUserComment',
    label: 'Tem comentário do viajante?',
    type: 'boolean'
  }, {
    name: 'updatedAt',
    label: 'Atualizado em'
  }, {
    name: 'price_total_value',
    label: 'Total',
    type: 'float'
  }, {
    name: 'price_total_currency',
    label: 'Moeda'
  }, {
    name: 'price_clientCommission_value',
    label: 'Comissão Cliente',
    type: 'float'
  }, {
    name: 'price_clientCommission_currency',
    label: 'Moeda'
  }, {
    name: 'price_commission_value',
    label: 'Comissão',
    type: 'float'
  }, {
    name: 'price_commission_currency',
    label: 'Moeda'
  }, {
    name: 'price_markup_value',
    label: 'Markup',
    type: 'float'
  }, {
    name: 'price_markup_currency',
    label: 'Moeda'
  }, {
    name: 'price_net_value',
    label: 'Valor NET',
    type: 'float'
  }, {
    name: 'price_net_currency',
    label: 'Moeda'
  }, {
    name: 'price_taxes_value',
    label: 'Taxas',
    type: 'float'
  }, {
    name: 'price_taxes_currency',
    label: 'Moeda'
  }, {
    name: 'total_received',
    label: 'Total Recebido',
    type: 'float'
  }, {
    name: 'createdBy',
    label: 'Criado por'
  }, {
    name: 'user',
    label: 'Usuário de Criação'
  }, {
    name: 'total_receivable',
    label: 'Total Recebível',
    type: 'float'
  }, {
    name: 'receive_payment_type',
    label: 'Forma de pagamento recebido',
    type: 'text',
    options: [{
      label: 'Cartão de Crédito',
      value: 'CREDITCARD'
    }, {
      label: 'Pontos',
      value: 'POINTS'
    }, {
      label: 'Pix',
      value: 'PIX'
    }, {
      label: 'Faturado',
      value: 'FAT'
    }, {
      label: 'Antecipado',
      value: 'ANT'
    }, {
      label: 'Pendente pagamento',
      value: 'LATER'
    }]
  }, {
    name: 'output_payment_type',
    label: 'Forma de pagamento fornecedor',
    type: 'text',
    options: [{
      label: 'Cartão de Crédito',
      value: 'CREDITCARD'
    }, {
      label: 'Pontos',
      value: 'POINTS'
    }, {
      label: 'Pix',
      value: 'PIX'
    }, {
      label: 'Faturado',
      value: 'FAT'
    }, {
      label: 'Antecipado',
      value: 'ANT'
    }, {
      label: 'Pendente pagamento',
      value: 'LATER'
    }]
  }, {
    name: 'isManual',
    label: 'É reserva manual',
    type: 'boolean'
  }, {
    name: 'pmsNumber',
    label: 'Código da reserva no PMS',
    type: 'text'
  }, {
    name: 'countryCode',
    label: 'Código do país'
  }, {
    name: 'cancelDate',
    label: 'Data de cancelamento',
    type: 'datetime'
  }, {
    name: 'cancelled',
    label: 'Cancelado?',
    type: 'boolean'
  }, {
    name: 'currencyCode',
    label: 'Moeda',
    type: 'text'
  }, {
    name: 'hideOnDashboard',
    label: 'Não mostrar no dashboard',
    type: 'boolean'
  }, {
    name: 'totalPointsSpent',
    label: 'Total de pontos gastos',
    type: 'float'
  }, {
    name: 'totalPointsPendingRefund',
    label: 'Total de pontos pendentes de estorno',
    type: 'float'
  }],
  filter: [{
    field: 'cancelPolicy_dropDate',
    op: 'ge'
  }, {
    field: 'cancelPolicy_dropDate',
    op: 'le'
  }, {
    field: 'date',
    op: 'ge'
  }, {
    field: 'date',
    op: 'le'
  }, {
    field: 'startDate',
    op: 'ge'
  }, {
    field: 'startDate',
    op: 'le'
  }, {
    field: 'endDate',
    op: 'ge'
  }, {
    field: 'endDate',
    op: 'le'
  }, {
    field: 'cancelPolicy_penaltyDate',
    op: 'ge'
  }, {
    field: 'cancelPolicy_penaltyDate',
    op: 'le'
  }, {
    field: 'cancelPolicy_inPenalty',
    op: 'eq'
  }, {
    field: 'status',
    op: 'eq'
  }, {
    field: 'locator',
    op: 'eq'
  }, {
    field: 'clientId',
    op: 'eq'
  }, {
    field: 'responsible',
    op: 'eq'
  }, {
    field: 'hotelName',
    op: 'eq'
  }, {
    field: 'source',
    op: 'eq'
  }, {
    field: 'orderId',
    op: 'eq'
  }, {
    field: 'createdBy',
    op: 'eq'
  }, {
    field: 'user',
    op: 'eq'
  }, {
    field: 'receive_payment_type',
    op: 'eq'
  }, {
    field: 'output_payment_type',
    op: 'eq'
  }, {
    field: 'isManual',
    op: 'ne'
  }, {
    field: 'isManual',
    op: 'eq'
  }, {
    field: 'importStatus',
    op: 'isNull'
  }, {
    field: 'importStatus',
    op: 'ne'
  }, {
    field: 'importStatus',
    op: 'eq'
  }, {
    field: 'countryCode',
    op: 'ne'
  }, {
    field: 'countryCode',
    op: 'eq'
  }, {
    field: 'countryCode',
    op: 'isNull'
  }, {
    field: 'hasUserComment',
    op: 'eq'
  }, {
    field: 'hasUserComment',
    op: 'ne'
  }, {
    field: 'cancelled',
    op: 'eq'
  }, {
    field: 'cancelDate',
    op: 'ge'
  }, {
    field: 'cancelDate',
    op: 'le'
  }, {
    field: 'total_received',
    op: 'eq'
  }, {
    field: 'total_received',
    op: 'gt'
  }, {
    field: 'hideOnDashboard',
    op: 'ne'
  }, {
    field: 'totalPointsPendingRefund',
    op: 'gt'
  }, {
    field: 'hotelId',
    op: 'in'
  }]
});