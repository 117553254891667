import { PartialField } from '@niaratech/devcase-crud/dist/fillCrudMetadataGaps';
import fillCrudMetadataGaps from '../../fillCrudMetadataGaps';
import authorization from '../../util/authorization';

// const f = [{name: 'firstName', label: 'Nome'}].map(r => ([{name: r.name+ 'Requested', type: 'boolean'}, {name: r.name+ 'Requested', type: 'boolean'}, ])).flat(1)

const CheckoutForm_Fields_Primary_Guest = (props?: any, propsField?: any) => {
  const fields = {
    graphQLType: 'CheckoutForm_Fields',
    graphQLInputType: 'CheckoutForm_FieldsInput',
    ...props,
    fields: [{
      name: 'firstNameRequested',
      label: 'Nome',
      type: 'boolean',
      custom_hasFieldRequired: true,
      ...propsField
    }, {
      name: 'lastNameRequested',
      label: 'Sobrenome',
      type: 'boolean',
      custom_hasFieldRequired: true,
      ...propsField
    }, {
      name: 'emailRequested',
      label: 'E-mail',
      type: 'boolean',
      custom_hasFieldRequired: true,
      ...propsField
    }, {
      name: 'phoneRequested',
      label: 'Celular',
      type: 'boolean',
      custom_hasFieldRequired: true,
      ...propsField
    }, {
      name: 'dobRequested',
      label: 'Data de nascimento',
      type: 'boolean',
      custom_hasFieldRequired: true,
      ...propsField
    }, {
      name: 'addressRequested',
      label: 'Endereço',
      type: 'boolean',
      custom_hasFieldRequired: true,
      ...propsField
    }, {
      name: 'nationalityCountryRequested',
      label: 'Nacionalidade',
      type: 'boolean',
      custom_hasFieldRequired: true,
      custom_groupName: 'Opções Avançadas',
      custom_showGroupName: true,
      ...propsField,
      hint: 'País de nascimento do hóspede'
    }, {
      name: 'documentRequested',
      label: 'Documento',
      type: 'boolean',
      custom_hasFieldRequired: true,
      ...propsField,
      hint: 'CPF'
    }, {
      name: 'passportRequested',
      label: 'Passaporte',
      type: 'boolean',
      custom_hasFieldRequired: true,
      ...propsField
    }, {
      name: 'addressZipCodeRequested',
      label: 'CEP',
      type: 'boolean',
      custom_hasFieldRequired: true,
      ...propsField
    }, {
      name: 'addressStreetRequested',
      label: 'Rua',
      type: 'boolean',
      custom_hasFieldRequired: true,
      ...propsField
    }, {
      name: 'addressDistrictRequested',
      label: 'Bairro',
      type: 'boolean',
      custom_hasFieldRequired: true,
      ...propsField
    }, {
      name: 'addressNumberRequested',
      label: 'Número',
      type: 'boolean',
      custom_hasFieldRequired: true,
      ...propsField
    }, {
      name: 'addressComplementRequested',
      label: 'Complemento',
      type: 'boolean',
      custom_hasFieldRequired: false,
      ...propsField
    }, {
      name: 'addressCityRequested',
      label: 'Cidade',
      type: 'boolean',
      custom_hasFieldRequired: true,
      ...propsField
    }, {
      name: 'addressStateRequested',
      label: 'Estado',
      type: 'boolean',
      custom_hasFieldRequired: true,
      ...propsField
    }, {
      name: 'countryRequested',
      label: 'País',
      type: 'boolean',
      custom_hasFieldRequired: true,
      custom_groupName: 'Opções Avançadas',
      ...propsField,
      hint: 'País do endereço'
    }].reduce((acc, field) => {
      acc.push(field);
      if (field.custom_hasFieldRequired) {
        acc.push({
          name: field.name.replace('Requested', 'Required'),
          label: field.label + ' (Obrigatório)',
          type: 'boolean',
          custom_fieldRequired: true,
          ...propsField
        });
      }
      return acc;
    }, ([] as Array<PartialField>))
  };
  return (fields as Partial<PartialField>);
};
const CheckoutForm_Documents = {
  type: 'object',
  graphQLType: 'CheckoutForm_Documents',
  fields: [{
    name: 'cpfRequested',
    label: 'CPF',
    type: 'boolean'
  }, {
    name: 'cpfRequired',
    label: 'CPF (Obrigatório)',
    type: 'boolean'
  }, {
    name: 'rgRequested',
    label: 'RG',
    type: 'boolean'
  }, {
    name: 'rgRequired',
    label: 'RG (Obrigatório)',
    type: 'boolean'
  }, {
    name: 'birthCertificateRequested',
    label: 'Certidão de Nascimento',
    type: 'boolean'
  }, {
    name: 'birthCertificateRequired',
    label: 'Certidão de Nascimento (Obrigatório)',
    type: 'boolean'
  }]
};
export default fillCrudMetadataGaps({
  label: 'Campos da reserva',
  labelPlural: 'Campos da reserva',
  deletable: false,
  name: 'CheckoutFormRule',
  namePlural: 'CheckoutFormRules',
  authorization: authorization('admin', 'admin'),
  custom_withAudit: true,
  dynamoDB: {
    tableName: 'SpearCheckoutFormRule'
  },
  columns: ['id', 'name', 'enabled'],
  fields: ([{
    label: 'Id',
    name: 'id',
    type: 'text',
    updatable: false,
    insertable: false,
    id: true,
    renderColumn: false
  }, {
    name: 'name',
    label: 'Título do formulário',
    description: 'Defina um título para identificação do seu formulário.',
    type: 'text',
    required: true,
    custom_groupName: 'Dados gerais da reserva'
  }, {
    name: 'enabled',
    label: 'Ativo',
    default: false,
    type: 'boolean',
    custom_groupName: 'Dados gerais da reserva'
  }, {
    name: 'primaryGuestAllowed',
    label: 'Hóspede principal',
    description: 'Identifica o hóspede responsável pela reserva.',
    default: true,
    type: 'boolean',
    custom_groupName: 'Dados gerais da reserva',
    custom_showGroupName: true
  }, {
    name: 'buyerAllowed',
    label: 'Estou a reservar para outra pessoa (solicitante)',
    description: 'Possibilita ao hóspede identificar outra pessoa como solicitante.',
    type: 'boolean',
    custom_groupName: 'Dados gerais da reserva'
  }, {
    name: 'payerAllowed',
    label: 'Dados do pagante diferentes',
    description: 'Disponibilizar opção de dados de faturação diferentes do hospede principal da reserva.',
    type: 'boolean',
    custom_groupName: 'Dados gerais da reserva'
  }, {
    name: 'responsibleRequested',
    label: 'Responsável da reserva',
    description: 'Possibilita definir o responsável pela reserva',
    type: 'boolean',
    custom_groupName: 'Dados gerais da reserva',
    default: false,
    custom_hasFieldRequired: true
  }, {
    name: 'commentRequested',
    label: 'Comentário',
    description: 'Possibilita o hóspede deixar um comentário direto ao hotel em sua reserva.',
    type: 'boolean',
    custom_groupName: 'Dados gerais da reserva',
    custom_hasFieldRequired: true
  }, {
    name: 'referenceRequested',
    label: 'Referência da reserva',
    description: 'Possibilita ao hóspede identificar o motivo de sua viagem.',
    type: 'boolean',
    custom_groupName: 'Dados gerais da reserva',
    custom_hasFieldRequired: true
  }, {
    name: 'travelReasonRequested',
    label: 'Motivo da viagem',
    type: 'boolean',
    custom_groupName: 'Dados gerais da reserva'
  }, {
    name: 'primaryGuestForm',
    type: 'object',
    ...CheckoutForm_Fields_Primary_Guest(),
    custom_groupName: 'Hóspede principal'
  }, {
    name: 'secondaryGuestsRequested',
    label: 'Solicitar dados de todos os hóspedes secundários',
    type: 'boolean',
    custom_groupName: 'Hóspede secundário'
  }, {
    name: 'secondaryGuestsSpecificRequested',
    label: 'Solicitar dados diferentes do hóspede principal',
    type: 'boolean',
    custom_groupName: 'Hóspede secundário'
  }, {
    name: 'secondaryGuestsForm',
    label: 'Formulário dos hóspedes secundários',
    type: 'object',
    ...CheckoutForm_Fields_Primary_Guest({}, {
      custom_dependency: 'secondaryGuestsSpecificRequested'
    }),
    custom_groupName: 'Hóspede secundário'
  }, {
    name: 'childrenRequested',
    label: 'Solicitar dados de todas as crianças da reserva',
    type: 'boolean',
    custom_groupName: 'Crianças'
  }, {
    name: 'childrenForm',
    label: 'Formulário das crianças',
    type: 'object',
    ...CheckoutForm_Fields_Primary_Guest({}, {
      custom_dependency: 'childrenRequested'
    }),
    custom_groupName: 'Crianças'
  }, {
    name: 'foreignRequested',
    label: 'Solicitar dados dos hóspedes estrangeiros da reserva',
    type: 'boolean',
    custom_groupName: 'Hóspede estrangeiro'
  }, {
    name: 'foreignForm',
    label: 'Formulário do estrangeiro',
    type: 'object',
    ...CheckoutForm_Fields_Primary_Guest({}, {
      custom_dependency: 'foreignRequested'
    }),
    custom_groupName: 'Hóspede estrangeiro'
  }, {
    name: 'buyerSpecificRequested',
    label: 'Solicitar dados diferentes do hóspede principal',
    type: 'boolean',
    custom_groupName: 'Dados do comprador'
  }, {
    name: 'buyerForm',
    label: 'Dados do comprador',
    type: 'object',
    ...CheckoutForm_Fields_Primary_Guest({}, {
      custom_dependency: 'buyerSpecificRequested'
    }),
    custom_groupName: 'Dados do comprador'
  }, {
    name: 'payerSpecificRequested',
    label: 'Solicitar dados diferentes do pagante',
    type: 'boolean',
    custom_groupName: 'Dados do pagador'
  }, {
    name: 'payerForm',
    label: 'Dados do pagador',
    type: 'object',
    ...CheckoutForm_Fields_Primary_Guest({}, {
      custom_dependency: 'payerSpecificRequested'
    }),
    custom_groupName: 'Dados do pagador'
  }, {
    name: 'quotationForm',
    label: 'Formulário do hóspede principal da cotação',
    type: 'object',
    default: {
      firstNameRequested: true,
      lastNameRequested: true,
      emailRequested: true,
      phoneRequested: true,
      firstNameRequired: true,
      lastNameRequired: true,
      emailRequired: true,
      phoneRequired: true
    },
    ...CheckoutForm_Fields_Primary_Guest(),
    custom_groupName: 'Cotação'
  }, {
    name: 'extraFieldDefinitions',
    label: 'Formulário de definição de campos extras',
    type: 'objectarray',
    custom_groupName: 'Campos extras',
    custom_renderFieldsRecursively: true,
    fields: [{
      name: 'label',
      label: 'Nome de exibição',
      type: 'text',
      required: true,
      hint: 'Nome curto que será exibido como rótulo do campo'
    }, {
      name: 'name',
      label: 'Nome do campo',
      type: 'text',
      readOnly: true,
      hint: 'Esse campo será preenchido automaticamente'
    }, {
      name: 'question',
      label: 'Pergunta',
      type: 'text',
      hint: 'Será exibido no lugar do campo "Nome de exibição" se preenchido'
    }, {
      name: 'hint',
      label: 'Dica',
      type: 'text',
      hint: 'Informação sobre o preenchimento do campo'
    }, {
      name: 'required',
      label: 'Obrigatório',
      type: 'boolean'
    }, {
      name: 'type',
      label: 'Tipo',
      type: 'text',
      default: 'text',
      required: true,
      options: [{
        value: 'text',
        label: 'Texto'
      }],
      renderForm: false
    }, {
      name: 'subtype',
      label: 'Subtipo',
      type: 'text',
      default: 'FREE',
      required: true,
      options: [{
        value: 'FREE',
        label: 'Texto livre'
      }, {
        value: 'OPTIONS',
        label: 'Lista de opções'
      }, {
        value: 'ADVANCED',
        label: 'Avançado'
      }]
    }, {
      name: 'options',
      label: 'Lista de opções',
      hint: 'Separar as opções por linha. Máximo de 500 opções.',
      type: 'text',
      inputType: 'textarea',
      renderForm: e => e.subtype === 'OPTIONS',
      conditionalRequired: e => e.subtype === 'OPTIONS'
    }, {
      name: 'inputType',
      label: 'Tipo de Entrada',
      type: 'text',
      default: 'select',
      options: [{
        value: 'select',
        label: 'Escolha de opções'
      }, {
        value: 'textfield',
        label: 'Usuário digita a opção'
      }],
      renderForm: e => e.subtype === 'OPTIONS',
      conditionalRequired: e => e.subtype === 'OPTIONS'
    }, {
      name: 'maxLength',
      label: 'Tamanho máximo',
      hint: 'Quantidade de caracteres máxima permitida',
      type: 'int',
      default: 100,
      renderForm: e => e.subtype === 'FREE' || e.subtype === 'ADVANCED'
    }, {
      name: 'minLength',
      label: 'Tamanho mínimo',
      hint: 'Quantidade de caracteres mínima permitida',
      type: 'int',
      renderForm: e => e.subtype === 'ADVANCED'
    }, {
      name: 'uppercase',
      label: 'Apenas letra maiúscula',
      type: 'boolean',
      renderForm: e => e.subtype === 'ADVANCED'
    }, {
      name: 'lowercase',
      label: 'Apenas letra minúscula',
      type: 'boolean',
      renderForm: e => e.subtype === 'ADVANCED'
    }, {
      name: 'pattern',
      label: 'Padrão',
      hint: 'Expressão regular de validação do campo (RegEx)',
      type: 'text',
      renderForm: e => e.subtype === 'ADVANCED'
    }]
  }, {
    name: 'userPersonalDataReadonly',
    label: 'Fixar dados de usuário logado',
    custom_groupName: 'Dados gerais da reserva',
    description: 'Fixa os dados do usuário logado no formulário do checkout, impedindo que o hóspede altere.',
    type: 'boolean'
  }, {
    name: 'updatedAt',
    type: 'datetime',
    label: 'Última atualização',
    updatable: false,
    insertable: false
  }, {
    name: 'updatedByName',
    type: 'text',
    label: 'Atualizado por',
    updatable: false,
    insertable: false
  }] as Array<PartialField>).reduce((acc, field) => {
    acc.push(field);
    if (field.custom_hasFieldRequired) {
      acc.push({
        name: field.name.replace('Requested', 'Required'),
        label: field.label + ' (Obrigatório)',
        type: 'boolean',
        custom_fieldRequired: true
      });
    }
    return acc;
  }, ([] as Array<PartialField>)).map(field => {
    if (field?.type === 'object') {
      return {
        ...field,
        render: entity => {
          return entity[field?.name] != null ? Object.entries(entity[field?.name]).map(([key, value]) => {
            const label = field?.fields?.find(f => f.name == key)?.label;
            return `${label}:\xa0${value == true ? "Sim" : "Não"}`;
          }).join('\n') : '-';
        }
      };
    }
    return field;
  }),
  filter: []
});