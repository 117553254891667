import type { Filter, FilterKeys, HotelResult, HotelResultFilterKey, OrderHotelsBy, RoomRate, RoomRateFilterKey } from '@niarab2c/frontend-commons/src/types/hotels';
import { credentialTypesOptions } from '@niarab2c/niara-spear-crudmodel/src/crudmodel/credential';
import { formatNumber } from '@niaratech/niara-js-commons';
import shallowEqual from 'fbjs/lib/shallowEqual';
import _intersection from 'lodash/intersection';
import _uniq from 'lodash/uniq';
import matchSorter from 'match-sorter';
import { Services } from '../../../../ota-components/src/constants/amenities';
import { FILTER_KEYS, FILTER_TITLES } from '../constants';
import normalizeCityName from '../normalizeCityName';
import normalizeMealName from '../normalizeMealName';
type GenericFilter<T> = {
  (t: T): boolean;
};
type RoomRateFilter = {
  (roomRate: RoomRate): boolean;
};
type HotelResultFilter = {
  (hotelResult: HotelResult): boolean;
};
const NOOP_FILTER = v => true;
function filterFunction<T>(filters: GenericFilter<T>[]): GenericFilter<T> {
  if (!filters || filters.length === 0) return NOOP_FILTER;
  return v => {
    for (let i = 0; i < filters.length; i++) {
      if (!filters[i](v)) return false;
    }
    return true;
  };
}

/**
 * definição dos filtros
 */
export const MultiOptionRoomRateFilters: Record<RoomRateFilterKey, {
  (h: RoomRate): Array<string | number | boolean>;
}> = ({
  cancelPolicy: (r: RoomRate) => [r._cancelPolicy],
  paymentType: (r: RoomRate) => r._acceptedPayments?.filter(p => PAYMENT_TYPES_VALID_CODES.indexOf(p) >= 0),
  rateType: (r: RoomRate) => r._rateType,
  meal: (r: RoomRate): string[] => r._meal ? [r._meal?.toLowerCase()] : [],
  breakfast: (r: RoomRate): boolean[] => [r.meal && r.meal.breakfast || false],
  remuneration: (rr: RoomRate) => {
    const {
      percentage
    } = rr._remuneration || {};
    return [Math.round((percentage || 0) * 100) / 100];
  },
  roomType: (r: RoomRate) => [r.roomType.name],
  credentialType: (r: RoomRate) => r?.credential?.type ? [r.credential.type] : [],
  outputPaymentType: (r: RoomRate) => r._outputPaymentTypes?.filter(p => PAYMENT_TYPES_VALID_CODES.indexOf(p) >= 0),
  ratePlanIsPublic: (r: RoomRate) => [r.ratePlan.public ? 'Pública' : 'Acordo']
} as const);
const HOTEL_AMENITIES = Services.map(a => a.service).flat(1);
const HOTEL_AMENITIES_CODE = HOTEL_AMENITIES.map(r => r.code);
const checkCityNameWithAccent = (cityName: string) => {
  const notHasAccents = /^[A-Z\s]+$/i.test(cityName);
  if (notHasAccents) {
    return [cityName];
  } else {
    return [cityName, cityName?.normalize('NFD')?.replace(/[\u0300-\u036f]/g, '')];
  }
};
export const MultiOptionHotelResultFilters: Record<HotelResultFilterKey, {
  (h: HotelResult): Array<string | number | boolean>;
}> = ({
  award: (r: HotelResult) => [r.hotel.award],
  hotelId: (r: HotelResult) => r.hotel.soupIds || [r.hotel.id],
  cityName: (r: HotelResult) => _uniq([r.hotel?.cityName, r._hotelDetails?.address?.cityName].filter(Boolean).map(normalizeCityName).map(checkCityNameWithAccent).flat(1)),
  rawDistance: (r: HotelResult) => r._rawDistance == null ? [] : r._rawDistance >= 20000 ? [9999] : [Math.floor(Math.sqrt(r._rawDistance / 2) / 20)],
  negotiatedRateFlags: (r: HotelResult) => [r._acceptsPets && 'acceptsPets', r._offersTransfer && 'offersTransfer'].filter(Boolean),
  hotelAmenities: (r: HotelResult) => _intersection(r?._hotelDetails?.amenities?.map(a => a.code), HOTEL_AMENITIES_CODE),
  metasearchPercentDiscount: (r: HotelResult): string[] => {
    const roomRate: RoomRate = r?._roomRates?.[0] ?? r?._bestPriceRoomRate;
    const {
      metasearch
    } = roomRate || ({} as typeof roomRate);
    const hasMasterComparison = metasearch?.slots?.find(slot => slot.sourceType == 'MASTER_CREDENTIAL') != null;
    if (!hasMasterComparison) return [];
    const percentage = metasearch?.slots?.find(slot => slot?.comparison?.percentage != null)?.comparison?.percentage;
    if (!percentage) return [];
    const percentageValue = (1 - percentage) * 100;
    // Como na ordenação os valores são arredondados, colocamos os valores aproximados para que os arredondamentos apereçam no filtro
    // Veja sortResults em hotelsPagination
    if (percentageValue > 14.5) {
      return ['>5', '>10', '>15'];
    } else if (percentageValue > 9.5) {
      return ['>5', '>10'];
    } else if (percentageValue > 4.5) {
      return ['>5'];
    } else {
      return [];
    }
  }
} as const);
const PAYMENT_TYPES = [{
  code: 'DIR',
  label: 'Pagamento direto',
  abrev: 'dir'
}, {
  code: 'DIR_IATA',
  label: 'Pagamento direto IATA',
  abrev: 'iata'
}, {
  code: 'OFF_CC',
  label: 'Cartão de crédito',
  abrev: 'CC'
}, {
  code: 'ON_CC',
  label: 'Cartão de crédito',
  abrev: 'CC'
}, {
  code: 'OFF_ANT',
  label: 'Antecipado',
  abrev: 'ant'
}, {
  code: 'OFF_FAT',
  label: 'Faturado',
  abrev: 'fat'
}, {
  code: 'LATER',
  label: 'Pagar Depois',
  abrev: 'pos'
}, {
  code: 'ON_PIX',
  label: 'PIX',
  abrev: 'PIX'
}];
const PAYMENT_TYPES_VALID_CODES = PAYMENT_TYPES.map(r => r.code);
export const LabelForValue: Partial<Record<FilterKeys, {
  (h: any): string;
}>> = ({
  cancelPolicy: v => v === 'NON_REFUNDABLE' ? "Não reembolsáveis" : v === 'IN_PENALTY' ? "Cancelamento com multa" : v === 'REFUNDABLE' ? "Cancelamento grátis" : v,
  rawDistance: v => v === 9999 ? "> 20 km" : v === 0 ? `> ${formatNumber(Math.pow(20, 2) / 500)} km` : `${formatNumber(Math.pow(20 * v, 2) / 500)} - ${formatNumber(Math.pow(20 * (v + 1), 2) / 500)} km`,
  meal: v => v === null ? "Sem refeição inclusa" : normalizeMealName(v),
  paymentType: v => PAYMENT_TYPES.filter(x => x.code == v)[0]?.label ?? v,
  hotelAmenities: v => HOTEL_AMENITIES?.find(b => b.code == v)?.description ?? v,
  breakfast: v => v ? "Café da manhã" : "Sem café da manhã",
  metasearchPercentDiscount: v => `${v[0] === '>' ? "A partir de" : "Abaixo de"} ${v.replace(/\D/g, '')}%`,
  rateType: v => v === 'NET' ? "Tarifa NET" : v === 'MARKUP' ? "Markup" : v === 'COMM' ? "Comissionada" : v,
  ratePlanIsPublic: v => {
    return v === 'Pública' ? "Público" : "Acordo";
  },
  credentialType: v => credentialTypesOptions.find(ct => ct.value === v)?.label ?? v,
  outputPaymentType: v => PAYMENT_TYPES.filter(x => x.code == v)[0]?.label ?? v
} as const);

/**
 * Gera filtro combinado de Room Rates a partir dos critérios em filter
 */
function roomFilter(filter: Filter): RoomRateFilter {
  const filters: Array<RoomRateFilter> = [];

  // se colocado no filtro um promocode, só mostrar roomRates com o promocode informado
  if (filter?.promoCode?.length > 0) {
    filters.push(roomRate => {
      return roomRate.promotion?.code == filter.promoCode;
    });
  }
  if (filter.priceMin != null && filter.priceMin > 0) {
    filters.push(v => {
      const room = v.priceComposition.nights.find(night => night.value > 0 && night.value >= filter.priceMin);
      return room != null;
    });
  }
  if (filter.priceMax && filter.priceMax > 0) {
    filters.push(v => {
      const room = v.priceComposition.nights.find(night => night.value > 0 && night.value <= filter.priceMax);
      return room != null;
    });
  }

  // Comissão do fornecedor
  if (filter.commissionMin != null) {
    filters.push(v => v.priceComposition.commission?.percentage != null ? Math.round(v.priceComposition.commission.percentage * 1000) >= Math.round(filter.commissionMin * 1000) : false);
  }
  if (filter.commissionMax != null) {
    filters.push(v => v.priceComposition.commission?.percentage != null ? Math.round(v.priceComposition.commission.percentage * 1000) <= Math.round(filter.commissionMax * 1000) : false);
  }

  // Comissão do cliente
  if (filter.clientCommissionMin != null) {
    filters.push(v => v.priceComposition.clientCommission?.percentage != null ? Math.round(v.priceComposition.clientCommission.percentage * 1000) >= Math.round(filter.clientCommissionMin * 1000) : false);
  }
  if (filter.clientCommissionMax != null) {
    filters.push(v => v.priceComposition.clientCommission?.percentage != null ? Math.round(v.priceComposition.clientCommission.percentage * 1000) <= Math.round(filter.clientCommissionMax * 1000) : false);
  }
  Object.keys(MultiOptionRoomRateFilters).map(key => {
    if (filter[key] && filter[key].length > 0) {
      filters.push(v => filter[key].find(p => MultiOptionRoomRateFilters[key](v).indexOf(p) >= 0) !== undefined);
    }
  });
  if (filter.clientCredentialsOnly) {
    filters.push(v => v.credential.clientId !== null);
  }
  if (filter.hideNonrefundable) {
    filters.push(v => v._cancelPolicy !== 'NON_REFUNDABLE');
  }
  return filterFunction(filters);
}

/**
 * Retorna função que filtra HotelResult de acordo com os valores em filter
 * @param filter
 * @returns
 */
function hotelResultFilter(filter: Filter): HotelResultFilter {
  const filters = [];
  Object.keys(MultiOptionHotelResultFilters).map((key: HotelResultFilterKey) => {
    if (filter?.[key]?.length > 0) {
      filters.push(
      // retorna true se existe intersecção entre o que está no array do valor que está em filter
      // com o valor retornado na função definida em MultiOptionHotelResultFilters[key]
      // ex: procura intersecção entre
      // filter.cityName = ["Santos", "Rio de Janeiro"]
      // e MultiOptionHotelResultFilters.cityName(v) = ["Santos"]
      (v: HotelResult) => filter[key].find(p => MultiOptionHotelResultFilters?.[key]?.(v)?.indexOf?.(p) >= 0) !== undefined);
    }
  });
  return filterFunction(filters);
}

/**
 * Dado uma lista de todos os resultados e um filtro, retorna a lista dos
 * resultados que obedece ao filtro
 * @param results
 * @param filter
 * @returns
 */
function filterResults(results: HotelResult[], filter: Filter): HotelResult[] {
  if (!filter) {
    return results?.map(r => r._roomRates ? {
      ...r,
      _roomRates: undefined
    } : r);
  }

  // função que identifica os room rates que obedecem ao filtro
  const _roomFilter = roomFilter(filter);

  // filtra os room rates de cada hotel result de acordo com o filtro e joga em _roomRates
  if (_roomFilter !== NOOP_FILTER) {
    results = results.map(r => {
      let newResult = {
        ...r
      };
      const _roomRates = newResult.roomRates?.filter(_roomFilter) || [];
      const nights = newResult?._bestPriceRoomRate?.priceComposition?.nights;
      newResult._bestPriceNight = filter?.priceMin && filter?.priceMin > 0 ? nights?.find(night => night?.value > 0 && night?.value >= filter?.priceMin)?.value || 0 : nights?.[0]?.value;
      if (!shallowEqual(_roomRates, newResult._roomRates)) {
        newResult = {
          ...newResult,
          _roomRates
        };
      }
      return newResult;
    });
    results = results.filter(r => r._roomRates?.length > 0);
  } else {
    results = results?.map(r => r._roomRates ? {
      ...r,
      _roomRates: undefined
    } : r);
  }

  // função que identifica os hotelresults que obedecem ao filtro
  const _hotelFilter = hotelResultFilter(filter);
  if (_hotelFilter !== NOOP_FILTER) {
    // executa o filtro
    results = results.filter(_hotelFilter);
  }

  // filtro especial por nome
  if (filter.name && filter.name.length > 0) {
    results = matchSorter(results, filter.name, {
      keys: ['hotel.name']
    });
  }
  if (filter?.metasearchPercentDiscount?.length > 0) {
    results = results.filter(hotelResult => {
      const roomRate: RoomRate = hotelResult?._roomRates?.[0] ?? hotelResult?._bestPriceRoomRate;
      const {
        metasearch
      } = roomRate || ({} as typeof roomRate);
      const percentage = metasearch?.slots?.find(slot => slot?.comparison?.percentage != null)?.comparison?.percentage;
      const percentageValue = isNaN(percentage) ? 0 : percentage * 100 ?? 0;
      return filter.metasearchPercentDiscount.some(discount => {
        if (discount[0] === '<') {
          return percentageValue < +discount.replace(/\D/g, '');
        }
        if (discount[0] === '>') {
          return percentageValue > +discount.replace(/\D/g, '');
        }
        return false;
      });
    });
  }
  return results;
}
export { filterResults, hotelResultFilter, roomFilter };
export default filterResults;
type FilterOption = {
  title: string;
  filterKey: string;
  filterOptions: Array<{
    label: string;
    value: string;
    total: number;
  }>;
  hide?: boolean;
  isSearchable?: boolean;
  enableCollapse?: boolean;
};
export type AvailableFilterOptions = FilterOption[];
type SortOption = {
  value: OrderHotelsBy;
  label: string;
};
export type AvailableSortOptions = SortOption[];
const EMPTY_ARRAY = [];

/**
 * Varre todos os resultados e monta objeto com os possíveis valores para cada filtro
 * e a quantidade de resultados para cada filtro. Ex: vai montar a lista de todas as
 * cidades e a quantidade de resultados para cada cidade.
 * @param state
 * @param results
 * @returns
 */
export const buildAvailableFilterOptions = (state: AvailableFilterOptions = [], results: HotelResult[]): AvailableFilterOptions => {
  if (results?.length > 0) {
    const availableFilterOptions = {};
    state?.forEach(filterOption => {
      availableFilterOptions[filterOption?.filterKey] = filterOption;
    });
    Object.keys(MultiOptionRoomRateFilters).forEach(key => {
      availableFilterOptions[key] = availableFilterOptions[key] || {
        filterKey: key,
        filterOptions: []
      };
      const retrieveValues = MultiOptionRoomRateFilters[key];
      results.forEach(r => r.roomRates && r.roomRates.forEach(roomRate => {
        return (retrieveValues(roomRate) || EMPTY_ARRAY).forEach(option => {
          const filterOption = availableFilterOptions[key]?.filterOptions?.find(({
            value
          }) => value === option);
          if (!filterOption) {
            availableFilterOptions[key]?.filterOptions.push({
              label: LabelForValue[key] ? LabelForValue[key](option) : (option as string),
              value: option,
              total: 1
            });
          } else {
            filterOption.total += 1;
          }
        });
      }));
    });
    Object.keys(MultiOptionHotelResultFilters).forEach(key => {
      availableFilterOptions[key] = availableFilterOptions[key] || {
        filterKey: key,
        filterOptions: []
      };
      const retrieveValues = MultiOptionHotelResultFilters[key];
      results.forEach(r => (retrieveValues(r) || EMPTY_ARRAY).forEach(option => {
        const filterOption = availableFilterOptions[key]?.filterOptions?.find(({
          value
        }) => value === option);
        if (!filterOption) {
          availableFilterOptions[key]?.filterOptions.push({
            label: LabelForValue[key] ? LabelForValue[key](option) : (option as string),
            value: option,
            total: 1
          });
        } else {
          filterOption.total += 1;
        }
      }));
    });
    const availableFilterOptionsUnifiqued = Object.keys(availableFilterOptions)?.filter(key => FILTER_KEYS[key]).map(filterOptionKey => ({
      ...availableFilterOptions[filterOptionKey],
      filterOptions: (availableFilterOptions[filterOptionKey]?.filterOptions || [])?.reduce((acc, curr) => {
        let itemFound = acc.find(item => item.label === curr.label);
        if (!itemFound) {
          itemFound = {
            label: curr.label,
            value: curr.value,
            total: 0
          };
          acc.push(itemFound);
        }
        itemFound.total += curr.total;
        return acc;
      }, [])
    }));
    const newState = availableFilterOptionsUnifiqued.map(option => {
      return {
        ...option,
        title: FILTER_TITLES[option?.filterKey] || option?.filterKey,
        hide: option?.filterOptions?.length <= 1 || !FILTER_TITLES[option?.filterKey],
        isSearchable: false && option?.filterOptions?.length >= 15,
        enableCollapse: option?.filterOptions?.length > 10
      };
    });
    return newState;
  }
  return state;
};
export const buildAvailableSortOptions = (state: AvailableSortOptions = [], results: HotelResult[]): AvailableSortOptions => {
  if (results?.length > 0) {
    const addMetaDiscountPercent = results.some(r => {
      const roomRate: RoomRate = r?._roomRates?.[0] ?? r?._bestPriceRoomRate;
      const {
        metasearch
      } = roomRate || ({} as typeof roomRate);
      const hasMasterComparison = metasearch?.slots?.find(slot => slot.sourceType == 'MASTER_CREDENTIAL') != null;
      return hasMasterComparison;
    });
    const discountOption: SortOption = {
      value: 'METASEARCH_DISCOUNT_PERCENT',
      label: 'Maior desconto'
    };
    const newState: SortOption[] = [{
      value: 'MIN_PRICE_VALUE',
      label: 'Menor preço'
    }, ...(addMetaDiscountPercent ? [discountOption] : [])];
    return newState;
  }
  return state;
};