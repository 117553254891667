import type { IdentityProvider } from '..';
import fillCrudMetadataGaps from '../../fillCrudMetadataGaps';
import authorization from '../../util/authorization';
export default fillCrudMetadataGaps<IdentityProvider>({
  label: 'Provedor de login',
  labelPlural: 'Provedores de login',
  name: 'IdentityProvider',
  namePlural: 'IdentityProviders',
  deletable: false,
  authorization: authorization('consultant', 'master'),
  custom_withAudit: true,
  dynamoDB: {
    tableName: 'SpearIdentityProvider'
  },
  columns: ['id', 'name', 'usage', 'enabled'],
  fields: [{
    label: 'Id',
    name: 'id',
    type: 'text',
    updatable: false,
    insertable: false,
    id: true,
    renderColumn: false
  }, {
    name: 'name',
    label: 'Nome do provedor',
    hint: 'Escolha um nome para facilitar a identificação deste provedor de identidade',
    required: true
  }, {
    name: 'enabled',
    label: 'Ativo',
    default: true,
    type: 'boolean'
  }, {
    name: 'usage',
    label: 'Uso do login',
    updatable: false,
    required: true,
    options: [{
      label: 'B2C',
      value: 'B2C',
      hint: 'Motor Niara / OTA Builder, cotação, link de pagamento'
    }, {
      label: 'Usuário da Instalação',
      value: 'FRONT',
      hint: 'Front e Central de Reservas'
    }, {
      label: 'Usuário de Cliente da Instalação',
      value: 'FRONT_CLIENT',
      hint: 'Agências e empresas'
    }],
    onEntityChange: (oldEntity, newEntity) => {
      if (oldEntity.usage != newEntity.usage) {
        if (newEntity.usage != 'B2C') {
          newEntity['type'] = 'OAUTH2';
          newEntity['subtype'] = null;
        }
        if (newEntity.usage != 'FRONT_CLIENT') {
          newEntity['clientId'] = null;
          newEntity['clientType'] = null;
          newEntity['clientTokenIdentifier'] = null;
        }
        return {
          ...newEntity
        };
      }
      return newEntity;
    }
  }, {
    name: 'clientType',
    label: 'Tipo de Cliente',
    options: [{
      label: 'Cliente Estático',
      value: 'STATIC_CLIENT',
      hint: 'Associação fixa a um cliente da instalação'
    }, {
      label: 'Cliente Dinamico',
      value: 'DYNAMIC_CLIENT',
      hint: 'Associação dinâmica de clientes (JWT Token)'
    }],
    onEntityChange: (oldEntity, newEntity) => {
      if (oldEntity.clientType != newEntity.clientType) {
        if (newEntity.clientType != 'STATIC_CLIENT') {
          newEntity['clientId'] = null;
        }
        if (newEntity.clientType != 'DYNAMIC_CLIENT') {
          newEntity['clientTokenIdentifier'] = null;
        }
        return {
          ...newEntity
        };
      }
      return newEntity;
    },
    renderForm: function (i) {
      return i.usage == 'FRONT_CLIENT' ? this.render(i) : false;
    }
  }, {
    name: 'clientId',
    label: 'Cliente',
    inputType: 'inputClient',
    renderForm: function (i) {
      return i.clientType == 'STATIC_CLIENT' ? this.render(i) : false;
    }
  }, {
    name: 'clientTokenClaim',
    label: 'Claim no Token',
    renderForm: i => i.clientType == 'DYNAMIC_CLIENT'
  }, {
    name: 'clientCorporateNameTokenClaim',
    label: 'Nome da empresa (Claim no Token)',
    renderForm: i => i.clientType == 'DYNAMIC_CLIENT'
  }, {
    name: 'clientBrandNameTokenClaim',
    label: 'Nome fantasia (Claim no Token)',
    renderForm: i => i.clientType == 'DYNAMIC_CLIENT'
  }, {
    name: 'clientCountryCodeTokenClaim',
    label: 'País (Claim no Token)',
    renderForm: i => i.clientType == 'DYNAMIC_CLIENT'
  }, {
    name: 'clientVatTokenClaim',
    label: 'CNPJ/VAT (Claim no Token)',
    renderForm: i => i.clientType == 'DYNAMIC_CLIENT'
  }, {
    name: 'clientExternalCodeTokenClaim',
    label: 'Código Externo (Claim no Token)',
    renderForm: i => i.clientType == 'DYNAMIC_CLIENT'
  }, {
    name: 'type',
    updatable: false,
    required: true,
    label: 'Tipo de login',
    options: [{
      label: 'OAuth2',
      value: 'OAUTH2'
    }, {
      label: 'User/Password',
      value: 'USER_PASSWORD'
    },
    // Dúvida: era apenas Allpoints?
    // {
    //   label: 'Token',
    //   value: 'TOKEN',
    // },
    {
      label: 'Conta Niara',
      value: 'NIARA_ACCOUNT'
    }],
    onEntityChange: (oldEntity, newEntity) => {
      if (oldEntity.type != newEntity.type) {
        newEntity['subtype'] = null;
        return {
          ...newEntity
        };
      }
      return newEntity;
    },
    renderForm: function (i) {
      return i.usage == 'B2C' ? this.render(i) : false;
    }
  }, {
    name: 'subtype',
    updatable: false,
    label: 'Tipo de integração',
    renderForm: function (i) {
      return i.type == 'USER_PASSWORD' ? this.render(i) : false;
    },
    options: [{
      label: 'Fidelimax',
      value: 'FIDELIMAX'
    }, {
      label: 'eSolution',
      value: 'ESOLUTION'
    }]
  }, {
    name: 'userInputType',
    updatable: false,
    label: 'Tipo de input de usuário',
    renderForm: function (i) {
      return i.type == 'USER_PASSWORD' ? this.render(i) : false;
    },
    options: [{
      label: 'CPF',
      value: 'CPF'
    }, {
      label: 'Username',
      value: 'USERNAME'
    }]
  }, {
    name: 'credentialId',
    label: 'Credencial',
    inputType: 'credential',
    credentialTypeList: ['FDMX', 'ESLT'],
    renderForm: function (i) {
      return ['FIDELIMAX', 'ESOLUTION'].includes(i.subtype) ? this.render(i) : false;
    }
  }, {
    name: 'signUpUrl',
    label: 'URL de registro de novo usuário',
    inputType: 'url',
    pattern: `^https:\\/\\/[-a-zA-Z0-9@:%._\\+~#=]{1,256}\\.[a-zA-Z0-9()]{1,6}\\b([-a-zA-Z0-9()@:%_\\+.~#?&//=]*)`,
    renderForm: function (i) {
      return i.usage == 'B2C' ? this.render(i) : false;
    }
  }, {
    name: 'signUpEnabled',
    label: 'Habilitar cadastro de pessoas?',
    type: 'boolean',
    renderForm: function (i) {
      return i.usage == 'B2C' && i.type == 'NIARA_ACCOUNT' ? this.render(i) : false;
    }
  }, {
    name: 'signUpSuccessTitle',
    label: 'Título da mensagem de sucesso do cadastro de pessoas',
    hint: 'Se não preenchido, utilizará o título padrão: Registro efetuado',
    type: 'text',
    renderForm: function (i) {
      return i.usage == 'B2C' && i.type == 'NIARA_ACCOUNT' && i.signUpEnabled ? this.render(i) : false;
    }
  }, {
    name: 'signUpSuccessMessage',
    label: 'Mensagem de sucesso do cadastro de pessoas',
    hint: 'Se não preenchida, utilizará a mensagem padrão: Seu registro foi realizado',
    type: 'text',
    renderForm: function (i) {
      return i.usage == 'B2C' && i.type == 'NIARA_ACCOUNT' && i.signUpEnabled ? this.render(i) : false;
    }
  }, {
    name: 'passwordRecoveryUrl',
    label: 'URL de recuperação de senha',
    inputType: 'url',
    pattern: `^https:\\/\\/[-a-zA-Z0-9@:%._\\+~#=]{1,256}\\.[a-zA-Z0-9()]{1,6}\\b([-a-zA-Z0-9()@:%_\\+.~#?&//=]*)`,
    renderForm: function (i) {
      return i.usage == 'B2C' && i.type != 'NIARA_ACCOUNT' ? this.render(i) : false;
    }
  }, {
    name: 'oauthClientId',
    label: 'Client ID',
    groupName: 'OAuth',
    renderForm: i => i.type == 'OAUTH2'
  }, {
    name: 'oauthClientSecret',
    label: 'Client Secret',
    groupName: 'OAuth',
    inputType: 'password',
    renderForm: i => i.type == 'OAUTH2',
    render: e => e.oauthClientSecret?.substring(0, 4).concat('*'.repeat(e?.oauthClientSecret?.length - 4))
  }, {
    name: 'oauthAuthorizedScopes',
    label: 'Escopos solicitados',
    groupName: 'OAuth',
    hint: 'Escolha os escopos OAuth2.0 que serão requisitados ao provedor de identidade',
    default: ['openid', 'profile', 'email'],
    type: 'textarray',
    renderForm: i => i.type == 'OAUTH2'
  }, {
    name: 'oauthTokenEndpoint',
    label: 'Endpoint para token',
    groupName: 'OAuth',
    inputType: 'url',
    pattern: `^https:\\/\\/[-a-zA-Z0-9@:%._\\+~#=]{1,256}\\.[a-zA-Z0-9()]{1,6}\\b([-a-zA-Z0-9()@:%_\\+.~#?&//=]*)`,
    renderForm: i => i.type == 'OAUTH2'
  }, {
    name: 'oauthAuthorizationEndpoint',
    label: 'Endpoint de autorização',
    groupName: 'OAuth',
    inputType: 'url',
    pattern: `^https:\\/\\/[-a-zA-Z0-9@:%._\\+~#=]{1,256}\\.[a-zA-Z0-9()]{1,6}\\b([-a-zA-Z0-9()@:%_\\+.~#?&//=]*)`,
    renderForm: i => i.type == 'OAUTH2'
  },
  // {
  //   name: 'oauthUserInfoEndpoint',
  //   label: 'Endpoint para recuperação de usuário (/me)',
  //   groupName: 'OAuth',
  //   inputType: 'url',
  //   pattern: `^https:\\/\\/[-a-zA-Z0-9@:%._\\+~#=]{1,256}\\.[a-zA-Z0-9()]{1,6}\\b([-a-zA-Z0-9()@:%_\\+.~#?&//=]*)`,
  //   renderForm: (i) => i.type == 'OAUTH2',
  // },
  {
    name: 'oauthJwksUriEndpoint',
    label: 'Endpoint para consulta às chaves jwks',
    groupName: 'OAuth',
    inputType: 'url',
    pattern: `^https:\\/\\/[-a-zA-Z0-9@:%._\\+~#=]{1,256}\\.[a-zA-Z0-9()]{1,6}\\b([-a-zA-Z0-9()@:%_\\+.~#?&//=]*)`,
    renderForm: i => i.type == 'OAUTH2'
  }],
  filter: [{
    field: 'usage',
    op: 'in'
  }, {
    field: 'usage',
    op: 'eq'
  }, {
    field: 'enabled',
    op: 'eq'
  }]
});