import fillCrudMetadataGaps, { PartialCrudMetadata } from '../../fillCrudMetadataGaps';
import authorization from '../../util/authorization';
export const partialMetadata: PartialCrudMetadata = {
  label: 'Regra de pesquisa',
  labelPlural: 'Regras de pesquisa',
  name: 'HotelPreSearchRule',
  deletable: false,
  updatable: true,
  insertable: true,
  searchable: true,
  deprecated: true,
  authorization: authorization('clientConsultant', 'admin'),
  dynamoDB: {
    tableName: 'SpearHotelSearchRule'
  },
  columnNames: ['id', 'description', 'type', 'enabled'],
  fields: [{
    label: 'Id',
    name: 'id',
    type: 'text',
    id: true,
    insertable: false,
    updatable: false,
    renderForm: false,
    renderColumn: false
  }, {
    custom_groupName: 'Informações básicas',
    label: 'Descrição',
    name: 'description',
    type: 'text',
    hint: 'Descriação da configuração para uso interno'
  }, {
    name: 'enabled',
    label: 'Ativo',
    default: true,
    required: true,
    type: 'boolean'
  }, {
    custom_groupName: 'Informações básicas',
    label: 'Tipo de regra',
    name: 'type',
    type: 'text',
    default: 'PRE',
    updatable: false,
    options: [{
      value: 'PRE',
      label: 'Antes da busca'
    }, {
      value: 'POST',
      label: 'Após a busca'
    }],
    render: false
  }, {
    label: 'Filtros de credencial',
    custom_groupName: 'Credenciais permitidas',
    name: 'credentialRule',
    type: 'object',
    hint: 'Definição das credenciais que poderão ser utilizadas',
    graphQLType: 'HotelSearchCredentials',
    graphQLInputType: 'HotelSearchCredentialstInput',
    fields: [{
      name: 'rule',
      label: 'Regra',
      hint: 'Selecione a regra que será aplicada para definir quais credenciais poderão ser utilizadas na pesquisa',
      required: true,
      inputType: 'select',
      default: 'TENANT_AND_CLIENT',
      options: [{
        label: 'Credenciais do cliente e da instalação',
        value: 'TENANT_AND_CLIENT'
      }, {
        label: 'Somente credenciais do cliente',
        value: 'CLIENT'
      }, {
        label: 'Somente credenciais especificadas',
        value: 'CUSTOM'
      }]
    }, {
      name: 'credentials',
      label: 'Credenciais',
      required: true,
      hint: 'Selecione as credenciais que poderão ser utilizadas',
      type: 'textarray',
      inputType: 'credential',
      credentialTypeList: ['B2BR', 'SHOP', 'TRND', 'OMNI', 'EXPD', 'HOTB', 'NIAR'],
      renderForm: function (i) {
        return i.rule === 'CUSTOM';
      }
    }]
  }, {
    label: 'Nível de conteúdo permitido',
    custom_groupName: 'Conteúdo permitido',
    name: 'allowedHierarchy',
    type: 'textarray',
    inputType: 'niara-react-form/inputMultiSelect',
    withOptionSorter: true,
    default: ['city', 'hotel', 'point_of_interest', 'neighborhood', 'metro_station', 'train_station', 'multi_city_vicinity', 'high_level_region', 'airport'],
    options: [{
      label: 'Hotéis',
      value: 'hotel'
    }, {
      label: 'Cidades',
      value: 'city'
    }, {
      label: 'Regiões',
      value: 'high_level_region'
    }, {
      label: 'Arredores',
      value: 'multi_city_vicinity'
    }, {
      label: 'Pontos de interesse',
      value: 'point_of_interest'
    }, {
      label: 'Aeroportos',
      value: 'airport'
    }, {
      label: 'Bairros',
      value: 'neighborhood'
    }, {
      label: 'Estações de metrô',
      value: 'metro_station'
    }, {
      label: 'Estações de trem',
      value: 'train_station'
    }, {
      label: 'Vila',
      value: 'vila'
    }],
    onEntityChange: function (oldVersion, newVersion) {
      // Não será possível deselecionar hotéis
      if (newVersion.allowedHierarchy.indexOf('hotel') === -1) return {
        ...newVersion,
        allowedHierarchy: ['hotel', ...newVersion.allowedHierarchy]
      };else {
        return newVersion;
      }
    }
  }, {
    label: 'Filtros de conteúdo',
    custom_groupName: 'Conteúdo permitido',
    name: 'rules',
    type: 'objectarray',
    hint: 'Definição do conteúdo pesquisável',
    graphQLType: '[HotelSearchRuleList]',
    graphQLInputType: '[HotelSearchRuleListInput]',
    fields: [{
      name: 'rule',
      label: 'Regra',
      required: false,
      type: 'object',
      graphQLType: 'HotelSearchRuleListItem',
      graphQLInputType: 'HotelSearchRuleListItemInput',
      fields: [{
        name: 'credentials',
        label: 'Credenciais',
        required: false,
        hint: 'Aplicar regras às credenciais. Se nenhuma credencial for selecionada a regra será aplicada a todas as credenciais.',
        type: 'textarray',
        inputType: 'credential',
        credentialTypeList: ['B2BR', 'SHOP', 'TRND', 'OMNI', 'EXPD', 'HOTB', 'NIAR']
      }, {
        label: 'Regras de conteúdo',
        name: 'content',
        type: 'objectarray',
        hint: 'Definição do conteúdo pesquisável',
        graphQLType: '[HotelSearchRuleContentList]',
        graphQLInputType: '[HotelSearchRuleContentListInput]',
        fields: [{
          name: 'type',
          label: 'Tipo da Regra',
          inputType: 'select',
          options: [{
            label: 'Permitir',
            value: 'ALLOW'
          }, {
            label: 'Negar',
            value: 'DENY'
          }]
        }, {
          name: 'value',
          label: 'Conteúdo',
          type: 'object',
          fields: [{
            name: 'id',
            label: 'Id'
          }, {
            name: 'contentType',
            label: 'Tipo do conteúdo'
          }, {
            name: 'name',
            label: 'Nome'
          }, {
            name: 'regionIds',
            label: 'Regiões a serem incluídas',
            required: false,
            type: 'textarray'
          }],
          custom_newSearch: true,
          inputType: 'InputHotelDestination',
          graphQLType: 'HotelSearchRuleContentItemValue',
          graphQLInputType: 'HotelSearchRuleContentItemValueInput'
        }]
      }]
    }]
  }, {
    label: 'Permitir busca de todos os itens acima',
    custom_groupName: 'Conteúdo permitido',
    name: 'showAllOption',
    type: 'boolean',
    hint: 'Permite a apresentação da opção "Todos" no resultado do autocomplete, a qual permite pesquisar por todos os hotéis habilitados nas regras acima',
    default: true
  }, {
    custom_groupName: 'Critérios de busca predefinidos',
    name: 'minNightCount',
    label: 'Número mínimo de noites',
    required: true,
    default: 1,
    min: 1,
    type: 'int'
  }, {
    custom_groupName: 'Critérios de busca predefinidos',
    name: 'maxNightCount',
    label: 'Número máximo de noites',
    required: false,
    min: 1,
    type: 'int'
  }, {
    custom_groupName: 'Critérios de busca predefinidos',
    name: 'minRoomCount',
    label: 'Número mínimo de quartos',
    required: true,
    default: 1,
    min: 1,
    type: 'int'
  }, {
    custom_groupName: 'Critérios de busca predefinidos',
    name: 'maxRoomCount',
    label: 'Número máximo de quartos',
    required: false,
    min: 1,
    type: 'int'
  }, {
    custom_groupName: 'Critérios de busca predefinidos',
    name: 'minStartDate',
    label: 'Data mínima de busca',
    required: false,
    type: 'date'
  }, {
    custom_groupName: 'Critérios de busca predefinidos',
    name: 'maxEndDate',
    label: 'Data máxima de busca',
    required: false,
    type: 'date'
  }, {
    name: 'minAdultCount',
    custom_groupName: 'Critérios de busca predefinidos',
    label: 'Número mínimo de adultos pemitidos',
    required: true,
    default: 1,
    min: 1,
    type: 'int'
  }, {
    name: 'maxAdultCount',
    custom_groupName: 'Critérios de busca predefinidos',
    label: 'Número máximo de adultos permitidos',
    required: false,
    min: 1,
    type: 'int'
  }, {
    name: 'childrenAllowed',
    custom_groupName: 'Critérios de busca predefinidos',
    label: 'Permitir incluir crianças',
    type: 'boolean',
    default: true
  }, {
    name: 'minChildCount',
    custom_groupName: 'Critérios de busca predefinidos',
    label: 'Número mínimo de crianças pemitidas',
    required: true,
    default: 0,
    min: 0,
    type: 'int',
    renderForm: function (i) {
      return !i.childrenAllowed ? false : this.render && this.render(i);
    }
  }, {
    name: 'maxChildCount',
    custom_groupName: 'Critérios de busca predefinidos',
    label: 'Número máximo de crianças permitidas',
    required: false,
    type: 'int',
    min: 0,
    renderForm: function (i) {
      return !i.childrenAllowed ? false : this.render && this.render(i);
    }
  }, {
    name: 'minChildAge',
    custom_groupName: 'Critérios de busca predefinidos',
    label: 'Idade mínima permitida da criança',
    required: true,
    default: 0,
    min: 0,
    type: 'int',
    renderForm: function (i) {
      return !i.childrenAllowed ? false : this.render && this.render(i);
    }
  }, {
    name: 'maxChildAge',
    custom_groupName: 'Critérios de busca predefinidos',
    label: 'Idade máxima permitida da criança',
    required: false,
    type: 'int',
    min: 0,
    renderForm: function (i) {
      return !i.childrenAllowed ? false : this.render && this.render(i);
    }
  }, {
    custom_groupName: 'Critérios de busca predefinidos',
    label: 'Antecedência mínima',
    name: 'minTimeBetweenBookingAndCheckin',
    hint: 'Quantidade de dias mínimo entre a data da reserva e a data do check-in',
    type: 'int',
    required: true,
    default: 0,
    min: 0
  }, {
    custom_groupName: 'Critérios de busca predefinidos',
    label: 'Antecedência máxima',
    name: 'maxTimeBetweenBookingAndCheckin',
    hint: 'Quantidade de dias máxima entre a data da reserva e a data do check-in',
    type: 'int',
    required: false,
    default: null,
    min: 0
  }, {
    custom_groupName: 'Motivos de não retorno',
    label: 'Apresentar motivos de não retorno de disponibilidade',
    hint: 'Na tela de detalhes de hotel, mostrar tarifas sem disponibilidade. Apenas OTA Builder e front unificado.',
    name: 'showUnavailableRates',
    type: 'boolean',
    required: false,
    default: true
  }, {
    custom_groupName: 'Calendário de disponibilidade',
    label: 'Habilitar a consulta de preço e disponibilidade no calendário',
    hint: 'Apenas OTA Builder e front unificado.',
    name: 'showCalendarAvailability',
    type: 'boolean',
    required: false,
    default: true
  }].map(field => ({
    ...field,
    renderForm: field.name == 'rules' ? field.renderForm : field.renderForm == false || field.render == false ? false : function (i) {
      if (i.id == 'NIARA_PRE') return false;else if (field.renderForm && typeof field.renderForm == 'function') return field.renderForm(i);else return this.render(i);
    }
  } as PartialCrudMetadata['fields'][0])),
  filter: [{
    name: 'type_eq',
    field: 'type',
    op: 'eq'
  }]
};

/**@deprecated */
export default fillCrudMetadataGaps({
  ...partialMetadata
});