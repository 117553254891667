import React from 'react';
import fillCrudMetadataGaps from '../../fillCrudMetadataGaps';
import authorization from '../../util/authorization';
const GROUP_CONSULTOR = {
  value: 'Consultant',
  label: 'Consultor'
};
const GROUP_SUPERVISOR = {
  value: 'Supervisor',
  label: 'Supervisor'
};
const GROUP_ADMIN = {
  value: 'Admin',
  label: 'Administrador'
};
const GROUP_MASTER = {
  value: 'Master',
  label: 'Master'
};
export const renderUsername = row => {
  const username = row.id;
  if (/^\w+[-.\w]*__[-.\w]*_at_[-.\w]*$/.test(username)) {
    return `${(username as string).split('__', 2)?.[1]?.replace('_at_', '@')} (SSO)`;
  } else {
    return username;
  }
};
export default fillCrudMetadataGaps({
  label: 'Usuário',
  labelPlural: 'Usuários',
  name: 'User',
  insertable: true,
  updatable: true,
  deletable: true,
  custom_withAudit: true,
  authorization: authorization('consultant', 'master'),
  columnNames: ['id', 'name', 'email', 'groups', 'enabled', 'clientId', 'allowedHotels', 'createMFA'],
  fields: [{
    name: 'id',
    label: 'Username',
    type: 'text',
    updatable: false,
    id: true,
    required: true,
    hint: "ex: nome.sobrenome, nome.setor, nome, ...",
    custom_cognito: true,
    renderColumn: renderUsername
  }, {
    name: 'clientId',
    label: 'Id do cliente',
    updatable: false,
    insertable: false,
    render: false,
    custom_cognito: true
  }, {
    name: 'name',
    label: 'Nome completo',
    required: true,
    custom_cognito: true
  }, {
    name: 'email',
    label: 'E-mail',
    inputType: 'email',
    required: true,
    custom_cognito: true
  }, {
    name: 'phone',
    label: 'Telefone',
    updatable: true,
    // placeholder: '+5511XXXXXXXXXX',
    // hint: 'Formato: +{código país}{código estado}{telefone}',
    // mask: '+9999999999999',
    inputType: 'phoneNumber',
    custom_cognito: true
  }, {
    // indisponível em buscas
    name: 'status',
    label: 'Status',
    updatable: false,
    insertable: false,
    custom_cognito: true
  }, {
    name: 'sub',
    label: 'Sub',
    hint: 'Sub do token do usuário no userpool. Não vem na listagem.',
    default: true,
    updatable: false,
    readOnly: true,
    insertable: false,
    type: 'text',
    custom_cognito: true
  }, {
    name: 'groups',
    label: 'Tipo',
    required: true,
    updatable: true,
    default: ['Consultant'],
    type: 'textarray',
    inputType: 'radio',
    //é um array, mas só deixa o usuário selecionar um valor
    options: [GROUP_CONSULTOR, GROUP_SUPERVISOR, GROUP_ADMIN, GROUP_MASTER],
    custom_cognito: true
  }, {
    name: 'selfOnly',
    label: 'Apenas próprias reservas',
    default: null,
    type: 'boolean',
    hint: 'Não permite o usuário ver as reservas feitas por outros consultores',
    renderForm: function (i) {
      return i.groups?.includes(GROUP_CONSULTOR.value) || i.groups?.includes(GROUP_SUPERVISOR.value) ? this.render && this.render(i) : false;
    },
    custom_cognito: false,
    custom_checkTenant: t => t && !t.isBee2Pay
  }, {
    name: 'allowedViewCard',
    label: 'Visualiza dados do cartão (VCN)',
    default: null,
    type: 'boolean',
    hint: 'Permite o usuário visualizar os dados dos cartões (VCN)',
    custom_cognito: false,
    custom_checkTenant: t => t && !t.isBee2Pay
  }, {
    name: 'enabled',
    label: 'Ativo',
    default: true,
    type: 'boolean',
    custom_cognito: true
  }, {
    deprecated: true,
    custom_groupName: "Central de Reservas",
    name: 'hotels',
    label: 'Hotéis atendidos',
    type: 'textarray',
    inputType: 'chips',
    custom_checkTenant: t => t && t.modules_crs && t.allowedHotels && t.allowedHotels.length > 0,
    custom_cognito: false,
    render: false
  }, {
    custom_groupName: "Central de Reservas",
    name: 'allowedHotels',
    label: 'Hotéis atendidos',
    type: 'objectarray',
    inputType: 'InputMultiHotelWithOmniIdAutoComplete',
    custom_checkTenant: t => t && t.modules_crs && t.allowedHotels && t.allowedHotels.length > 0,
    fields: [{
      name: 'id',
      label: 'ID'
    }, {
      name: 'name',
      label: 'Nome'
    }, {
      name: 'omniId',
      label: 'ID Omnibees'
    }],
    render: e => {
      return <>{e?.properties?.map(p => p?.name)?.join('\n')}</>;
    },
    renderColumn: false
  }],
  createParams: [{
    custom_groupName: "Acesso",
    name: 'firstPassword',
    label: 'Senha inicial',
    updatable: false,
    required: true,
    minLength: 8,
    pattern: '(?=.*\\d)(?=.*[a-z])(?=.*[A-Z]).{8,}',
    placeholder: 'Mudar123, Empresa@2018, ...',
    hint: 'Mínimo 8 caracteres, ao menos uma letra maiúscula, uma letra minúscula e um número'
  }, {
    custom_groupName: "Acesso",
    name: 'sendEmail',
    label: 'Enviar email com senha para usuário',
    default: true,
    type: 'boolean'
  }, {
    custom_groupName: "Acesso",
    name: 'createMFA',
    label: 'Criar MFA',
    default: false,
    type: 'boolean',
    hint: 'Cria e habilita um MFA ao criar o usuário',
    custom_checkTenant: t => t && t?.modules_enableMFA
  }],
  searchParams: [{
    name: 'useCognito',
    type: 'boolean'
  }],
  filter: [{
    name: 'name_beginsWith',
    field: 'name',
    op: 'beginsWith',
    label: 'Nome'
  }, {
    name: 'enabled_eq',
    field: 'enabled',
    op: 'eq',
    label: 'Ativo'
  }]
});