import type { FilterKeys } from '@niarab2c/frontend-commons/src/types/hotels';
export const FILTER_KEYS: Partial<Record<FilterKeys, FilterKeys>> = {
  cancelPolicy: 'cancelPolicy',
  paymentType: 'paymentType',
  //roomType: 'roomType',
  award: 'award',
  cityName: 'cityName',
  // name: 'name',
  // price: 'price',
  meal: 'meal',
  hotelAmenities: 'hotelAmenities',
  // hotelId: 'hotelId',
  rateType: 'rateType',
  breakfast: 'breakfast',
  // remuneration: 'remuneration',
  credentialType: 'credentialType',
  // rawDistance: 'rawDistance',
  // negotiatedRateFlags: 'negotiatedRateFlags',
  metasearchPercentDiscount: 'metasearchPercentDiscount',
  outputPaymentType: 'outputPaymentType',
  ratePlanIsPublic: 'ratePlanIsPublic'
};
export const FILTER_TITLES: Partial<Record<FilterKeys, string>> = {
  cancelPolicy: "Condições de Cancelamento",
  paymentType: "Formas de pagamento",
  //roomType: 'Quarto',
  award: "Número de Estrelas",
  cityName: "Cidade",
  // name: 'Nome do Hotel',
  // price: 'Seu Orçamento',
  meal: "Regime de Alimentação",
  hotelAmenities: "Amenidades do Hotel",
  metasearchPercentDiscount: "Percentual de desconto",
  credentialType: "Fornecedor de Conteúdo",
  rateType: "Tipo de Tarifa",
  outputPaymentType: "Formas de Recebimento",
  ratePlanIsPublic: "Tipo de Acordo"
};
export const FILTER_ONLY_B2C: Partial<Record<FilterKeys, boolean>> = {
  cancelPolicy: true,
  paymentType: true,
  // roomType: true,
  award: true,
  cityName: true,
  // name: true,
  // price: true,
  meal: true,
  hotelAmenities: true,
  metasearchPercentDiscount: true,
  credentialType: false,
  //rateType: false,
  outputPaymentType: false,
  ratePlanIsPublic: false
};