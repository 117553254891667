import { printPrice } from '@niaratech/niara-js-commons';
import React from 'react';
import fillCrudMetadataGaps from '../../fillCrudMetadataGaps';
import authorization from '../../util/authorization';
import { metadataOptions, credentialTypeDescriptions } from '../credential';
const metadataMap = new Map(metadataOptions.map(m => [m.value, m.label]));
export default fillCrudMetadataGaps({
  label: 'VCN',
  labelPlural: 'VCNs',
  name: 'Vcn',
  faIcon: 'credit-card',
  insertable: false,
  updatable: false,
  deletable: false,
  authorization: authorization('consultant', 'master'),
  columnNames: ['createDate', 'id', 'idOnSource', 'cardHolderName', 'activationDate', 'expirationDate', 'cardExpiration', 'limit', 'productId', 'status', 'currency', 'source'],
  sortFields: ['createDate desc'],
  dynamoDB: {
    indexName: 'tenantId-createDate-index',
    indexedProp: 'createDate',
    scanIndexBackwards: true
  },
  fields: [{
    label: 'id',
    name: 'id',
    type: 'text',
    updatable: false,
    insertable: false,
    render: false,
    id: true
  }, {
    name: 'idOnSource',
    label: 'Id Externo'
  }, {
    name: 'source',
    label: 'Fornecedor',
    render: i => i.source && credentialTypeDescriptions[i.source === 'WEX' ? 'WEX0' : i.source]
  }, {
    name: 'cardHolderName',
    label: 'PAX'
  }, {
    name: 'maskedCardNumber',
    label: 'Número do Cartão'
  }, {
    name: 'cardType',
    label: 'Bandeira'
  }, {
    name: 'cardExpiration',
    label: 'Validade do cartão',
    type: 'date'
  }, {
    name: 'activationDate',
    label: 'Ativação',
    type: 'date'
  }, {
    name: 'expirationDate',
    label: 'Expiração',
    type: 'date'
  }, {
    name: 'limit',
    label: 'Limite',
    type: 'float',
    render: i => i.limit ? printPrice(i.limit, i.currency) : '-'
  }, {
    name: 'status',
    label: 'Status',
    options: [{
      value: 'Valido',
      label: 'Válido'
    }, {
      value: 'Cancelado',
      label: 'Cancelado'
    }],
    renderStyle(i, fieldName = 'status') {
      if (i[fieldName]) {
        switch (i[fieldName]) {
          case 'Cancelado':
            return 'danger';
          case 'Valido':
            return 'success';
        }
      }
      return 'danger';
    }
  }, {
    name: 'productId',
    label: 'Reserva',
    render: i => i.productId && <a target="_blank" href={'/hotelreservations/' + i.productId} rel="noreferrer">
            {i.productId}
          </a>
  }, {
    name: 'product',
    label: 'Descrição',
    render: false
  }, {
    name: 'currency',
    label: 'Descrição',
    render: false
  }, {
    name: 'lastUpdateDate',
    label: 'Última atualização',
    type: 'datetime'
  }, {
    name: 'createDate',
    label: 'Data de criação',
    type: 'datetime'
  }, {
    name: 'CreatedBy',
    label: 'Criado por'
  }, {
    name: 'UpdatedBy',
    label: 'Modificado por'
  }, {
    name: 'credentialAlias',
    label: 'Credencial',
    render: i => i.credentialAlias && <a target="_blank" href={'/credentials/' + i.credentialId} rel="noreferrer">
            {i.credentialAlias}
          </a>
  }, {
    name: 'credentialId',
    label: 'Id da Credencial',
    render: false
  }, {
    name: 'metadata',
    label: 'Campos Gerenciais',
    type: 'object',
    updatable: false,
    graphQLType: 'VcnMetadata',
    fields: metadataOptions.map(m => ({
      name: m.value,
      label: m.label
    })),
    renderForm: i => i.metadata && <div className="container">
            {Object.entries(i.metadata).map(([k, v]) => v ? <div className="row">
                  <div className="col">{metadataMap.get(k)}</div>
                  <div className="col">{(v as string)}</div>
                </div> : '')}
          </div>
  }],
  filter: [{
    name: 'productId_beginsWith',
    field: 'productId',
    op: 'beginsWith'
  }]
});