import type { EngineRuleVersion, HotelResult } from '@niarab2c/frontend-commons/src/types/hotels';
import { createAsyncThunk, createSlice, PayloadAction, unwrapResult } from '@reduxjs/toolkit';
import _isEqual from 'lodash/isEqual';
import _isEqualWith from 'lodash/isEqualWith';
import { nanoid } from 'nanoid';
import type { BaseRootState } from '../../base';
import * as Hotel from '../hotel';
import type { CriteriaForm, State as HotelSearchState } from './hotelSearch';
import hotelSearch, { searchHotels as _searchHotels } from './hotelSearch';
export * from './hotelPagination';
export interface HotelDetailsState {
  hotelSearch?: HotelSearchState;
  hotelId?: string;
  hotelResult?: HotelResult;
}
const isCriteriaEqual = function (a: CriteriaForm, b: CriteriaForm) {
  const tester = (objValue, othValue, key): boolean => {
    if (key === 'destinationName') {
      return true; //ignora destinationName na comparação
    }
    return _isEqual(objValue, othValue);
  };
  return _isEqualWith(a, b, tester);
};
export const searchHotels = createAsyncThunk<void, {
  hotelId: string;
  propertyId?: string;
  criteriaForm: CriteriaForm & {
    user?: any;
  };
  engineRuleVersion: EngineRuleVersion;
}, {
  state: BaseRootState;
  rejectValue: {
    searchId: string;
    errorMessage: string;
  };
}>('hotelDetails/searchHotels', async ({
  criteriaForm,
  hotelId,
  propertyId,
  engineRuleVersion
}, {
  getState,
  dispatch,
  rejectWithValue
}) => {
  dispatch(_setHotelId({
    hotelId
  }));
  if (!criteriaForm) {
    _setHotelSearch(undefined);
  } else {
    criteriaForm = {
      ...criteriaForm,
      bestOnly: false
    };
    criteriaForm.destinations = Object.assign<(typeof criteriaForm)['destinations'], (typeof criteriaForm)['destinations']>({
      hotelIds: [hotelId].filter(Boolean)
    }, propertyId ? {
      propertyId,
      contentType: 'property'
    } : {});

    //Dados da pesquisa anterior
    const currentHotelSearch = getState().hotel?.hotelDetails?.hotelSearch;
    const currentCriteria = currentHotelSearch?.criteria;
    const currentEngineRuleVersion = currentHotelSearch?.engineRuleVersion;

    // compara o critério e engineRuleVersion - se não mudou, não faz nova busca
    if (currentCriteria && isCriteriaEqual(currentCriteria, criteriaForm) && engineRuleVersion == currentEngineRuleVersion) {
      // keep previous search
    } else {
      const searchId = nanoid();
      await dispatch(Hotel.loadHotelSearchRule({
        clientId: criteriaForm.clientId,
        propertyId
      }));
      dispatch(_setHotelSearch({
        hotelSearch: {
          searchId
        }
      }));
      try {
        await dispatch(_searchHotels({
          criteriaForm,
          searchId,
          engineRuleVersion
        })).then(unwrapResult /** Vai expor o exception - assim, HotelDetails consegue detectar que deu erro e reencaminha para a tela de pesquisa */);
      } catch (error) {
        return rejectWithValue({
          searchId,
          errorMessage: error.errorMessage
        });
      }
    }
  }
});
const hotelDetails = createSlice({
  name: 'hotelDetails',
  initialState: ({
    hotelSearch: hotelSearch(undefined, {
      type: 'IGNORE'
    })
  } as HotelDetailsState),
  reducers: {
    _setHotelSearch: (state, action: PayloadAction<{
      hotelSearch?: HotelSearchState;
    }>) => {
      state.hotelSearch = action.payload.hotelSearch;
    },
    _setHotelId: (state, action: PayloadAction<{
      hotelId?: string;
    }>) => {
      if (state.hotelId !== action.payload.hotelId) {
        return {
          hotelId: action.payload.hotelId
        };
      }
      return state;
    },
    _setHotelResult: (state, action: PayloadAction<{
      hotelResult?: HotelResult;
    }>) => {
      state.hotelResult = action.payload.hotelResult;
    }
  },
  extraReducers: builder => {
    builder.addCase(searchHotels.fulfilled, (state, action) => {
      const hotelResult = state.hotelSearch.results ? Object.values(state.hotelSearch.results).find(hr => hr._soupIds.indexOf(state.hotelId) >= 0) : null;
      state.hotelResult = hotelResult;
    }).addMatcher(action => action.type.startsWith('hotelSearch'), (state, action) => {
      if (state?.hotelSearch?.searchId === action.payload?.searchId || state?.hotelSearch?.searchId === action.meta?.arg?.searchId) {
        const newHotelSearch = hotelSearch(state.hotelSearch, action);
        if (state.hotelSearch === newHotelSearch) return state;
        const hotelResult = newHotelSearch.results ? Object.values(newHotelSearch.results).find(hr => hr._soupIds.indexOf(state.hotelId) >= 0) : undefined;
        return {
          ...state,
          hotelSearch: newHotelSearch,
          hotelResult
        };
      }
      return state;
    });
  }
});
export default hotelDetails.reducer;
const {
  _setHotelSearch,
  _setHotelId
} = hotelDetails.actions;